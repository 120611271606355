.v-progress-circular {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  vertical-align: middle;
}
.v-progress-circular > svg {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.v-progress-circular__content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.v-progress-circular__underlay {
  color: rgba(var(--v-border-color), var(--v-border-opacity));
  stroke: currentColor;
  z-index: 1;
}

.v-progress-circular__overlay {
  stroke: currentColor;
  transition: all 0.2s ease-in-out, stroke-width 0s;
  z-index: 2;
}

.v-progress-circular--size-x-small {
  height: 16px;
  width: 16px;
}
.v-progress-circular--size-small {
  height: 24px;
  width: 24px;
}
.v-progress-circular--size-default {
  height: 32px;
  width: 32px;
}
.v-progress-circular--size-large {
  height: 48px;
  width: 48px;
}
.v-progress-circular--size-x-large {
  height: 64px;
  width: 64px;
}

.v-progress-circular--indeterminate > svg {
  animation: progress-circular-rotate 1.4s linear infinite;
  transform-origin: center center;
  transition: all 0.2s ease-in-out;
}
.v-progress-circular--indeterminate .v-progress-circular__overlay {
  animation: progress-circular-dash 1.4s ease-in-out infinite, progress-circular-rotate 1.4s linear infinite;
  stroke-dasharray: 25, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center center;
  transform: rotate(-90deg);
}

.v-progress-circular--disable-shrink > svg {
  animation-duration: 0.7s;
}
.v-progress-circular--disable-shrink .v-progress-circular__overlay {
  animation: none;
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg,
.v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay {
  animation-play-state: paused !important;
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes progress-circular-rotate {
  100% {
    transform: rotate(270deg);
  }
}