/* region BLOCK */
.v-text-field input {
  color: inherit;
  opacity: 0;
  flex: 1;
  transition: 0.15s opacity cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 0;
}
.v-text-field input:focus, .v-text-field input:active {
  outline: none;
}
.v-text-field input:invalid {
  box-shadow: none;
}
.v-text-field .v-field {
  cursor: text;
}
.v-text-field--prefixed.v-text-field .v-field__input {
  --v-field-padding-start: 6px;
}

.v-text-field--suffixed.v-text-field .v-field__input {
  --v-field-padding-end: 0;
}

.v-text-field .v-input__details {
  padding-inline: 16px;
}
.v-input--plain-underlined.v-text-field .v-input__details {
  padding-inline: 0;
}

.v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
}
.v-text-field .v-field--single-line input {
  transition: none;
}

/* endregion */
/* region ELEMENTS */
.v-text-field__prefix, .v-text-field__suffix {
  align-items: center;
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  cursor: default;
  display: flex;
  opacity: 0;
  transition: inherit;
  white-space: nowrap;
  min-height: max(var(--v-input-control-height, 56px), 1.5rem + var(--v-field-input-padding-top) + var(--v-field-input-padding-bottom));
  padding-top: calc(var(--v-field-padding-top, 4px) + var(--v-input-padding-top, 0));
  padding-bottom: var(--v-field-padding-bottom, 6px);
}
.v-field--active .v-text-field__prefix, .v-field--active .v-text-field__suffix {
  opacity: 1;
}
.v-field--disabled .v-text-field__prefix, .v-field--disabled .v-text-field__suffix {
  color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity));
}
.v-text-field__prefix {
  padding-inline-start: var(--v-field-padding-start);
}
.v-text-field__suffix {
  padding-inline-end: var(--v-field-padding-end);
}

/* endregion */