@use '../tools';

@include tools.layer('base') {
  .v-locale {
    &--is-rtl {
      direction: rtl;
    }

    &--is-ltr {
      direction: ltr;
    }
  }
}
