@use './functions' as *
@use '../settings' as *

@mixin visually-hidden
  &
    position: absolute !important
    height: 1px
    width: 1px
    overflow: hidden
    clip: rect(1px, 1px, 1px, 1px)
    white-space: nowrap
    display: initial

=media-breakpoint-up($name, $breakpoints: $grid-breakpoints)
  $min: breakpoint-min($name, $breakpoints)
  @if $min
    @media (min-width: $min)
      @content
  @else
    @content
