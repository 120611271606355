.v-btn-group {
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  vertical-align: middle;
}
.v-btn-group {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
}
.v-btn-group--border {
  border-width: thin;
  box-shadow: none;
}
.v-btn-group {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-btn-group {
  border-radius: 4px;
}
.v-btn-group {
  background: transparent;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}
.v-btn-group--density-default.v-btn-group {
  height: 48px;
}

.v-btn-group--density-comfortable.v-btn-group {
  height: 40px;
}

.v-btn-group--density-compact.v-btn-group {
  height: 36px;
}

.v-btn-group .v-btn {
  border-radius: 0;
  border-color: inherit;
}
.v-btn-group .v-btn:not(:last-child) {
  border-inline-end: none;
}
.v-btn-group .v-btn:not(:first-child) {
  border-inline-start: none;
}
.v-btn-group .v-btn:first-child {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}
.v-btn-group .v-btn:last-child {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}
.v-btn-group--divided .v-btn:not(:last-child) {
  border-inline-end-width: thin;
  border-inline-end-style: solid;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity));
}
.v-btn-group--tile {
  border-radius: 0;
}