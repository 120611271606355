@use '../settings'
@use '../tools'

@if (settings.$utilities != false and length(settings.$utilities) > 0)
  @include tools.layer('utilities')
    @each $size, $media_query in settings.$display-breakpoints
      .hidden
        &-#{$size}
          @media #{$media_query}
            display: none !important
