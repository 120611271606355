@use '../settings'
@use '../tools'

@include tools.layer('base')
  html
    font-family: settings.$body-font-family
    line-height: settings.$line-height-root
    font-size: settings.$font-size-root
    overflow-x: hidden
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

  html.overflow-y-hidden
    overflow-y: hidden !important

  :root
    --v-theme-overlay-multiplier: 1
    --v-scrollbar-offset: 0px

  // iOS Safari hack to allow click events on body
  @supports (-webkit-touch-callout: none)
    body
      cursor: pointer
