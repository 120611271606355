.v-chip-group {
  display: flex;
  max-width: 100%;
  min-width: 0;
  overflow-x: auto;
  padding: 4px 0;
}
.v-chip-group .v-chip {
  margin: 4px 8px 4px 0;
}
.v-chip-group .v-chip.v-chip--selected:not(.v-chip--disabled) .v-chip__overlay {
  opacity: var(--v-activated-opacity);
}

.v-chip-group--column .v-slide-group__content {
  white-space: normal;
  flex-wrap: wrap;
  max-width: 100%;
}