.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.v-ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.v-ripple__animation--enter {
  transition: none;
  opacity: 0;
}
.v-ripple__animation--in {
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  opacity: calc(0.25 * var(--v-theme-overlay-multiplier));
}
.v-ripple__animation--out {
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
  opacity: 0;
}