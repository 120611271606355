.v-img {
  --v-theme-overlay-multiplier: 3;
  z-index: 0;
}
.v-img.v-img--absolute {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.v-img--booting .v-responsive__sizer {
  transition: none;
}
.v-img--rounded {
  border-radius: 4px;
}

.v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
}
.v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-img__img--preload {
  filter: blur(4px);
}
.v-img__img--contain {
  object-fit: contain;
}
.v-img__img--cover {
  object-fit: cover;
}

.v-img__gradient {
  background-repeat: no-repeat;
}