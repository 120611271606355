@use 'sass:list'
@use 'sass:map'
@use 'sass:meta'

=generate-utility($utility, $infix, $forceDir)
  $values: map.get($utility, values)

  // If the values are a list or string, convert it into a map
  @if meta.type-of($values) == "string" or meta.type-of(list.nth($values, 1)) != "list"
    $values: list.zip($values, $values)

  @each $value in $values
    $properties: map.get($utility, property)

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if meta.type-of($properties) == 'string'
      $properties: list.append((), $properties)

    // Property can be a map, where the key is a mixin to include
    @if meta.type-of($properties) == 'map'
      @each $dir in $properties
        $mixin: list.nth($dir, 1)
        // SASS doesn't support dynamic mixin invocation
        // https://github.com/sass/sass/issues/626
        @if $mixin == 'ltr'
          .v-locale--is-ltr
            @include generate-utility-body($utility, list.nth($dir, 2), $value, $infix)
        @else if $mixin == 'rtl'
          .v-locale--is-rtl
            @include generate-utility-body($utility, list.nth($dir, 2), $value, $infix)
        @else
          @error 'Only RTL and LTR are supported'
    @else
      @if $forceDir == 'ltr'
        .v-locale--is-ltr
          @include generate-utility-body($utility, $properties, $value, $infix)
      @else if $forceDir == 'rtl'
        .v-locale--is-rtl
          @include generate-utility-body($utility, $properties, $value, $infix)
      @else
        @include generate-utility-body($utility, $properties, $value, $infix)

=generate-utility-body($utility, $properties, $value, $infix)
  // Use custom class if present
  $property-class: map.get($utility, class)
  $property-class: if($property-class, $property-class, list.nth($properties, 1))

  // Don't prefix if value key is null (eg. with shadow class)
  $property-class-modifier: if(list.nth($value, 1), "-" + list.nth($value, 1), "")

  $value: list.nth($value, 2)

  .#{$property-class + $infix + $property-class-modifier}
    @for $i from 1 through list.length($properties)
      $property: list.nth($properties, $i)
      $val: $value
      @if meta.type-of($value) == 'list' and list.length($properties) == list.length($value)
        $val: list.nth($value, $i)
      @if $val != false
        // Check if unimportant property exists.
        // This allows you to conditional skip
        // defining a property as important.
        $unimportant: map.get($utility, unimportant)
        #{$property}: #{meta.inspect($val) if(list.index($unimportant, $property), null, !important)}
