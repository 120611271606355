@use 'sass:string'
@use 'sass:map'
@use 'sass:meta'
@use '../settings'
@use '../tools'
@use './display'
@use './elevation'
@use './screenreaders'

@include tools.layer('utilities')
  @each $breakpoint in map.keys(settings.$grid-breakpoints)
    // Generate media query if needed
    @include tools.media-breakpoint-up($breakpoint)
      $infix: tools.breakpoint-infix($breakpoint, settings.$grid-breakpoints)

      // Loop over each utility property
      @each $key, $utility in settings.$utilities
        // The utility can be disabled with `false`, thus check if the utility is a map first
        // Only proceed if responsive media queries are enabled or if it's the base media query
        @if string.slice($key, -4) == ':ltr'
          @if meta.type-of($utility) == "map" and (map.get($utility, responsive) or $infix == "")
            @include tools.generate-utility($utility, $infix, 'ltr')
        @else if string.slice($key, -4) == ':rtl'
          @if meta.type-of($utility) == "map" and (map.get($utility, responsive) or $infix == "")
            @include tools.generate-utility($utility, $infix, 'rtl')
        @else
          @if meta.type-of($utility) == "map" and (map.get($utility, responsive) or $infix == "")
            @include tools.generate-utility($utility, $infix, 'bidi')

  // Print utilities
  @media print
    @each $key, $utility in settings.$utilities
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Then check if the utility needs print styles
      @if string.slice($key, -4) == ':ltr'
        @if meta.type-of($utility) == "map" and map.get($utility, print) == true
          @include tools.generate-utility($utility, "-print", 'ltr')
      @else if string.slice($key, -4) == ':rtl'
        @if meta.type-of($utility) == "map" and map.get($utility, print) == true
          @include tools.generate-utility($utility, "-print", 'rtl')
      @else
        @if meta.type-of($utility) == "map" and map.get($utility, print) == true
          @include tools.generate-utility($utility, "-print", 'bidi')
