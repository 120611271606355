.v-responsive {
  display: flex;
  flex: 1 0 auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.v-responsive--inline {
  display: inline-flex;
  flex: 0 0 auto;
}

.v-responsive__content {
  flex: 1 0 0px;
  max-width: 100%;
}

.v-responsive__sizer ~ .v-responsive__content {
  margin-inline-start: -100%;
}

.v-responsive__sizer {
  flex: 1 0 0px;
  transition: padding-bottom 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}