.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  height: 1em;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  width: 1em;
  min-width: 1em;
}
.v-icon--clickable {
  cursor: pointer;
}
.v-icon--disabled {
  pointer-events: none;
  opacity: 0.38;
}
.v-icon--size-x-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1em);
}
.v-icon--size-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.25em);
}
.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.5em);
}
.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 1.75em);
}
.v-icon--size-x-large {
  font-size: calc(var(--v-icon-size-multiplier) * 2em);
}

.v-icon__svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.v-icon--start {
  margin-inline-end: 8px;
}

.v-icon--end {
  margin-inline-start: 8px;
}