.v-avatar {
  flex: none;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: width, height;
  vertical-align: middle;
}
.v-avatar.v-avatar--size-x-small {
  --v-avatar-height: 24px;
}
.v-avatar.v-avatar--size-small {
  --v-avatar-height: 32px;
}
.v-avatar.v-avatar--size-default {
  --v-avatar-height: 40px;
}
.v-avatar.v-avatar--size-large {
  --v-avatar-height: 48px;
}
.v-avatar.v-avatar--size-x-large {
  --v-avatar-height: 56px;
}
.v-avatar.v-avatar--density-default {
  height: calc(var(--v-avatar-height) + 0px);
  width: calc(var(--v-avatar-height) + 0px);
}
.v-avatar.v-avatar--density-comfortable {
  height: calc(var(--v-avatar-height) + -4px);
  width: calc(var(--v-avatar-height) + -4px);
}
.v-avatar.v-avatar--density-compact {
  height: calc(var(--v-avatar-height) + -8px);
  width: calc(var(--v-avatar-height) + -8px);
}
.v-avatar {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: thin;
}
.v-avatar--border {
  border-width: thin;
  box-shadow: none;
}
.v-avatar {
  border-radius: 50%;
}
.v-avatar--variant-plain, .v-avatar--variant-outlined, .v-avatar--variant-text, .v-avatar--variant-tonal {
  background: transparent;
  color: inherit;
}
.v-avatar--variant-plain {
  opacity: 0.62;
}
.v-avatar--variant-plain:focus, .v-avatar--variant-plain:hover {
  opacity: 1;
}
.v-avatar--variant-plain .v-avatar__overlay {
  display: none;
}
.v-avatar--variant-elevated, .v-avatar--variant-flat {
  background: var(--v-theme-surface);
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
}
.v-avatar--variant-elevated {
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 3px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-avatar--variant-flat {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}
.v-avatar--variant-outlined {
  border: thin solid currentColor;
}
.v-avatar--variant-text .v-avatar__overlay {
  background: currentColor;
}
.v-avatar--variant-tonal .v-avatar__underlay {
  background: currentColor;
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.v-avatar .v-avatar__underlay {
  position: absolute;
}
.v-avatar--rounded {
  border-radius: 4px;
}
.v-avatar--start {
  margin-inline-end: 8px;
}
.v-avatar--end {
  margin-inline-start: 8px;
}
.v-avatar .v-img {
  height: 100%;
  width: 100%;
}