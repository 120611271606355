@keyframes v-shake {
  59% {
    margin-left: 0;
  }

  60%, 80% {
    margin-left: 2px;
  }

  70%, 90% {
    margin-left: -2px;
  }
}

.bg-black {
  color: #fff !important;
  background-color: #000 !important;
}

.bg-white {
  color: #000 !important;
  background-color: #fff !important;
}

.bg-transparent {
  color: currentColor !important;
  background-color: #0000 !important;
}

.bg-red {
  color: #fff !important;
  background-color: #f44336 !important;
}

.bg-red-lighten-5 {
  color: #000 !important;
  background-color: #ffebee !important;
}

.bg-red-lighten-4 {
  color: #000 !important;
  background-color: #ffcdd2 !important;
}

.bg-red-lighten-3 {
  color: #000 !important;
  background-color: #ef9a9a !important;
}

.bg-red-lighten-2 {
  color: #fff !important;
  background-color: #e57373 !important;
}

.bg-red-lighten-1 {
  color: #fff !important;
  background-color: #ef5350 !important;
}

.bg-red-darken-1 {
  color: #fff !important;
  background-color: #e53935 !important;
}

.bg-red-darken-2 {
  color: #fff !important;
  background-color: #d32f2f !important;
}

.bg-red-darken-3 {
  color: #fff !important;
  background-color: #c62828 !important;
}

.bg-red-darken-4 {
  color: #fff !important;
  background-color: #b71c1c !important;
}

.bg-red-accent-1 {
  color: #000 !important;
  background-color: #ff8a80 !important;
}

.bg-red-accent-2 {
  color: #fff !important;
  background-color: #ff5252 !important;
}

.bg-red-accent-3 {
  color: #fff !important;
  background-color: #ff1744 !important;
}

.bg-red-accent-4 {
  color: #fff !important;
  background-color: #d50000 !important;
}

.bg-pink {
  color: #fff !important;
  background-color: #e91e63 !important;
}

.bg-pink-lighten-5 {
  color: #000 !important;
  background-color: #fce4ec !important;
}

.bg-pink-lighten-4 {
  color: #000 !important;
  background-color: #f8bbd0 !important;
}

.bg-pink-lighten-3 {
  color: #000 !important;
  background-color: #f48fb1 !important;
}

.bg-pink-lighten-2 {
  color: #fff !important;
  background-color: #f06292 !important;
}

.bg-pink-lighten-1 {
  color: #fff !important;
  background-color: #ec407a !important;
}

.bg-pink-darken-1 {
  color: #fff !important;
  background-color: #d81b60 !important;
}

.bg-pink-darken-2 {
  color: #fff !important;
  background-color: #c2185b !important;
}

.bg-pink-darken-3 {
  color: #fff !important;
  background-color: #ad1457 !important;
}

.bg-pink-darken-4 {
  color: #fff !important;
  background-color: #880e4f !important;
}

.bg-pink-accent-1 {
  color: #fff !important;
  background-color: #ff80ab !important;
}

.bg-pink-accent-2 {
  color: #fff !important;
  background-color: #ff4081 !important;
}

.bg-pink-accent-3 {
  color: #fff !important;
  background-color: #f50057 !important;
}

.bg-pink-accent-4 {
  color: #fff !important;
  background-color: #c51162 !important;
}

.bg-purple {
  color: #fff !important;
  background-color: #9c27b0 !important;
}

.bg-purple-lighten-5 {
  color: #000 !important;
  background-color: #f3e5f5 !important;
}

.bg-purple-lighten-4 {
  color: #000 !important;
  background-color: #e1bee7 !important;
}

.bg-purple-lighten-3 {
  color: #fff !important;
  background-color: #ce93d8 !important;
}

.bg-purple-lighten-2 {
  color: #fff !important;
  background-color: #ba68c8 !important;
}

.bg-purple-lighten-1 {
  color: #fff !important;
  background-color: #ab47bc !important;
}

.bg-purple-darken-1 {
  color: #fff !important;
  background-color: #8e24aa !important;
}

.bg-purple-darken-2 {
  color: #fff !important;
  background-color: #7b1fa2 !important;
}

.bg-purple-darken-3 {
  color: #fff !important;
  background-color: #6a1b9a !important;
}

.bg-purple-darken-4 {
  color: #fff !important;
  background-color: #4a148c !important;
}

.bg-purple-accent-1 {
  color: #fff !important;
  background-color: #ea80fc !important;
}

.bg-purple-accent-2 {
  color: #fff !important;
  background-color: #e040fb !important;
}

.bg-purple-accent-3 {
  color: #fff !important;
  background-color: #d500f9 !important;
}

.bg-purple-accent-4 {
  color: #fff !important;
  background-color: #a0f !important;
}

.bg-deep-purple {
  color: #fff !important;
  background-color: #673ab7 !important;
}

.bg-deep-purple-lighten-5 {
  color: #000 !important;
  background-color: #ede7f6 !important;
}

.bg-deep-purple-lighten-4 {
  color: #000 !important;
  background-color: #d1c4e9 !important;
}

.bg-deep-purple-lighten-3 {
  color: #fff !important;
  background-color: #b39ddb !important;
}

.bg-deep-purple-lighten-2 {
  color: #fff !important;
  background-color: #9575cd !important;
}

.bg-deep-purple-lighten-1 {
  color: #fff !important;
  background-color: #7e57c2 !important;
}

.bg-deep-purple-darken-1 {
  color: #fff !important;
  background-color: #5e35b1 !important;
}

.bg-deep-purple-darken-2 {
  color: #fff !important;
  background-color: #512da8 !important;
}

.bg-deep-purple-darken-3 {
  color: #fff !important;
  background-color: #4527a0 !important;
}

.bg-deep-purple-darken-4 {
  color: #fff !important;
  background-color: #311b92 !important;
}

.bg-deep-purple-accent-1 {
  color: #fff !important;
  background-color: #b388ff !important;
}

.bg-deep-purple-accent-2 {
  color: #fff !important;
  background-color: #7c4dff !important;
}

.bg-deep-purple-accent-3 {
  color: #fff !important;
  background-color: #651fff !important;
}

.bg-deep-purple-accent-4 {
  color: #fff !important;
  background-color: #6200ea !important;
}

.bg-indigo {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.bg-indigo-lighten-5 {
  color: #000 !important;
  background-color: #e8eaf6 !important;
}

.bg-indigo-lighten-4 {
  color: #000 !important;
  background-color: #c5cae9 !important;
}

.bg-indigo-lighten-3 {
  color: #fff !important;
  background-color: #9fa8da !important;
}

.bg-indigo-lighten-2 {
  color: #fff !important;
  background-color: #7986cb !important;
}

.bg-indigo-lighten-1 {
  color: #fff !important;
  background-color: #5c6bc0 !important;
}

.bg-indigo-darken-1 {
  color: #fff !important;
  background-color: #3949ab !important;
}

.bg-indigo-darken-2 {
  color: #fff !important;
  background-color: #303f9f !important;
}

.bg-indigo-darken-3 {
  color: #fff !important;
  background-color: #283593 !important;
}

.bg-indigo-darken-4 {
  color: #fff !important;
  background-color: #1a237e !important;
}

.bg-indigo-accent-1 {
  color: #fff !important;
  background-color: #8c9eff !important;
}

.bg-indigo-accent-2 {
  color: #fff !important;
  background-color: #536dfe !important;
}

.bg-indigo-accent-3 {
  color: #fff !important;
  background-color: #3d5afe !important;
}

.bg-indigo-accent-4 {
  color: #fff !important;
  background-color: #304ffe !important;
}

.bg-blue {
  color: #fff !important;
  background-color: #2196f3 !important;
}

.bg-blue-lighten-5 {
  color: #000 !important;
  background-color: #e3f2fd !important;
}

.bg-blue-lighten-4 {
  color: #000 !important;
  background-color: #bbdefb !important;
}

.bg-blue-lighten-3 {
  color: #000 !important;
  background-color: #90caf9 !important;
}

.bg-blue-lighten-2 {
  color: #000 !important;
  background-color: #64b5f6 !important;
}

.bg-blue-lighten-1 {
  color: #fff !important;
  background-color: #42a5f5 !important;
}

.bg-blue-darken-1 {
  color: #fff !important;
  background-color: #1e88e5 !important;
}

.bg-blue-darken-2 {
  color: #fff !important;
  background-color: #1976d2 !important;
}

.bg-blue-darken-3 {
  color: #fff !important;
  background-color: #1565c0 !important;
}

.bg-blue-darken-4 {
  color: #fff !important;
  background-color: #0d47a1 !important;
}

.bg-blue-accent-1 {
  color: #000 !important;
  background-color: #82b1ff !important;
}

.bg-blue-accent-2 {
  color: #fff !important;
  background-color: #448aff !important;
}

.bg-blue-accent-3 {
  color: #fff !important;
  background-color: #2979ff !important;
}

.bg-blue-accent-4 {
  color: #fff !important;
  background-color: #2962ff !important;
}

.bg-light-blue {
  color: #fff !important;
  background-color: #03a9f4 !important;
}

.bg-light-blue-lighten-5 {
  color: #000 !important;
  background-color: #e1f5fe !important;
}

.bg-light-blue-lighten-4 {
  color: #000 !important;
  background-color: #b3e5fc !important;
}

.bg-light-blue-lighten-3 {
  color: #000 !important;
  background-color: #81d4fa !important;
}

.bg-light-blue-lighten-2 {
  color: #000 !important;
  background-color: #4fc3f7 !important;
}

.bg-light-blue-lighten-1 {
  color: #000 !important;
  background-color: #29b6f6 !important;
}

.bg-light-blue-darken-1 {
  color: #fff !important;
  background-color: #039be5 !important;
}

.bg-light-blue-darken-2 {
  color: #fff !important;
  background-color: #0288d1 !important;
}

.bg-light-blue-darken-3 {
  color: #fff !important;
  background-color: #0277bd !important;
}

.bg-light-blue-darken-4 {
  color: #fff !important;
  background-color: #01579b !important;
}

.bg-light-blue-accent-1 {
  color: #000 !important;
  background-color: #80d8ff !important;
}

.bg-light-blue-accent-2 {
  color: #000 !important;
  background-color: #40c4ff !important;
}

.bg-light-blue-accent-3 {
  color: #fff !important;
  background-color: #00b0ff !important;
}

.bg-light-blue-accent-4 {
  color: #fff !important;
  background-color: #0091ea !important;
}

.bg-cyan {
  color: #000 !important;
  background-color: #00bcd4 !important;
}

.bg-cyan-lighten-5 {
  color: #000 !important;
  background-color: #e0f7fa !important;
}

.bg-cyan-lighten-4 {
  color: #000 !important;
  background-color: #b2ebf2 !important;
}

.bg-cyan-lighten-3 {
  color: #000 !important;
  background-color: #80deea !important;
}

.bg-cyan-lighten-2 {
  color: #000 !important;
  background-color: #4dd0e1 !important;
}

.bg-cyan-lighten-1 {
  color: #000 !important;
  background-color: #26c6da !important;
}

.bg-cyan-darken-1 {
  color: #fff !important;
  background-color: #00acc1 !important;
}

.bg-cyan-darken-2 {
  color: #fff !important;
  background-color: #0097a7 !important;
}

.bg-cyan-darken-3 {
  color: #fff !important;
  background-color: #00838f !important;
}

.bg-cyan-darken-4 {
  color: #fff !important;
  background-color: #006064 !important;
}

.bg-cyan-accent-1 {
  color: #000 !important;
  background-color: #84ffff !important;
}

.bg-cyan-accent-2 {
  color: #000 !important;
  background-color: #18ffff !important;
}

.bg-cyan-accent-3 {
  color: #000 !important;
  background-color: #00e5ff !important;
}

.bg-cyan-accent-4 {
  color: #fff !important;
  background-color: #00b8d4 !important;
}

.bg-teal {
  color: #fff !important;
  background-color: #009688 !important;
}

.bg-teal-lighten-5 {
  color: #000 !important;
  background-color: #e0f2f1 !important;
}

.bg-teal-lighten-4 {
  color: #000 !important;
  background-color: #b2dfdb !important;
}

.bg-teal-lighten-3 {
  color: #000 !important;
  background-color: #80cbc4 !important;
}

.bg-teal-lighten-2 {
  color: #fff !important;
  background-color: #4db6ac !important;
}

.bg-teal-lighten-1 {
  color: #fff !important;
  background-color: #26a69a !important;
}

.bg-teal-darken-1 {
  color: #fff !important;
  background-color: #00897b !important;
}

.bg-teal-darken-2 {
  color: #fff !important;
  background-color: #00796b !important;
}

.bg-teal-darken-3 {
  color: #fff !important;
  background-color: #00695c !important;
}

.bg-teal-darken-4 {
  color: #fff !important;
  background-color: #004d40 !important;
}

.bg-teal-accent-1 {
  color: #000 !important;
  background-color: #a7ffeb !important;
}

.bg-teal-accent-2 {
  color: #000 !important;
  background-color: #64ffda !important;
}

.bg-teal-accent-3 {
  color: #000 !important;
  background-color: #1de9b6 !important;
}

.bg-teal-accent-4 {
  color: #fff !important;
  background-color: #00bfa5 !important;
}

.bg-green {
  color: #fff !important;
  background-color: #4caf50 !important;
}

.bg-green-lighten-5 {
  color: #000 !important;
  background-color: #e8f5e9 !important;
}

.bg-green-lighten-4 {
  color: #000 !important;
  background-color: #c8e6c9 !important;
}

.bg-green-lighten-3 {
  color: #000 !important;
  background-color: #a5d6a7 !important;
}

.bg-green-lighten-2 {
  color: #000 !important;
  background-color: #81c784 !important;
}

.bg-green-lighten-1 {
  color: #fff !important;
  background-color: #66bb6a !important;
}

.bg-green-darken-1 {
  color: #fff !important;
  background-color: #43a047 !important;
}

.bg-green-darken-2 {
  color: #fff !important;
  background-color: #388e3c !important;
}

.bg-green-darken-3 {
  color: #fff !important;
  background-color: #2e7d32 !important;
}

.bg-green-darken-4 {
  color: #fff !important;
  background-color: #1b5e20 !important;
}

.bg-green-accent-1 {
  color: #000 !important;
  background-color: #b9f6ca !important;
}

.bg-green-accent-2 {
  color: #000 !important;
  background-color: #69f0ae !important;
}

.bg-green-accent-3 {
  color: #000 !important;
  background-color: #00e676 !important;
}

.bg-green-accent-4 {
  color: #000 !important;
  background-color: #00c853 !important;
}

.bg-light-green {
  color: #000 !important;
  background-color: #8bc34a !important;
}

.bg-light-green-lighten-5 {
  color: #000 !important;
  background-color: #f1f8e9 !important;
}

.bg-light-green-lighten-4 {
  color: #000 !important;
  background-color: #dcedc8 !important;
}

.bg-light-green-lighten-3 {
  color: #000 !important;
  background-color: #c5e1a5 !important;
}

.bg-light-green-lighten-2 {
  color: #000 !important;
  background-color: #aed581 !important;
}

.bg-light-green-lighten-1 {
  color: #000 !important;
  background-color: #9ccc65 !important;
}

.bg-light-green-darken-1 {
  color: #fff !important;
  background-color: #7cb342 !important;
}

.bg-light-green-darken-2 {
  color: #fff !important;
  background-color: #689f38 !important;
}

.bg-light-green-darken-3 {
  color: #fff !important;
  background-color: #558b2f !important;
}

.bg-light-green-darken-4 {
  color: #fff !important;
  background-color: #33691e !important;
}

.bg-light-green-accent-1 {
  color: #000 !important;
  background-color: #ccff90 !important;
}

.bg-light-green-accent-2 {
  color: #000 !important;
  background-color: #b2ff59 !important;
}

.bg-light-green-accent-3 {
  color: #000 !important;
  background-color: #76ff03 !important;
}

.bg-light-green-accent-4 {
  color: #000 !important;
  background-color: #64dd17 !important;
}

.bg-lime {
  color: #000 !important;
  background-color: #cddc39 !important;
}

.bg-lime-lighten-5 {
  color: #000 !important;
  background-color: #f9fbe7 !important;
}

.bg-lime-lighten-4 {
  color: #000 !important;
  background-color: #f0f4c3 !important;
}

.bg-lime-lighten-3 {
  color: #000 !important;
  background-color: #e6ee9c !important;
}

.bg-lime-lighten-2 {
  color: #000 !important;
  background-color: #dce775 !important;
}

.bg-lime-lighten-1 {
  color: #000 !important;
  background-color: #d4e157 !important;
}

.bg-lime-darken-1 {
  color: #000 !important;
  background-color: #c0ca33 !important;
}

.bg-lime-darken-2 {
  color: #000 !important;
  background-color: #afb42b !important;
}

.bg-lime-darken-3 {
  color: #fff !important;
  background-color: #9e9d24 !important;
}

.bg-lime-darken-4 {
  color: #fff !important;
  background-color: #827717 !important;
}

.bg-lime-accent-1 {
  color: #000 !important;
  background-color: #f4ff81 !important;
}

.bg-lime-accent-2 {
  color: #000 !important;
  background-color: #eeff41 !important;
}

.bg-lime-accent-3 {
  color: #000 !important;
  background-color: #c6ff00 !important;
}

.bg-lime-accent-4 {
  color: #000 !important;
  background-color: #aeea00 !important;
}

.bg-yellow {
  color: #000 !important;
  background-color: #ffeb3b !important;
}

.bg-yellow-lighten-5 {
  color: #000 !important;
  background-color: #fffde7 !important;
}

.bg-yellow-lighten-4 {
  color: #000 !important;
  background-color: #fff9c4 !important;
}

.bg-yellow-lighten-3 {
  color: #000 !important;
  background-color: #fff59d !important;
}

.bg-yellow-lighten-2 {
  color: #000 !important;
  background-color: #fff176 !important;
}

.bg-yellow-lighten-1 {
  color: #000 !important;
  background-color: #ffee58 !important;
}

.bg-yellow-darken-1 {
  color: #000 !important;
  background-color: #fdd835 !important;
}

.bg-yellow-darken-2 {
  color: #000 !important;
  background-color: #fbc02d !important;
}

.bg-yellow-darken-3 {
  color: #000 !important;
  background-color: #f9a825 !important;
}

.bg-yellow-darken-4 {
  color: #fff !important;
  background-color: #f57f17 !important;
}

.bg-yellow-accent-1 {
  color: #000 !important;
  background-color: #ffff8d !important;
}

.bg-yellow-accent-2 {
  color: #000 !important;
  background-color: #ff0 !important;
}

.bg-yellow-accent-3 {
  color: #000 !important;
  background-color: #ffea00 !important;
}

.bg-yellow-accent-4 {
  color: #000 !important;
  background-color: #ffd600 !important;
}

.bg-amber {
  color: #000 !important;
  background-color: #ffc107 !important;
}

.bg-amber-lighten-5 {
  color: #000 !important;
  background-color: #fff8e1 !important;
}

.bg-amber-lighten-4 {
  color: #000 !important;
  background-color: #ffecb3 !important;
}

.bg-amber-lighten-3 {
  color: #000 !important;
  background-color: #ffe082 !important;
}

.bg-amber-lighten-2 {
  color: #000 !important;
  background-color: #ffd54f !important;
}

.bg-amber-lighten-1 {
  color: #000 !important;
  background-color: #ffca28 !important;
}

.bg-amber-darken-1 {
  color: #000 !important;
  background-color: #ffb300 !important;
}

.bg-amber-darken-2 {
  color: #000 !important;
  background-color: #ffa000 !important;
}

.bg-amber-darken-3 {
  color: #000 !important;
  background-color: #ff8f00 !important;
}

.bg-amber-darken-4 {
  color: #fff !important;
  background-color: #ff6f00 !important;
}

.bg-amber-accent-1 {
  color: #000 !important;
  background-color: #ffe57f !important;
}

.bg-amber-accent-2 {
  color: #000 !important;
  background-color: #ffd740 !important;
}

.bg-amber-accent-3 {
  color: #000 !important;
  background-color: #ffc400 !important;
}

.bg-amber-accent-4 {
  color: #000 !important;
  background-color: #ffab00 !important;
}

.bg-orange {
  color: #000 !important;
  background-color: #ff9800 !important;
}

.bg-orange-lighten-5 {
  color: #000 !important;
  background-color: #fff3e0 !important;
}

.bg-orange-lighten-4 {
  color: #000 !important;
  background-color: #ffe0b2 !important;
}

.bg-orange-lighten-3 {
  color: #000 !important;
  background-color: #ffcc80 !important;
}

.bg-orange-lighten-2 {
  color: #000 !important;
  background-color: #ffb74d !important;
}

.bg-orange-lighten-1 {
  color: #000 !important;
  background-color: #ffa726 !important;
}

.bg-orange-darken-1 {
  color: #fff !important;
  background-color: #fb8c00 !important;
}

.bg-orange-darken-2 {
  color: #fff !important;
  background-color: #f57c00 !important;
}

.bg-orange-darken-3 {
  color: #fff !important;
  background-color: #ef6c00 !important;
}

.bg-orange-darken-4 {
  color: #fff !important;
  background-color: #e65100 !important;
}

.bg-orange-accent-1 {
  color: #000 !important;
  background-color: #ffd180 !important;
}

.bg-orange-accent-2 {
  color: #000 !important;
  background-color: #ffab40 !important;
}

.bg-orange-accent-3 {
  color: #000 !important;
  background-color: #ff9100 !important;
}

.bg-orange-accent-4 {
  color: #fff !important;
  background-color: #ff6d00 !important;
}

.bg-deep-orange {
  color: #fff !important;
  background-color: #ff5722 !important;
}

.bg-deep-orange-lighten-5 {
  color: #000 !important;
  background-color: #fbe9e7 !important;
}

.bg-deep-orange-lighten-4 {
  color: #000 !important;
  background-color: #ffccbc !important;
}

.bg-deep-orange-lighten-3 {
  color: #000 !important;
  background-color: #ffab91 !important;
}

.bg-deep-orange-lighten-2 {
  color: #000 !important;
  background-color: #ff8a65 !important;
}

.bg-deep-orange-lighten-1 {
  color: #fff !important;
  background-color: #ff7043 !important;
}

.bg-deep-orange-darken-1 {
  color: #fff !important;
  background-color: #f4511e !important;
}

.bg-deep-orange-darken-2 {
  color: #fff !important;
  background-color: #e64a19 !important;
}

.bg-deep-orange-darken-3 {
  color: #fff !important;
  background-color: #d84315 !important;
}

.bg-deep-orange-darken-4 {
  color: #fff !important;
  background-color: #bf360c !important;
}

.bg-deep-orange-accent-1 {
  color: #000 !important;
  background-color: #ff9e80 !important;
}

.bg-deep-orange-accent-2 {
  color: #fff !important;
  background-color: #ff6e40 !important;
}

.bg-deep-orange-accent-3 {
  color: #fff !important;
  background-color: #ff3d00 !important;
}

.bg-deep-orange-accent-4 {
  color: #fff !important;
  background-color: #dd2c00 !important;
}

.bg-brown {
  color: #fff !important;
  background-color: #795548 !important;
}

.bg-brown-lighten-5 {
  color: #000 !important;
  background-color: #efebe9 !important;
}

.bg-brown-lighten-4 {
  color: #000 !important;
  background-color: #d7ccc8 !important;
}

.bg-brown-lighten-3 {
  color: #000 !important;
  background-color: #bcaaa4 !important;
}

.bg-brown-lighten-2 {
  color: #fff !important;
  background-color: #a1887f !important;
}

.bg-brown-lighten-1 {
  color: #fff !important;
  background-color: #8d6e63 !important;
}

.bg-brown-darken-1 {
  color: #fff !important;
  background-color: #6d4c41 !important;
}

.bg-brown-darken-2 {
  color: #fff !important;
  background-color: #5d4037 !important;
}

.bg-brown-darken-3 {
  color: #fff !important;
  background-color: #4e342e !important;
}

.bg-brown-darken-4 {
  color: #fff !important;
  background-color: #3e2723 !important;
}

.bg-blue-grey {
  color: #fff !important;
  background-color: #607d8b !important;
}

.bg-blue-grey-lighten-5 {
  color: #000 !important;
  background-color: #eceff1 !important;
}

.bg-blue-grey-lighten-4 {
  color: #000 !important;
  background-color: #cfd8dc !important;
}

.bg-blue-grey-lighten-3 {
  color: #000 !important;
  background-color: #b0bec5 !important;
}

.bg-blue-grey-lighten-2 {
  color: #fff !important;
  background-color: #90a4ae !important;
}

.bg-blue-grey-lighten-1 {
  color: #fff !important;
  background-color: #78909c !important;
}

.bg-blue-grey-darken-1 {
  color: #fff !important;
  background-color: #546e7a !important;
}

.bg-blue-grey-darken-2 {
  color: #fff !important;
  background-color: #455a64 !important;
}

.bg-blue-grey-darken-3 {
  color: #fff !important;
  background-color: #37474f !important;
}

.bg-blue-grey-darken-4 {
  color: #fff !important;
  background-color: #263238 !important;
}

.bg-grey {
  color: #fff !important;
  background-color: #9e9e9e !important;
}

.bg-grey-lighten-5 {
  color: #000 !important;
  background-color: #fafafa !important;
}

.bg-grey-lighten-4 {
  color: #000 !important;
  background-color: #f5f5f5 !important;
}

.bg-grey-lighten-3 {
  color: #000 !important;
  background-color: #eee !important;
}

.bg-grey-lighten-2 {
  color: #000 !important;
  background-color: #e0e0e0 !important;
}

.bg-grey-lighten-1 {
  color: #000 !important;
  background-color: #bdbdbd !important;
}

.bg-grey-darken-1 {
  color: #fff !important;
  background-color: #757575 !important;
}

.bg-grey-darken-2 {
  color: #fff !important;
  background-color: #616161 !important;
}

.bg-grey-darken-3 {
  color: #fff !important;
  background-color: #424242 !important;
}

.bg-grey-darken-4 {
  color: #fff !important;
  background-color: #212121 !important;
}

.bg-shades-black {
  color: #fff !important;
  background-color: #000 !important;
}

.bg-shades-white {
  color: #000 !important;
  background-color: #fff !important;
}

.bg-shades-transparent {
  color: currentColor !important;
  background-color: #0000 !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-transparent {
  color: #0000 !important;
}

.text-red {
  color: #f44336 !important;
}

.text-red-lighten-5 {
  color: #ffebee !important;
}

.text-red-lighten-4 {
  color: #ffcdd2 !important;
}

.text-red-lighten-3 {
  color: #ef9a9a !important;
}

.text-red-lighten-2 {
  color: #e57373 !important;
}

.text-red-lighten-1 {
  color: #ef5350 !important;
}

.text-red-darken-1 {
  color: #e53935 !important;
}

.text-red-darken-2 {
  color: #d32f2f !important;
}

.text-red-darken-3 {
  color: #c62828 !important;
}

.text-red-darken-4 {
  color: #b71c1c !important;
}

.text-red-accent-1 {
  color: #ff8a80 !important;
}

.text-red-accent-2 {
  color: #ff5252 !important;
}

.text-red-accent-3 {
  color: #ff1744 !important;
}

.text-red-accent-4 {
  color: #d50000 !important;
}

.text-pink {
  color: #e91e63 !important;
}

.text-pink-lighten-5 {
  color: #fce4ec !important;
}

.text-pink-lighten-4 {
  color: #f8bbd0 !important;
}

.text-pink-lighten-3 {
  color: #f48fb1 !important;
}

.text-pink-lighten-2 {
  color: #f06292 !important;
}

.text-pink-lighten-1 {
  color: #ec407a !important;
}

.text-pink-darken-1 {
  color: #d81b60 !important;
}

.text-pink-darken-2 {
  color: #c2185b !important;
}

.text-pink-darken-3 {
  color: #ad1457 !important;
}

.text-pink-darken-4 {
  color: #880e4f !important;
}

.text-pink-accent-1 {
  color: #ff80ab !important;
}

.text-pink-accent-2 {
  color: #ff4081 !important;
}

.text-pink-accent-3 {
  color: #f50057 !important;
}

.text-pink-accent-4 {
  color: #c51162 !important;
}

.text-purple {
  color: #9c27b0 !important;
}

.text-purple-lighten-5 {
  color: #f3e5f5 !important;
}

.text-purple-lighten-4 {
  color: #e1bee7 !important;
}

.text-purple-lighten-3 {
  color: #ce93d8 !important;
}

.text-purple-lighten-2 {
  color: #ba68c8 !important;
}

.text-purple-lighten-1 {
  color: #ab47bc !important;
}

.text-purple-darken-1 {
  color: #8e24aa !important;
}

.text-purple-darken-2 {
  color: #7b1fa2 !important;
}

.text-purple-darken-3 {
  color: #6a1b9a !important;
}

.text-purple-darken-4 {
  color: #4a148c !important;
}

.text-purple-accent-1 {
  color: #ea80fc !important;
}

.text-purple-accent-2 {
  color: #e040fb !important;
}

.text-purple-accent-3 {
  color: #d500f9 !important;
}

.text-purple-accent-4 {
  color: #a0f !important;
}

.text-deep-purple {
  color: #673ab7 !important;
}

.text-deep-purple-lighten-5 {
  color: #ede7f6 !important;
}

.text-deep-purple-lighten-4 {
  color: #d1c4e9 !important;
}

.text-deep-purple-lighten-3 {
  color: #b39ddb !important;
}

.text-deep-purple-lighten-2 {
  color: #9575cd !important;
}

.text-deep-purple-lighten-1 {
  color: #7e57c2 !important;
}

.text-deep-purple-darken-1 {
  color: #5e35b1 !important;
}

.text-deep-purple-darken-2 {
  color: #512da8 !important;
}

.text-deep-purple-darken-3 {
  color: #4527a0 !important;
}

.text-deep-purple-darken-4 {
  color: #311b92 !important;
}

.text-deep-purple-accent-1 {
  color: #b388ff !important;
}

.text-deep-purple-accent-2 {
  color: #7c4dff !important;
}

.text-deep-purple-accent-3 {
  color: #651fff !important;
}

.text-deep-purple-accent-4 {
  color: #6200ea !important;
}

.text-indigo {
  color: #3f51b5 !important;
}

.text-indigo-lighten-5 {
  color: #e8eaf6 !important;
}

.text-indigo-lighten-4 {
  color: #c5cae9 !important;
}

.text-indigo-lighten-3 {
  color: #9fa8da !important;
}

.text-indigo-lighten-2 {
  color: #7986cb !important;
}

.text-indigo-lighten-1 {
  color: #5c6bc0 !important;
}

.text-indigo-darken-1 {
  color: #3949ab !important;
}

.text-indigo-darken-2 {
  color: #303f9f !important;
}

.text-indigo-darken-3 {
  color: #283593 !important;
}

.text-indigo-darken-4 {
  color: #1a237e !important;
}

.text-indigo-accent-1 {
  color: #8c9eff !important;
}

.text-indigo-accent-2 {
  color: #536dfe !important;
}

.text-indigo-accent-3 {
  color: #3d5afe !important;
}

.text-indigo-accent-4 {
  color: #304ffe !important;
}

.text-blue {
  color: #2196f3 !important;
}

.text-blue-lighten-5 {
  color: #e3f2fd !important;
}

.text-blue-lighten-4 {
  color: #bbdefb !important;
}

.text-blue-lighten-3 {
  color: #90caf9 !important;
}

.text-blue-lighten-2 {
  color: #64b5f6 !important;
}

.text-blue-lighten-1 {
  color: #42a5f5 !important;
}

.text-blue-darken-1 {
  color: #1e88e5 !important;
}

.text-blue-darken-2 {
  color: #1976d2 !important;
}

.text-blue-darken-3 {
  color: #1565c0 !important;
}

.text-blue-darken-4 {
  color: #0d47a1 !important;
}

.text-blue-accent-1 {
  color: #82b1ff !important;
}

.text-blue-accent-2 {
  color: #448aff !important;
}

.text-blue-accent-3 {
  color: #2979ff !important;
}

.text-blue-accent-4 {
  color: #2962ff !important;
}

.text-light-blue {
  color: #03a9f4 !important;
}

.text-light-blue-lighten-5 {
  color: #e1f5fe !important;
}

.text-light-blue-lighten-4 {
  color: #b3e5fc !important;
}

.text-light-blue-lighten-3 {
  color: #81d4fa !important;
}

.text-light-blue-lighten-2 {
  color: #4fc3f7 !important;
}

.text-light-blue-lighten-1 {
  color: #29b6f6 !important;
}

.text-light-blue-darken-1 {
  color: #039be5 !important;
}

.text-light-blue-darken-2 {
  color: #0288d1 !important;
}

.text-light-blue-darken-3 {
  color: #0277bd !important;
}

.text-light-blue-darken-4 {
  color: #01579b !important;
}

.text-light-blue-accent-1 {
  color: #80d8ff !important;
}

.text-light-blue-accent-2 {
  color: #40c4ff !important;
}

.text-light-blue-accent-3 {
  color: #00b0ff !important;
}

.text-light-blue-accent-4 {
  color: #0091ea !important;
}

.text-cyan {
  color: #00bcd4 !important;
}

.text-cyan-lighten-5 {
  color: #e0f7fa !important;
}

.text-cyan-lighten-4 {
  color: #b2ebf2 !important;
}

.text-cyan-lighten-3 {
  color: #80deea !important;
}

.text-cyan-lighten-2 {
  color: #4dd0e1 !important;
}

.text-cyan-lighten-1 {
  color: #26c6da !important;
}

.text-cyan-darken-1 {
  color: #00acc1 !important;
}

.text-cyan-darken-2 {
  color: #0097a7 !important;
}

.text-cyan-darken-3 {
  color: #00838f !important;
}

.text-cyan-darken-4 {
  color: #006064 !important;
}

.text-cyan-accent-1 {
  color: #84ffff !important;
}

.text-cyan-accent-2 {
  color: #18ffff !important;
}

.text-cyan-accent-3 {
  color: #00e5ff !important;
}

.text-cyan-accent-4 {
  color: #00b8d4 !important;
}

.text-teal {
  color: #009688 !important;
}

.text-teal-lighten-5 {
  color: #e0f2f1 !important;
}

.text-teal-lighten-4 {
  color: #b2dfdb !important;
}

.text-teal-lighten-3 {
  color: #80cbc4 !important;
}

.text-teal-lighten-2 {
  color: #4db6ac !important;
}

.text-teal-lighten-1 {
  color: #26a69a !important;
}

.text-teal-darken-1 {
  color: #00897b !important;
}

.text-teal-darken-2 {
  color: #00796b !important;
}

.text-teal-darken-3 {
  color: #00695c !important;
}

.text-teal-darken-4 {
  color: #004d40 !important;
}

.text-teal-accent-1 {
  color: #a7ffeb !important;
}

.text-teal-accent-2 {
  color: #64ffda !important;
}

.text-teal-accent-3 {
  color: #1de9b6 !important;
}

.text-teal-accent-4 {
  color: #00bfa5 !important;
}

.text-green {
  color: #4caf50 !important;
}

.text-green-lighten-5 {
  color: #e8f5e9 !important;
}

.text-green-lighten-4 {
  color: #c8e6c9 !important;
}

.text-green-lighten-3 {
  color: #a5d6a7 !important;
}

.text-green-lighten-2 {
  color: #81c784 !important;
}

.text-green-lighten-1 {
  color: #66bb6a !important;
}

.text-green-darken-1 {
  color: #43a047 !important;
}

.text-green-darken-2 {
  color: #388e3c !important;
}

.text-green-darken-3 {
  color: #2e7d32 !important;
}

.text-green-darken-4 {
  color: #1b5e20 !important;
}

.text-green-accent-1 {
  color: #b9f6ca !important;
}

.text-green-accent-2 {
  color: #69f0ae !important;
}

.text-green-accent-3 {
  color: #00e676 !important;
}

.text-green-accent-4 {
  color: #00c853 !important;
}

.text-light-green {
  color: #8bc34a !important;
}

.text-light-green-lighten-5 {
  color: #f1f8e9 !important;
}

.text-light-green-lighten-4 {
  color: #dcedc8 !important;
}

.text-light-green-lighten-3 {
  color: #c5e1a5 !important;
}

.text-light-green-lighten-2 {
  color: #aed581 !important;
}

.text-light-green-lighten-1 {
  color: #9ccc65 !important;
}

.text-light-green-darken-1 {
  color: #7cb342 !important;
}

.text-light-green-darken-2 {
  color: #689f38 !important;
}

.text-light-green-darken-3 {
  color: #558b2f !important;
}

.text-light-green-darken-4 {
  color: #33691e !important;
}

.text-light-green-accent-1 {
  color: #ccff90 !important;
}

.text-light-green-accent-2 {
  color: #b2ff59 !important;
}

.text-light-green-accent-3 {
  color: #76ff03 !important;
}

.text-light-green-accent-4 {
  color: #64dd17 !important;
}

.text-lime {
  color: #cddc39 !important;
}

.text-lime-lighten-5 {
  color: #f9fbe7 !important;
}

.text-lime-lighten-4 {
  color: #f0f4c3 !important;
}

.text-lime-lighten-3 {
  color: #e6ee9c !important;
}

.text-lime-lighten-2 {
  color: #dce775 !important;
}

.text-lime-lighten-1 {
  color: #d4e157 !important;
}

.text-lime-darken-1 {
  color: #c0ca33 !important;
}

.text-lime-darken-2 {
  color: #afb42b !important;
}

.text-lime-darken-3 {
  color: #9e9d24 !important;
}

.text-lime-darken-4 {
  color: #827717 !important;
}

.text-lime-accent-1 {
  color: #f4ff81 !important;
}

.text-lime-accent-2 {
  color: #eeff41 !important;
}

.text-lime-accent-3 {
  color: #c6ff00 !important;
}

.text-lime-accent-4 {
  color: #aeea00 !important;
}

.text-yellow {
  color: #ffeb3b !important;
}

.text-yellow-lighten-5 {
  color: #fffde7 !important;
}

.text-yellow-lighten-4 {
  color: #fff9c4 !important;
}

.text-yellow-lighten-3 {
  color: #fff59d !important;
}

.text-yellow-lighten-2 {
  color: #fff176 !important;
}

.text-yellow-lighten-1 {
  color: #ffee58 !important;
}

.text-yellow-darken-1 {
  color: #fdd835 !important;
}

.text-yellow-darken-2 {
  color: #fbc02d !important;
}

.text-yellow-darken-3 {
  color: #f9a825 !important;
}

.text-yellow-darken-4 {
  color: #f57f17 !important;
}

.text-yellow-accent-1 {
  color: #ffff8d !important;
}

.text-yellow-accent-2 {
  color: #ff0 !important;
}

.text-yellow-accent-3 {
  color: #ffea00 !important;
}

.text-yellow-accent-4 {
  color: #ffd600 !important;
}

.text-amber {
  color: #ffc107 !important;
}

.text-amber-lighten-5 {
  color: #fff8e1 !important;
}

.text-amber-lighten-4 {
  color: #ffecb3 !important;
}

.text-amber-lighten-3 {
  color: #ffe082 !important;
}

.text-amber-lighten-2 {
  color: #ffd54f !important;
}

.text-amber-lighten-1 {
  color: #ffca28 !important;
}

.text-amber-darken-1 {
  color: #ffb300 !important;
}

.text-amber-darken-2 {
  color: #ffa000 !important;
}

.text-amber-darken-3 {
  color: #ff8f00 !important;
}

.text-amber-darken-4 {
  color: #ff6f00 !important;
}

.text-amber-accent-1 {
  color: #ffe57f !important;
}

.text-amber-accent-2 {
  color: #ffd740 !important;
}

.text-amber-accent-3 {
  color: #ffc400 !important;
}

.text-amber-accent-4 {
  color: #ffab00 !important;
}

.text-orange {
  color: #ff9800 !important;
}

.text-orange-lighten-5 {
  color: #fff3e0 !important;
}

.text-orange-lighten-4 {
  color: #ffe0b2 !important;
}

.text-orange-lighten-3 {
  color: #ffcc80 !important;
}

.text-orange-lighten-2 {
  color: #ffb74d !important;
}

.text-orange-lighten-1 {
  color: #ffa726 !important;
}

.text-orange-darken-1 {
  color: #fb8c00 !important;
}

.text-orange-darken-2 {
  color: #f57c00 !important;
}

.text-orange-darken-3 {
  color: #ef6c00 !important;
}

.text-orange-darken-4 {
  color: #e65100 !important;
}

.text-orange-accent-1 {
  color: #ffd180 !important;
}

.text-orange-accent-2 {
  color: #ffab40 !important;
}

.text-orange-accent-3 {
  color: #ff9100 !important;
}

.text-orange-accent-4 {
  color: #ff6d00 !important;
}

.text-deep-orange {
  color: #ff5722 !important;
}

.text-deep-orange-lighten-5 {
  color: #fbe9e7 !important;
}

.text-deep-orange-lighten-4 {
  color: #ffccbc !important;
}

.text-deep-orange-lighten-3 {
  color: #ffab91 !important;
}

.text-deep-orange-lighten-2 {
  color: #ff8a65 !important;
}

.text-deep-orange-lighten-1 {
  color: #ff7043 !important;
}

.text-deep-orange-darken-1 {
  color: #f4511e !important;
}

.text-deep-orange-darken-2 {
  color: #e64a19 !important;
}

.text-deep-orange-darken-3 {
  color: #d84315 !important;
}

.text-deep-orange-darken-4 {
  color: #bf360c !important;
}

.text-deep-orange-accent-1 {
  color: #ff9e80 !important;
}

.text-deep-orange-accent-2 {
  color: #ff6e40 !important;
}

.text-deep-orange-accent-3 {
  color: #ff3d00 !important;
}

.text-deep-orange-accent-4 {
  color: #dd2c00 !important;
}

.text-brown {
  color: #795548 !important;
}

.text-brown-lighten-5 {
  color: #efebe9 !important;
}

.text-brown-lighten-4 {
  color: #d7ccc8 !important;
}

.text-brown-lighten-3 {
  color: #bcaaa4 !important;
}

.text-brown-lighten-2 {
  color: #a1887f !important;
}

.text-brown-lighten-1 {
  color: #8d6e63 !important;
}

.text-brown-darken-1 {
  color: #6d4c41 !important;
}

.text-brown-darken-2 {
  color: #5d4037 !important;
}

.text-brown-darken-3 {
  color: #4e342e !important;
}

.text-brown-darken-4 {
  color: #3e2723 !important;
}

.text-blue-grey {
  color: #607d8b !important;
}

.text-blue-grey-lighten-5 {
  color: #eceff1 !important;
}

.text-blue-grey-lighten-4 {
  color: #cfd8dc !important;
}

.text-blue-grey-lighten-3 {
  color: #b0bec5 !important;
}

.text-blue-grey-lighten-2 {
  color: #90a4ae !important;
}

.text-blue-grey-lighten-1 {
  color: #78909c !important;
}

.text-blue-grey-darken-1 {
  color: #546e7a !important;
}

.text-blue-grey-darken-2 {
  color: #455a64 !important;
}

.text-blue-grey-darken-3 {
  color: #37474f !important;
}

.text-blue-grey-darken-4 {
  color: #263238 !important;
}

.text-grey {
  color: #9e9e9e !important;
}

.text-grey-lighten-5 {
  color: #fafafa !important;
}

.text-grey-lighten-4 {
  color: #f5f5f5 !important;
}

.text-grey-lighten-3 {
  color: #eee !important;
}

.text-grey-lighten-2 {
  color: #e0e0e0 !important;
}

.text-grey-lighten-1 {
  color: #bdbdbd !important;
}

.text-grey-darken-1 {
  color: #757575 !important;
}

.text-grey-darken-2 {
  color: #616161 !important;
}

.text-grey-darken-3 {
  color: #424242 !important;
}

.text-grey-darken-4 {
  color: #212121 !important;
}

.text-shades-black {
  color: #000 !important;
}

.text-shades-white {
  color: #fff !important;
}

.text-shades-transparent {
  color: #0000 !important;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  overflow-y: scroll;
}

*, :before, :after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

hr {
  height: 0;
  overflow: visible;
}

details, main {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
}

pre {
  font-size: 1em;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

input {
  border-radius: 0;
}

[disabled] {
  cursor: default;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"], [role="button"] {
  cursor: pointer;
  color: inherit;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]::-moz-focus-inner {
  outline: 1px dotted buttontext;
}

[type="reset"]::-moz-focus-inner {
  outline: 1px dotted buttontext;
}

[type="submit"]::-moz-focus-inner {
  outline: 1px dotted buttontext;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button, input, select, textarea {
  background-color: #0000;
  border-style: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  white-space: normal;
  border: 0;
  max-width: 100%;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"] {
  cursor: default;
}

.dialog-transition-enter-active, .dialog-bottom-transition-enter-active, .dialog-top-transition-enter-active {
  transition-duration: .225s !important;
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.dialog-transition-leave-active, .dialog-bottom-transition-leave-active, .dialog-top-transition-leave-active {
  transition-duration: .125s !important;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.dialog-transition-enter-active, .dialog-transition-leave-active, .dialog-bottom-transition-enter-active, .dialog-bottom-transition-leave-active, .dialog-top-transition-enter-active, .dialog-top-transition-leave-active {
  pointer-events: none;
  transition-property: transform, opacity !important;
}

.dialog-transition-enter-from, .dialog-transition-leave-to {
  opacity: 0;
  transform: scale(.9);
}

.dialog-transition-enter-to, .dialog-transition-leave-from {
  opacity: 1;
}

.dialog-bottom-transition-enter-from, .dialog-bottom-transition-leave-to {
  transform: translateY(calc(50vh + 50%));
}

.dialog-top-transition-enter-from, .dialog-top-transition-leave-to {
  transform: translateY(calc(-50vh - 50%));
}

.picker-transition-enter-active, .picker-reverse-transition-enter-active, .picker-transition-leave-active, .picker-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-transition-move, .picker-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-transition-enter-from, .picker-transition-leave-to, .picker-reverse-transition-enter-from, .picker-reverse-transition-leave-to {
  opacity: 0;
}

.picker-transition-leave-from, .picker-transition-leave-active, .picker-transition-leave-to, .picker-reverse-transition-leave-from, .picker-reverse-transition-leave-active, .picker-reverse-transition-leave-to {
  position: absolute !important;
}

.picker-transition-enter-active, .picker-transition-leave-active, .picker-reverse-transition-enter-active, .picker-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.picker-transition-enter-active, .picker-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-transition-enter-from {
  transform: translate(100%);
}

.picker-transition-leave-to {
  transform: translate(-100%);
}

.picker-reverse-transition-enter-active, .picker-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.picker-reverse-transition-enter-from {
  transform: translate(-100%);
}

.picker-reverse-transition-leave-to {
  transform: translate(100%);
}

.expand-transition-enter-active, .expand-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.expand-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.expand-transition-enter-active, .expand-transition-leave-active {
  transition-property: height !important;
}

.expand-x-transition-enter-active, .expand-x-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.expand-x-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.expand-x-transition-enter-active, .expand-x-transition-leave-active {
  transition-property: width !important;
}

.scale-transition-enter-active, .scale-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-transition-leave-to {
  opacity: 0;
}

.scale-transition-leave-active {
  transition-duration: .1s !important;
}

.scale-transition-enter-from {
  opacity: 0;
  transform: scale(0);
}

.scale-transition-enter-active, .scale-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scale-rotate-transition-enter-active, .scale-rotate-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-rotate-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-rotate-transition-leave-to {
  opacity: 0;
}

.scale-rotate-transition-leave-active {
  transition-duration: .1s !important;
}

.scale-rotate-transition-enter-from {
  opacity: 0;
  transform: scale(0)rotate(-45deg);
}

.scale-rotate-transition-enter-active, .scale-rotate-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scale-rotate-reverse-transition-enter-active, .scale-rotate-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-rotate-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scale-rotate-reverse-transition-leave-to {
  opacity: 0;
}

.scale-rotate-reverse-transition-leave-active {
  transition-duration: .1s !important;
}

.scale-rotate-reverse-transition-enter-from {
  opacity: 0;
  transform: scale(0)rotate(45deg);
}

.scale-rotate-reverse-transition-enter-active, .scale-rotate-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.message-transition-enter-active, .message-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.message-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.message-transition-enter-from, .message-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.message-transition-leave-from, .message-transition-leave-active {
  position: absolute;
}

.message-transition-enter-active, .message-transition-leave-active {
  transition-property: transform, opacity !important;
}

.slide-y-transition-enter-active, .slide-y-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-y-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-y-transition-enter-from, .slide-y-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.slide-y-transition-enter-active, .slide-y-transition-leave-active {
  transition-property: transform, opacity !important;
}

.slide-y-reverse-transition-enter-active, .slide-y-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-y-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-y-reverse-transition-enter-from, .slide-y-reverse-transition-leave-to {
  opacity: 0;
  transform: translateY(15px);
}

.slide-y-reverse-transition-enter-active, .slide-y-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-y-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-y-transition-enter-from, .scroll-y-transition-leave-to {
  opacity: 0;
}

.scroll-y-transition-enter-from {
  transform: translateY(-15px);
}

.scroll-y-transition-leave-to {
  transform: translateY(15px);
}

.scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scroll-y-reverse-transition-enter-active, .scroll-y-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-y-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-y-reverse-transition-enter-from, .scroll-y-reverse-transition-leave-to {
  opacity: 0;
}

.scroll-y-reverse-transition-enter-from {
  transform: translateY(15px);
}

.scroll-y-reverse-transition-leave-to {
  transform: translateY(-15px);
}

.scroll-y-reverse-transition-enter-active, .scroll-y-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-x-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-x-transition-enter-from, .scroll-x-transition-leave-to {
  opacity: 0;
}

.scroll-x-transition-enter-from {
  transform: translateX(-15px);
}

.scroll-x-transition-leave-to {
  transform: translateX(15px);
}

.scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
  transition-property: transform, opacity !important;
}

.scroll-x-reverse-transition-enter-active, .scroll-x-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-x-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.scroll-x-reverse-transition-enter-from, .scroll-x-reverse-transition-leave-to {
  opacity: 0;
}

.scroll-x-reverse-transition-enter-from {
  transform: translateX(15px);
}

.scroll-x-reverse-transition-leave-to {
  transform: translateX(-15px);
}

.scroll-x-reverse-transition-enter-active, .scroll-x-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.slide-x-transition-enter-active, .slide-x-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-x-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-x-transition-enter-from, .slide-x-transition-leave-to {
  opacity: 0;
  transform: translateX(-15px);
}

.slide-x-transition-enter-active, .slide-x-transition-leave-active {
  transition-property: transform, opacity !important;
}

.slide-x-reverse-transition-enter-active, .slide-x-reverse-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-x-reverse-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.slide-x-reverse-transition-enter-from, .slide-x-reverse-transition-leave-to {
  opacity: 0;
  transform: translateX(15px);
}

.slide-x-reverse-transition-enter-active, .slide-x-reverse-transition-leave-active {
  transition-property: transform, opacity !important;
}

.fade-transition-enter-active, .fade-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.fade-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.fade-transition-enter-from, .fade-transition-leave-to {
  opacity: 0 !important;
}

.fade-transition-enter-active, .fade-transition-leave-active {
  transition-property: opacity !important;
}

.fab-transition-enter-active, .fab-transition-leave-active {
  transition-duration: .3s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.fab-transition-move {
  transition-property: transform !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.fab-transition-enter-from, .fab-transition-leave-to {
  transform: scale(0)rotate(-45deg);
}

.fab-transition-enter-active, .fab-transition-leave-active {
  transition-property: transform !important;
}

.v-locale--is-rtl {
  direction: rtl;
}

.v-locale--is-ltr {
  direction: ltr;
}

.blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: #0000;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow-x: hidden;
}

html.overflow-y-hidden {
  overflow-y: hidden !important;
}

:root {
  --v-theme-overlay-multiplier: 1;
  --v-scrollbar-offset: 0px;
}

@supports (-webkit-touch-callout: none) {
  body {
    cursor: pointer;
  }
}

@media only print {
  .hidden-print-only {
    display: none !important;
  }
}

@media only screen {
  .hidden-screen-only {
    display: none !important;
  }
}

@media (width <= 599.98px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (width >= 600px) and (width <= 959.98px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (width >= 960px) and (width <= 1279.98px) {
  .hidden-md {
    display: none !important;
  }
}

@media (width >= 1280px) and (width <= 1919.98px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (width >= 1920px) and (width <= 2559.98px) {
  .hidden-xl {
    display: none !important;
  }
}

@media (width >= 2560px) {
  .hidden-xxl {
    display: none !important;
  }
}

@media (width >= 600px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}

@media (width >= 960px) {
  .hidden-md-and-up {
    display: none !important;
  }
}

@media (width >= 1280px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}

@media (width >= 1920px) {
  .hidden-xl-and-up {
    display: none !important;
  }
}

@media (width <= 959.98px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}

@media (width <= 1279.98px) {
  .hidden-md-and-down {
    display: none !important;
  }
}

@media (width <= 1919.98px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}

@media (width <= 2559.98px) {
  .hidden-xl-and-down {
    display: none !important;
  }
}

.elevation-24 {
  box-shadow: 0px 11px 15px -7px var(--v-shadow-key-umbra-opacity, #0003), 0px 24px 38px 3px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 9px 46px 8px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-23 {
  box-shadow: 0px 11px 14px -7px var(--v-shadow-key-umbra-opacity, #0003), 0px 23px 36px 3px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 9px 44px 8px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-22 {
  box-shadow: 0px 10px 14px -6px var(--v-shadow-key-umbra-opacity, #0003), 0px 22px 35px 3px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 8px 42px 7px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-21 {
  box-shadow: 0px 10px 13px -6px var(--v-shadow-key-umbra-opacity, #0003), 0px 21px 33px 3px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 8px 40px 7px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-20 {
  box-shadow: 0px 10px 13px -6px var(--v-shadow-key-umbra-opacity, #0003), 0px 20px 31px 3px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 8px 38px 7px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-19 {
  box-shadow: 0px 9px 12px -6px var(--v-shadow-key-umbra-opacity, #0003), 0px 19px 29px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 7px 36px 6px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-18 {
  box-shadow: 0px 9px 11px -5px var(--v-shadow-key-umbra-opacity, #0003), 0px 18px 28px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 7px 34px 6px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-17 {
  box-shadow: 0px 8px 11px -5px var(--v-shadow-key-umbra-opacity, #0003), 0px 17px 26px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 6px 32px 5px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-16 {
  box-shadow: 0px 8px 10px -5px var(--v-shadow-key-umbra-opacity, #0003), 0px 16px 24px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 6px 30px 5px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-15 {
  box-shadow: 0px 8px 9px -5px var(--v-shadow-key-umbra-opacity, #0003), 0px 15px 22px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 6px 28px 5px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-14 {
  box-shadow: 0px 7px 9px -4px var(--v-shadow-key-umbra-opacity, #0003), 0px 14px 21px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 5px 26px 4px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-13 {
  box-shadow: 0px 7px 8px -4px var(--v-shadow-key-umbra-opacity, #0003), 0px 13px 19px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 5px 24px 4px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-12 {
  box-shadow: 0px 7px 8px -4px var(--v-shadow-key-umbra-opacity, #0003), 0px 12px 17px 2px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 5px 22px 4px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-11 {
  box-shadow: 0px 6px 7px -4px var(--v-shadow-key-umbra-opacity, #0003), 0px 11px 15px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 4px 20px 3px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-10 {
  box-shadow: 0px 6px 6px -3px var(--v-shadow-key-umbra-opacity, #0003), 0px 10px 14px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 4px 18px 3px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-9 {
  box-shadow: 0px 5px 6px -3px var(--v-shadow-key-umbra-opacity, #0003), 0px 9px 12px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 3px 16px 2px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-8 {
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, #0003), 0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 3px 14px 2px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-7 {
  box-shadow: 0px 4px 5px -2px var(--v-shadow-key-umbra-opacity, #0003), 0px 7px 10px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 2px 16px 1px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-6 {
  box-shadow: 0px 3px 5px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 6px 10px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 18px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-5 {
  box-shadow: 0px 3px 5px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 5px 8px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 14px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-4 {
  box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 10px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-3 {
  box-shadow: 0px 3px 3px -2px var(--v-shadow-key-umbra-opacity, #0003), 0px 3px 4px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 8px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-2 {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, #0003), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-1 {
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 3px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.elevation-0 {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, #0003), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, #0000001f) !important;
}

.d-sr-only, .d-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.float-none {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.v-locale--is-rtl .float-end {
  float: left !important;
}

.v-locale--is-rtl .float-start, .v-locale--is-ltr .float-end {
  float: right !important;
}

.v-locale--is-ltr .float-start {
  float: left !important;
}

.flex-fill, .flex-1-1 {
  flex: auto !important;
}

.flex-1-0 {
  flex: 1 0 auto !important;
}

.flex-0-1 {
  flex: 0 auto !important;
}

.flex-0-0 {
  flex: none !important;
}

.flex-1-1-100 {
  flex: 100% !important;
}

.flex-1-0-100 {
  flex: 1 0 100% !important;
}

.flex-0-1-100 {
  flex: 0 100% !important;
}

.flex-0-0-100 {
  flex: 0 0 100% !important;
}

.flex-1-1-0 {
  flex: 1 1 0 !important;
}

.flex-1-0-0 {
  flex: 1 0 0 !important;
}

.flex-0-1-0 {
  flex: 0 1 0 !important;
}

.flex-0-0-0 {
  flex: 0 0 0 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

.align-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-space-between {
  align-content: space-between !important;
}

.align-content-space-around {
  align-content: space-around !important;
}

.align-content-space-evenly {
  align-content: space-evenly !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-6 {
  order: 6 !important;
}

.order-7 {
  order: 7 !important;
}

.order-8 {
  order: 8 !important;
}

.order-9 {
  order: 9 !important;
}

.order-10 {
  order: 10 !important;
}

.order-11 {
  order: 11 !important;
}

.order-12 {
  order: 12 !important;
}

.order-last {
  order: 13 !important;
}

.ga-0 {
  gap: 0 !important;
}

.ga-1 {
  gap: 4px !important;
}

.ga-2 {
  gap: 8px !important;
}

.ga-3 {
  gap: 12px !important;
}

.ga-4 {
  gap: 16px !important;
}

.ga-5 {
  gap: 20px !important;
}

.ga-6 {
  gap: 24px !important;
}

.ga-7 {
  gap: 28px !important;
}

.ga-8 {
  gap: 32px !important;
}

.ga-9 {
  gap: 36px !important;
}

.ga-10 {
  gap: 40px !important;
}

.ga-11 {
  gap: 44px !important;
}

.ga-12 {
  gap: 48px !important;
}

.ga-13 {
  gap: 52px !important;
}

.ga-14 {
  gap: 56px !important;
}

.ga-15 {
  gap: 60px !important;
}

.ga-16 {
  gap: 64px !important;
}

.ga-auto {
  gap: auto !important;
}

.gr-0 {
  row-gap: 0 !important;
}

.gr-1 {
  row-gap: 4px !important;
}

.gr-2 {
  row-gap: 8px !important;
}

.gr-3 {
  row-gap: 12px !important;
}

.gr-4 {
  row-gap: 16px !important;
}

.gr-5 {
  row-gap: 20px !important;
}

.gr-6 {
  row-gap: 24px !important;
}

.gr-7 {
  row-gap: 28px !important;
}

.gr-8 {
  row-gap: 32px !important;
}

.gr-9 {
  row-gap: 36px !important;
}

.gr-10 {
  row-gap: 40px !important;
}

.gr-11 {
  row-gap: 44px !important;
}

.gr-12 {
  row-gap: 48px !important;
}

.gr-13 {
  row-gap: 52px !important;
}

.gr-14 {
  row-gap: 56px !important;
}

.gr-15 {
  row-gap: 60px !important;
}

.gr-16 {
  row-gap: 64px !important;
}

.gr-auto {
  row-gap: auto !important;
}

.gc-0 {
  column-gap: 0 !important;
}

.gc-1 {
  column-gap: 4px !important;
}

.gc-2 {
  column-gap: 8px !important;
}

.gc-3 {
  column-gap: 12px !important;
}

.gc-4 {
  column-gap: 16px !important;
}

.gc-5 {
  column-gap: 20px !important;
}

.gc-6 {
  column-gap: 24px !important;
}

.gc-7 {
  column-gap: 28px !important;
}

.gc-8 {
  column-gap: 32px !important;
}

.gc-9 {
  column-gap: 36px !important;
}

.gc-10 {
  column-gap: 40px !important;
}

.gc-11 {
  column-gap: 44px !important;
}

.gc-12 {
  column-gap: 48px !important;
}

.gc-13 {
  column-gap: 52px !important;
}

.gc-14 {
  column-gap: 56px !important;
}

.gc-15 {
  column-gap: 60px !important;
}

.gc-16 {
  column-gap: 64px !important;
}

.gc-auto {
  column-gap: auto !important;
}

.ma-0 {
  margin: 0 !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-6 {
  margin: 24px !important;
}

.ma-7 {
  margin: 28px !important;
}

.ma-8 {
  margin: 32px !important;
}

.ma-9 {
  margin: 36px !important;
}

.ma-10 {
  margin: 40px !important;
}

.ma-11 {
  margin: 44px !important;
}

.ma-12 {
  margin: 48px !important;
}

.ma-13 {
  margin: 52px !important;
}

.ma-14 {
  margin: 56px !important;
}

.ma-15 {
  margin: 60px !important;
}

.ma-16 {
  margin: 64px !important;
}

.ma-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mx-9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.mx-10 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mx-11 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.mx-12 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.mx-13 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.mx-14 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.mx-15 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mx-16 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-13 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.my-14 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.my-15 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my-16 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.mt-13 {
  margin-top: 52px !important;
}

.mt-14 {
  margin-top: 56px !important;
}

.mt-15 {
  margin-top: 60px !important;
}

.mt-16 {
  margin-top: 64px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mr-11 {
  margin-right: 44px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.mr-13 {
  margin-right: 52px !important;
}

.mr-14 {
  margin-right: 56px !important;
}

.mr-15 {
  margin-right: 60px !important;
}

.mr-16 {
  margin-right: 64px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.mb-13 {
  margin-bottom: 52px !important;
}

.mb-14 {
  margin-bottom: 56px !important;
}

.mb-15 {
  margin-bottom: 60px !important;
}

.mb-16 {
  margin-bottom: 64px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.ml-11 {
  margin-left: 44px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.ml-13 {
  margin-left: 52px !important;
}

.ml-14 {
  margin-left: 56px !important;
}

.ml-15 {
  margin-left: 60px !important;
}

.ml-16 {
  margin-left: 64px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ms-0 {
  margin-inline-start: 0 !important;
}

.ms-1 {
  margin-inline-start: 4px !important;
}

.ms-2 {
  margin-inline-start: 8px !important;
}

.ms-3 {
  margin-inline-start: 12px !important;
}

.ms-4 {
  margin-inline-start: 16px !important;
}

.ms-5 {
  margin-inline-start: 20px !important;
}

.ms-6 {
  margin-inline-start: 24px !important;
}

.ms-7 {
  margin-inline-start: 28px !important;
}

.ms-8 {
  margin-inline-start: 32px !important;
}

.ms-9 {
  margin-inline-start: 36px !important;
}

.ms-10 {
  margin-inline-start: 40px !important;
}

.ms-11 {
  margin-inline-start: 44px !important;
}

.ms-12 {
  margin-inline-start: 48px !important;
}

.ms-13 {
  margin-inline-start: 52px !important;
}

.ms-14 {
  margin-inline-start: 56px !important;
}

.ms-15 {
  margin-inline-start: 60px !important;
}

.ms-16 {
  margin-inline-start: 64px !important;
}

.ms-auto {
  margin-inline-start: auto !important;
}

.me-0 {
  margin-inline-end: 0 !important;
}

.me-1 {
  margin-inline-end: 4px !important;
}

.me-2 {
  margin-inline-end: 8px !important;
}

.me-3 {
  margin-inline-end: 12px !important;
}

.me-4 {
  margin-inline-end: 16px !important;
}

.me-5 {
  margin-inline-end: 20px !important;
}

.me-6 {
  margin-inline-end: 24px !important;
}

.me-7 {
  margin-inline-end: 28px !important;
}

.me-8 {
  margin-inline-end: 32px !important;
}

.me-9 {
  margin-inline-end: 36px !important;
}

.me-10 {
  margin-inline-end: 40px !important;
}

.me-11 {
  margin-inline-end: 44px !important;
}

.me-12 {
  margin-inline-end: 48px !important;
}

.me-13 {
  margin-inline-end: 52px !important;
}

.me-14 {
  margin-inline-end: 56px !important;
}

.me-15 {
  margin-inline-end: 60px !important;
}

.me-16 {
  margin-inline-end: 64px !important;
}

.me-auto {
  margin-inline-end: auto !important;
}

.ma-n1 {
  margin: -4px !important;
}

.ma-n2 {
  margin: -8px !important;
}

.ma-n3 {
  margin: -12px !important;
}

.ma-n4 {
  margin: -16px !important;
}

.ma-n5 {
  margin: -20px !important;
}

.ma-n6 {
  margin: -24px !important;
}

.ma-n7 {
  margin: -28px !important;
}

.ma-n8 {
  margin: -32px !important;
}

.ma-n9 {
  margin: -36px !important;
}

.ma-n10 {
  margin: -40px !important;
}

.ma-n11 {
  margin: -44px !important;
}

.ma-n12 {
  margin: -48px !important;
}

.ma-n13 {
  margin: -52px !important;
}

.ma-n14 {
  margin: -56px !important;
}

.ma-n15 {
  margin: -60px !important;
}

.ma-n16 {
  margin: -64px !important;
}

.mx-n1 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.mx-n2 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.mx-n3 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.mx-n4 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.mx-n5 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.mx-n6 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.mx-n7 {
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.mx-n8 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.mx-n9 {
  margin-left: -36px !important;
  margin-right: -36px !important;
}

.mx-n10 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.mx-n11 {
  margin-left: -44px !important;
  margin-right: -44px !important;
}

.mx-n12 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.mx-n13 {
  margin-left: -52px !important;
  margin-right: -52px !important;
}

.mx-n14 {
  margin-left: -56px !important;
  margin-right: -56px !important;
}

.mx-n15 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

.mx-n16 {
  margin-left: -64px !important;
  margin-right: -64px !important;
}

.my-n1 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.my-n2 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.my-n3 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.my-n4 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.my-n5 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.my-n6 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.my-n7 {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}

.my-n8 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.my-n9 {
  margin-top: -36px !important;
  margin-bottom: -36px !important;
}

.my-n10 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.my-n11 {
  margin-top: -44px !important;
  margin-bottom: -44px !important;
}

.my-n12 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.my-n13 {
  margin-top: -52px !important;
  margin-bottom: -52px !important;
}

.my-n14 {
  margin-top: -56px !important;
  margin-bottom: -56px !important;
}

.my-n15 {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}

.my-n16 {
  margin-top: -64px !important;
  margin-bottom: -64px !important;
}

.mt-n1 {
  margin-top: -4px !important;
}

.mt-n2 {
  margin-top: -8px !important;
}

.mt-n3 {
  margin-top: -12px !important;
}

.mt-n4 {
  margin-top: -16px !important;
}

.mt-n5 {
  margin-top: -20px !important;
}

.mt-n6 {
  margin-top: -24px !important;
}

.mt-n7 {
  margin-top: -28px !important;
}

.mt-n8 {
  margin-top: -32px !important;
}

.mt-n9 {
  margin-top: -36px !important;
}

.mt-n10 {
  margin-top: -40px !important;
}

.mt-n11 {
  margin-top: -44px !important;
}

.mt-n12 {
  margin-top: -48px !important;
}

.mt-n13 {
  margin-top: -52px !important;
}

.mt-n14 {
  margin-top: -56px !important;
}

.mt-n15 {
  margin-top: -60px !important;
}

.mt-n16 {
  margin-top: -64px !important;
}

.mr-n1 {
  margin-right: -4px !important;
}

.mr-n2 {
  margin-right: -8px !important;
}

.mr-n3 {
  margin-right: -12px !important;
}

.mr-n4 {
  margin-right: -16px !important;
}

.mr-n5 {
  margin-right: -20px !important;
}

.mr-n6 {
  margin-right: -24px !important;
}

.mr-n7 {
  margin-right: -28px !important;
}

.mr-n8 {
  margin-right: -32px !important;
}

.mr-n9 {
  margin-right: -36px !important;
}

.mr-n10 {
  margin-right: -40px !important;
}

.mr-n11 {
  margin-right: -44px !important;
}

.mr-n12 {
  margin-right: -48px !important;
}

.mr-n13 {
  margin-right: -52px !important;
}

.mr-n14 {
  margin-right: -56px !important;
}

.mr-n15 {
  margin-right: -60px !important;
}

.mr-n16 {
  margin-right: -64px !important;
}

.mb-n1 {
  margin-bottom: -4px !important;
}

.mb-n2 {
  margin-bottom: -8px !important;
}

.mb-n3 {
  margin-bottom: -12px !important;
}

.mb-n4 {
  margin-bottom: -16px !important;
}

.mb-n5 {
  margin-bottom: -20px !important;
}

.mb-n6 {
  margin-bottom: -24px !important;
}

.mb-n7 {
  margin-bottom: -28px !important;
}

.mb-n8 {
  margin-bottom: -32px !important;
}

.mb-n9 {
  margin-bottom: -36px !important;
}

.mb-n10 {
  margin-bottom: -40px !important;
}

.mb-n11 {
  margin-bottom: -44px !important;
}

.mb-n12 {
  margin-bottom: -48px !important;
}

.mb-n13 {
  margin-bottom: -52px !important;
}

.mb-n14 {
  margin-bottom: -56px !important;
}

.mb-n15 {
  margin-bottom: -60px !important;
}

.mb-n16 {
  margin-bottom: -64px !important;
}

.ml-n1 {
  margin-left: -4px !important;
}

.ml-n2 {
  margin-left: -8px !important;
}

.ml-n3 {
  margin-left: -12px !important;
}

.ml-n4 {
  margin-left: -16px !important;
}

.ml-n5 {
  margin-left: -20px !important;
}

.ml-n6 {
  margin-left: -24px !important;
}

.ml-n7 {
  margin-left: -28px !important;
}

.ml-n8 {
  margin-left: -32px !important;
}

.ml-n9 {
  margin-left: -36px !important;
}

.ml-n10 {
  margin-left: -40px !important;
}

.ml-n11 {
  margin-left: -44px !important;
}

.ml-n12 {
  margin-left: -48px !important;
}

.ml-n13 {
  margin-left: -52px !important;
}

.ml-n14 {
  margin-left: -56px !important;
}

.ml-n15 {
  margin-left: -60px !important;
}

.ml-n16 {
  margin-left: -64px !important;
}

.ms-n1 {
  margin-inline-start: -4px !important;
}

.ms-n2 {
  margin-inline-start: -8px !important;
}

.ms-n3 {
  margin-inline-start: -12px !important;
}

.ms-n4 {
  margin-inline-start: -16px !important;
}

.ms-n5 {
  margin-inline-start: -20px !important;
}

.ms-n6 {
  margin-inline-start: -24px !important;
}

.ms-n7 {
  margin-inline-start: -28px !important;
}

.ms-n8 {
  margin-inline-start: -32px !important;
}

.ms-n9 {
  margin-inline-start: -36px !important;
}

.ms-n10 {
  margin-inline-start: -40px !important;
}

.ms-n11 {
  margin-inline-start: -44px !important;
}

.ms-n12 {
  margin-inline-start: -48px !important;
}

.ms-n13 {
  margin-inline-start: -52px !important;
}

.ms-n14 {
  margin-inline-start: -56px !important;
}

.ms-n15 {
  margin-inline-start: -60px !important;
}

.ms-n16 {
  margin-inline-start: -64px !important;
}

.me-n1 {
  margin-inline-end: -4px !important;
}

.me-n2 {
  margin-inline-end: -8px !important;
}

.me-n3 {
  margin-inline-end: -12px !important;
}

.me-n4 {
  margin-inline-end: -16px !important;
}

.me-n5 {
  margin-inline-end: -20px !important;
}

.me-n6 {
  margin-inline-end: -24px !important;
}

.me-n7 {
  margin-inline-end: -28px !important;
}

.me-n8 {
  margin-inline-end: -32px !important;
}

.me-n9 {
  margin-inline-end: -36px !important;
}

.me-n10 {
  margin-inline-end: -40px !important;
}

.me-n11 {
  margin-inline-end: -44px !important;
}

.me-n12 {
  margin-inline-end: -48px !important;
}

.me-n13 {
  margin-inline-end: -52px !important;
}

.me-n14 {
  margin-inline-end: -56px !important;
}

.me-n15 {
  margin-inline-end: -60px !important;
}

.me-n16 {
  margin-inline-end: -64px !important;
}

.pa-0 {
  padding: 0 !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.pa-6 {
  padding: 24px !important;
}

.pa-7 {
  padding: 28px !important;
}

.pa-8 {
  padding: 32px !important;
}

.pa-9 {
  padding: 36px !important;
}

.pa-10 {
  padding: 40px !important;
}

.pa-11 {
  padding: 44px !important;
}

.pa-12 {
  padding: 48px !important;
}

.pa-13 {
  padding: 52px !important;
}

.pa-14 {
  padding: 56px !important;
}

.pa-15 {
  padding: 60px !important;
}

.pa-16 {
  padding: 64px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.px-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.px-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.px-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.pt-11 {
  padding-top: 44px !important;
}

.pt-12 {
  padding-top: 48px !important;
}

.pt-13 {
  padding-top: 52px !important;
}

.pt-14 {
  padding-top: 56px !important;
}

.pt-15 {
  padding-top: 60px !important;
}

.pt-16 {
  padding-top: 64px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.pr-11 {
  padding-right: 44px !important;
}

.pr-12 {
  padding-right: 48px !important;
}

.pr-13 {
  padding-right: 52px !important;
}

.pr-14 {
  padding-right: 56px !important;
}

.pr-15 {
  padding-right: 60px !important;
}

.pr-16 {
  padding-right: 64px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.pb-11 {
  padding-bottom: 44px !important;
}

.pb-12 {
  padding-bottom: 48px !important;
}

.pb-13 {
  padding-bottom: 52px !important;
}

.pb-14 {
  padding-bottom: 56px !important;
}

.pb-15 {
  padding-bottom: 60px !important;
}

.pb-16 {
  padding-bottom: 64px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.pl-11 {
  padding-left: 44px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.pl-13 {
  padding-left: 52px !important;
}

.pl-14 {
  padding-left: 56px !important;
}

.pl-15 {
  padding-left: 60px !important;
}

.pl-16 {
  padding-left: 64px !important;
}

.ps-0 {
  padding-inline-start: 0 !important;
}

.ps-1 {
  padding-inline-start: 4px !important;
}

.ps-2 {
  padding-inline-start: 8px !important;
}

.ps-3 {
  padding-inline-start: 12px !important;
}

.ps-4 {
  padding-inline-start: 16px !important;
}

.ps-5 {
  padding-inline-start: 20px !important;
}

.ps-6 {
  padding-inline-start: 24px !important;
}

.ps-7 {
  padding-inline-start: 28px !important;
}

.ps-8 {
  padding-inline-start: 32px !important;
}

.ps-9 {
  padding-inline-start: 36px !important;
}

.ps-10 {
  padding-inline-start: 40px !important;
}

.ps-11 {
  padding-inline-start: 44px !important;
}

.ps-12 {
  padding-inline-start: 48px !important;
}

.ps-13 {
  padding-inline-start: 52px !important;
}

.ps-14 {
  padding-inline-start: 56px !important;
}

.ps-15 {
  padding-inline-start: 60px !important;
}

.ps-16 {
  padding-inline-start: 64px !important;
}

.pe-0 {
  padding-inline-end: 0 !important;
}

.pe-1 {
  padding-inline-end: 4px !important;
}

.pe-2 {
  padding-inline-end: 8px !important;
}

.pe-3 {
  padding-inline-end: 12px !important;
}

.pe-4 {
  padding-inline-end: 16px !important;
}

.pe-5 {
  padding-inline-end: 20px !important;
}

.pe-6 {
  padding-inline-end: 24px !important;
}

.pe-7 {
  padding-inline-end: 28px !important;
}

.pe-8 {
  padding-inline-end: 32px !important;
}

.pe-9 {
  padding-inline-end: 36px !important;
}

.pe-10 {
  padding-inline-end: 40px !important;
}

.pe-11 {
  padding-inline-end: 44px !important;
}

.pe-12 {
  padding-inline-end: 48px !important;
}

.pe-13 {
  padding-inline-end: 52px !important;
}

.pe-14 {
  padding-inline-end: 56px !important;
}

.pe-15 {
  padding-inline-end: 60px !important;
}

.pe-16 {
  padding-inline-end: 64px !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-xl {
  border-radius: 24px !important;
}

.rounded-pill {
  border-radius: 9999px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-shaped {
  border-radius: 24px 0 !important;
}

.rounded-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-t-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.rounded-t {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-t-lg {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.rounded-t-xl {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.rounded-t-pill {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.rounded-t-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-t-shaped {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 0 !important;
}

.v-locale--is-ltr .rounded-e-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-rtl .rounded-e-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.v-locale--is-ltr .rounded-e-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.v-locale--is-rtl .rounded-e-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.v-locale--is-ltr .rounded-e {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.v-locale--is-rtl .rounded-e {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.v-locale--is-ltr .rounded-e-lg {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.v-locale--is-rtl .rounded-e-lg {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.v-locale--is-ltr .rounded-e-xl {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.v-locale--is-rtl .rounded-e-xl {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.v-locale--is-ltr .rounded-e-pill {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-e-pill {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-e-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.v-locale--is-rtl .rounded-e-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.v-locale--is-ltr .rounded-e-shaped {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-rtl .rounded-e-shaped {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-b-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-b-sm {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rounded-b {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-b-lg {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.rounded-b-xl {
  border-bottom-right-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.rounded-b-pill {
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-b-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-b-shaped {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 24px !important;
}

.v-locale--is-ltr .rounded-s-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.v-locale--is-rtl .rounded-s-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-ltr .rounded-s-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.v-locale--is-rtl .rounded-s-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.v-locale--is-ltr .rounded-s {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.v-locale--is-rtl .rounded-s {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.v-locale--is-ltr .rounded-s-lg {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.v-locale--is-rtl .rounded-s-lg {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.v-locale--is-ltr .rounded-s-xl {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.v-locale--is-rtl .rounded-s-xl {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.v-locale--is-ltr .rounded-s-pill {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-s-pill {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-s-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.v-locale--is-rtl .rounded-s-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.v-locale--is-ltr .rounded-s-shaped {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 0 !important;
}

.v-locale--is-rtl .rounded-s-shaped {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-ltr .rounded-ts-0 {
  border-top-left-radius: 0 !important;
}

.v-locale--is-rtl .rounded-ts-0 {
  border-top-right-radius: 0 !important;
}

.v-locale--is-ltr .rounded-ts-sm {
  border-top-left-radius: 2px !important;
}

.v-locale--is-rtl .rounded-ts-sm {
  border-top-right-radius: 2px !important;
}

.v-locale--is-ltr .rounded-ts {
  border-top-left-radius: 4px !important;
}

.v-locale--is-rtl .rounded-ts {
  border-top-right-radius: 4px !important;
}

.v-locale--is-ltr .rounded-ts-lg {
  border-top-left-radius: 8px !important;
}

.v-locale--is-rtl .rounded-ts-lg {
  border-top-right-radius: 8px !important;
}

.v-locale--is-ltr .rounded-ts-xl {
  border-top-left-radius: 24px !important;
}

.v-locale--is-rtl .rounded-ts-xl {
  border-top-right-radius: 24px !important;
}

.v-locale--is-ltr .rounded-ts-pill {
  border-top-left-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-ts-pill {
  border-top-right-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-ts-circle {
  border-top-left-radius: 50% !important;
}

.v-locale--is-rtl .rounded-ts-circle {
  border-top-right-radius: 50% !important;
}

.v-locale--is-ltr .rounded-ts-shaped {
  border-top-left-radius: 24px 0 !important;
}

.v-locale--is-rtl .rounded-ts-shaped {
  border-top-right-radius: 24px 0 !important;
}

.v-locale--is-ltr .rounded-te-0 {
  border-top-right-radius: 0 !important;
}

.v-locale--is-rtl .rounded-te-0 {
  border-top-left-radius: 0 !important;
}

.v-locale--is-ltr .rounded-te-sm {
  border-top-right-radius: 2px !important;
}

.v-locale--is-rtl .rounded-te-sm {
  border-top-left-radius: 2px !important;
}

.v-locale--is-ltr .rounded-te {
  border-top-right-radius: 4px !important;
}

.v-locale--is-rtl .rounded-te {
  border-top-left-radius: 4px !important;
}

.v-locale--is-ltr .rounded-te-lg {
  border-top-right-radius: 8px !important;
}

.v-locale--is-rtl .rounded-te-lg {
  border-top-left-radius: 8px !important;
}

.v-locale--is-ltr .rounded-te-xl {
  border-top-right-radius: 24px !important;
}

.v-locale--is-rtl .rounded-te-xl {
  border-top-left-radius: 24px !important;
}

.v-locale--is-ltr .rounded-te-pill {
  border-top-right-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-te-pill {
  border-top-left-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-te-circle {
  border-top-right-radius: 50% !important;
}

.v-locale--is-rtl .rounded-te-circle {
  border-top-left-radius: 50% !important;
}

.v-locale--is-ltr .rounded-te-shaped {
  border-top-right-radius: 24px 0 !important;
}

.v-locale--is-rtl .rounded-te-shaped {
  border-top-left-radius: 24px 0 !important;
}

.v-locale--is-ltr .rounded-be-0 {
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-rtl .rounded-be-0 {
  border-bottom-left-radius: 0 !important;
}

.v-locale--is-ltr .rounded-be-sm {
  border-bottom-right-radius: 2px !important;
}

.v-locale--is-rtl .rounded-be-sm {
  border-bottom-left-radius: 2px !important;
}

.v-locale--is-ltr .rounded-be {
  border-bottom-right-radius: 4px !important;
}

.v-locale--is-rtl .rounded-be {
  border-bottom-left-radius: 4px !important;
}

.v-locale--is-ltr .rounded-be-lg {
  border-bottom-right-radius: 8px !important;
}

.v-locale--is-rtl .rounded-be-lg {
  border-bottom-left-radius: 8px !important;
}

.v-locale--is-ltr .rounded-be-xl {
  border-bottom-right-radius: 24px !important;
}

.v-locale--is-rtl .rounded-be-xl {
  border-bottom-left-radius: 24px !important;
}

.v-locale--is-ltr .rounded-be-pill {
  border-bottom-right-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-be-pill {
  border-bottom-left-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-be-circle {
  border-bottom-right-radius: 50% !important;
}

.v-locale--is-rtl .rounded-be-circle {
  border-bottom-left-radius: 50% !important;
}

.v-locale--is-ltr .rounded-be-shaped {
  border-bottom-right-radius: 24px 0 !important;
}

.v-locale--is-rtl .rounded-be-shaped {
  border-bottom-left-radius: 24px 0 !important;
}

.v-locale--is-ltr .rounded-bs-0 {
  border-bottom-left-radius: 0 !important;
}

.v-locale--is-rtl .rounded-bs-0 {
  border-bottom-right-radius: 0 !important;
}

.v-locale--is-ltr .rounded-bs-sm {
  border-bottom-left-radius: 2px !important;
}

.v-locale--is-rtl .rounded-bs-sm {
  border-bottom-right-radius: 2px !important;
}

.v-locale--is-ltr .rounded-bs {
  border-bottom-left-radius: 4px !important;
}

.v-locale--is-rtl .rounded-bs {
  border-bottom-right-radius: 4px !important;
}

.v-locale--is-ltr .rounded-bs-lg {
  border-bottom-left-radius: 8px !important;
}

.v-locale--is-rtl .rounded-bs-lg {
  border-bottom-right-radius: 8px !important;
}

.v-locale--is-ltr .rounded-bs-xl {
  border-bottom-left-radius: 24px !important;
}

.v-locale--is-rtl .rounded-bs-xl {
  border-bottom-right-radius: 24px !important;
}

.v-locale--is-ltr .rounded-bs-pill {
  border-bottom-left-radius: 9999px !important;
}

.v-locale--is-rtl .rounded-bs-pill {
  border-bottom-right-radius: 9999px !important;
}

.v-locale--is-ltr .rounded-bs-circle {
  border-bottom-left-radius: 50% !important;
}

.v-locale--is-rtl .rounded-bs-circle {
  border-bottom-right-radius: 50% !important;
}

.v-locale--is-ltr .rounded-bs-shaped {
  border-bottom-left-radius: 24px 0 !important;
}

.v-locale--is-rtl .rounded-bs-shaped {
  border-bottom-right-radius: 24px 0 !important;
}

.border-0 {
  border-style: solid !important;
  border-width: 0 !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border, .border-thin {
  border-style: solid !important;
  border-width: thin !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-sm {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-md {
  border-style: solid !important;
  border-width: 2px !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-lg {
  border-style: solid !important;
  border-width: 4px !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-xl {
  border-style: solid !important;
  border-width: 8px !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-opacity-0 {
  --v-border-opacity: 0 !important;
}

.border-opacity {
  --v-border-opacity: .12 !important;
}

.border-opacity-25 {
  --v-border-opacity: .25 !important;
}

.border-opacity-50 {
  --v-border-opacity: .5 !important;
}

.border-opacity-75 {
  --v-border-opacity: .75 !important;
}

.border-opacity-100 {
  --v-border-opacity: 1 !important;
}

.border-t-0 {
  border-block-start-style: solid !important;
  border-block-start-width: 0 !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-t, .border-t-thin {
  border-block-start-style: solid !important;
  border-block-start-width: thin !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-t-sm {
  border-block-start-style: solid !important;
  border-block-start-width: 1px !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-t-md {
  border-block-start-style: solid !important;
  border-block-start-width: 2px !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-t-lg {
  border-block-start-style: solid !important;
  border-block-start-width: 4px !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-t-xl {
  border-block-start-style: solid !important;
  border-block-start-width: 8px !important;
  border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e-0 {
  border-inline-end-style: solid !important;
  border-inline-end-width: 0 !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e, .border-e-thin {
  border-inline-end-style: solid !important;
  border-inline-end-width: thin !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e-sm {
  border-inline-end-style: solid !important;
  border-inline-end-width: 1px !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e-md {
  border-inline-end-style: solid !important;
  border-inline-end-width: 2px !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e-lg {
  border-inline-end-style: solid !important;
  border-inline-end-width: 4px !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-e-xl {
  border-inline-end-style: solid !important;
  border-inline-end-width: 8px !important;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b-0 {
  border-block-end-style: solid !important;
  border-block-end-width: 0 !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b, .border-b-thin {
  border-block-end-style: solid !important;
  border-block-end-width: thin !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b-sm {
  border-block-end-style: solid !important;
  border-block-end-width: 1px !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b-md {
  border-block-end-style: solid !important;
  border-block-end-width: 2px !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b-lg {
  border-block-end-style: solid !important;
  border-block-end-width: 4px !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-b-xl {
  border-block-end-style: solid !important;
  border-block-end-width: 8px !important;
  border-block-end-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s-0 {
  border-inline-start-style: solid !important;
  border-inline-start-width: 0 !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s, .border-s-thin {
  border-inline-start-style: solid !important;
  border-inline-start-width: thin !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s-sm {
  border-inline-start-style: solid !important;
  border-inline-start-width: 1px !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s-md {
  border-inline-start-style: solid !important;
  border-inline-start-width: 2px !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s-lg {
  border-inline-start-style: solid !important;
  border-inline-start-width: 4px !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-s-xl {
  border-inline-start-style: solid !important;
  border-inline-start-width: 8px !important;
  border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-none {
  border-style: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-overline {
  text-decoration: overline !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.text-pre {
  white-space: pre !important;
}

.text-pre-line {
  white-space: pre-line !important;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.opacity-hover {
  opacity: var(--v-hover-opacity) !important;
}

.opacity-focus {
  opacity: var(--v-focus-opacity) !important;
}

.opacity-selected {
  opacity: var(--v-selected-opacity) !important;
}

.opacity-activated {
  opacity: var(--v-activated-opacity) !important;
}

.opacity-pressed {
  opacity: var(--v-pressed-opacity) !important;
}

.opacity-dragged {
  opacity: var(--v-dragged-opacity) !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.text-high-emphasis {
  color: rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity)) !important;
}

.text-medium-emphasis {
  color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity)) !important;
}

.text-disabled {
  color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity)) !important;
}

.text-truncate {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.text-h1 {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.015625em !important;
  text-transform: none !important;
  font-size: 6rem !important;
}

.text-h2 {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.00833333em !important;
  text-transform: none !important;
  font-size: 3.75rem !important;
}

.text-h3 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.05;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-size: 3rem !important;
}

.text-h4 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.175;
  letter-spacing: .00735294em !important;
  text-transform: none !important;
  font-size: 2.125rem !important;
}

.text-h5 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.333;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-size: 1.5rem !important;
}

.text-h6 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: .0125em !important;
  text-transform: none !important;
  font-size: 1.25rem !important;
}

.text-subtitle-1 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: .009375em !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.text-subtitle-2 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: .00714286em !important;
  text-transform: none !important;
  font-size: .875rem !important;
}

.text-body-1 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .03125em !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.text-body-2 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.425;
  letter-spacing: .0178571em !important;
  text-transform: none !important;
  font-size: .875rem !important;
}

.text-button {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 2.6;
  letter-spacing: .0892857em !important;
  text-transform: uppercase !important;
  font-size: .875rem !important;
}

.text-caption {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.667;
  letter-spacing: .0333333em !important;
  text-transform: none !important;
  font-size: .75rem !important;
}

.text-overline {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 2.667;
  letter-spacing: .166667em !important;
  text-transform: uppercase !important;
  font-size: .75rem !important;
}

.text-none {
  text-transform: none !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-mono {
  font-family: monospace !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-progress {
  cursor: progress !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.cursor-none {
  cursor: none !important;
}

.fill-height {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-screen {
  height: 100dvh !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-50 {
  width: 50% !important;
}

.w-66 {
  width: 66% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

@media (width >= 600px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-sm-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-sm-start, .v-locale--is-ltr .float-sm-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-sm-start {
    float: left !important;
  }

  .flex-sm-fill, .flex-sm-1-1 {
    flex: auto !important;
  }

  .flex-sm-1-0 {
    flex: 1 0 auto !important;
  }

  .flex-sm-0-1 {
    flex: 0 auto !important;
  }

  .flex-sm-0-0 {
    flex: none !important;
  }

  .flex-sm-1-1-100 {
    flex: 100% !important;
  }

  .flex-sm-1-0-100 {
    flex: 1 0 100% !important;
  }

  .flex-sm-0-1-100 {
    flex: 0 100% !important;
  }

  .flex-sm-0-0-100 {
    flex: 0 0 100% !important;
  }

  .flex-sm-1-1-0 {
    flex: 1 1 0 !important;
  }

  .flex-sm-1-0-0 {
    flex: 1 0 0 !important;
  }

  .flex-sm-0-1-0 {
    flex: 0 1 0 !important;
  }

  .flex-sm-0-0-0 {
    flex: 0 0 0 !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-sm-start {
    justify-content: flex-start !important;
  }

  .justify-sm-end {
    justify-content: flex-end !important;
  }

  .justify-sm-center {
    justify-content: center !important;
  }

  .justify-sm-space-between {
    justify-content: space-between !important;
  }

  .justify-sm-space-around {
    justify-content: space-around !important;
  }

  .justify-sm-space-evenly {
    justify-content: space-evenly !important;
  }

  .align-sm-start {
    align-items: flex-start !important;
  }

  .align-sm-end {
    align-items: flex-end !important;
  }

  .align-sm-center {
    align-items: center !important;
  }

  .align-sm-baseline {
    align-items: baseline !important;
  }

  .align-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-space-between {
    align-content: space-between !important;
  }

  .align-content-sm-space-around {
    align-content: space-around !important;
  }

  .align-content-sm-space-evenly {
    align-content: space-evenly !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-6 {
    order: 6 !important;
  }

  .order-sm-7 {
    order: 7 !important;
  }

  .order-sm-8 {
    order: 8 !important;
  }

  .order-sm-9 {
    order: 9 !important;
  }

  .order-sm-10 {
    order: 10 !important;
  }

  .order-sm-11 {
    order: 11 !important;
  }

  .order-sm-12 {
    order: 12 !important;
  }

  .order-sm-last {
    order: 13 !important;
  }

  .ga-sm-0 {
    gap: 0 !important;
  }

  .ga-sm-1 {
    gap: 4px !important;
  }

  .ga-sm-2 {
    gap: 8px !important;
  }

  .ga-sm-3 {
    gap: 12px !important;
  }

  .ga-sm-4 {
    gap: 16px !important;
  }

  .ga-sm-5 {
    gap: 20px !important;
  }

  .ga-sm-6 {
    gap: 24px !important;
  }

  .ga-sm-7 {
    gap: 28px !important;
  }

  .ga-sm-8 {
    gap: 32px !important;
  }

  .ga-sm-9 {
    gap: 36px !important;
  }

  .ga-sm-10 {
    gap: 40px !important;
  }

  .ga-sm-11 {
    gap: 44px !important;
  }

  .ga-sm-12 {
    gap: 48px !important;
  }

  .ga-sm-13 {
    gap: 52px !important;
  }

  .ga-sm-14 {
    gap: 56px !important;
  }

  .ga-sm-15 {
    gap: 60px !important;
  }

  .ga-sm-16 {
    gap: 64px !important;
  }

  .ga-sm-auto {
    gap: auto !important;
  }

  .gr-sm-0 {
    row-gap: 0 !important;
  }

  .gr-sm-1 {
    row-gap: 4px !important;
  }

  .gr-sm-2 {
    row-gap: 8px !important;
  }

  .gr-sm-3 {
    row-gap: 12px !important;
  }

  .gr-sm-4 {
    row-gap: 16px !important;
  }

  .gr-sm-5 {
    row-gap: 20px !important;
  }

  .gr-sm-6 {
    row-gap: 24px !important;
  }

  .gr-sm-7 {
    row-gap: 28px !important;
  }

  .gr-sm-8 {
    row-gap: 32px !important;
  }

  .gr-sm-9 {
    row-gap: 36px !important;
  }

  .gr-sm-10 {
    row-gap: 40px !important;
  }

  .gr-sm-11 {
    row-gap: 44px !important;
  }

  .gr-sm-12 {
    row-gap: 48px !important;
  }

  .gr-sm-13 {
    row-gap: 52px !important;
  }

  .gr-sm-14 {
    row-gap: 56px !important;
  }

  .gr-sm-15 {
    row-gap: 60px !important;
  }

  .gr-sm-16 {
    row-gap: 64px !important;
  }

  .gr-sm-auto {
    row-gap: auto !important;
  }

  .gc-sm-0 {
    column-gap: 0 !important;
  }

  .gc-sm-1 {
    column-gap: 4px !important;
  }

  .gc-sm-2 {
    column-gap: 8px !important;
  }

  .gc-sm-3 {
    column-gap: 12px !important;
  }

  .gc-sm-4 {
    column-gap: 16px !important;
  }

  .gc-sm-5 {
    column-gap: 20px !important;
  }

  .gc-sm-6 {
    column-gap: 24px !important;
  }

  .gc-sm-7 {
    column-gap: 28px !important;
  }

  .gc-sm-8 {
    column-gap: 32px !important;
  }

  .gc-sm-9 {
    column-gap: 36px !important;
  }

  .gc-sm-10 {
    column-gap: 40px !important;
  }

  .gc-sm-11 {
    column-gap: 44px !important;
  }

  .gc-sm-12 {
    column-gap: 48px !important;
  }

  .gc-sm-13 {
    column-gap: 52px !important;
  }

  .gc-sm-14 {
    column-gap: 56px !important;
  }

  .gc-sm-15 {
    column-gap: 60px !important;
  }

  .gc-sm-16 {
    column-gap: 64px !important;
  }

  .gc-sm-auto {
    column-gap: auto !important;
  }

  .ma-sm-0 {
    margin: 0 !important;
  }

  .ma-sm-1 {
    margin: 4px !important;
  }

  .ma-sm-2 {
    margin: 8px !important;
  }

  .ma-sm-3 {
    margin: 12px !important;
  }

  .ma-sm-4 {
    margin: 16px !important;
  }

  .ma-sm-5 {
    margin: 20px !important;
  }

  .ma-sm-6 {
    margin: 24px !important;
  }

  .ma-sm-7 {
    margin: 28px !important;
  }

  .ma-sm-8 {
    margin: 32px !important;
  }

  .ma-sm-9 {
    margin: 36px !important;
  }

  .ma-sm-10 {
    margin: 40px !important;
  }

  .ma-sm-11 {
    margin: 44px !important;
  }

  .ma-sm-12 {
    margin: 48px !important;
  }

  .ma-sm-13 {
    margin: 52px !important;
  }

  .ma-sm-14 {
    margin: 56px !important;
  }

  .ma-sm-15 {
    margin: 60px !important;
  }

  .ma-sm-16 {
    margin: 64px !important;
  }

  .ma-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-sm-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-sm-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-sm-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-sm-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-sm-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-sm-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .mx-sm-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-sm-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .mx-sm-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-sm-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .mx-sm-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .mx-sm-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .mx-sm-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .mx-sm-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-sm-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-sm-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-sm-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-sm-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-sm-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-sm-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .my-sm-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-sm-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-sm-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-sm-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .my-sm-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-sm-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .my-sm-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .my-sm-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-sm-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 4px !important;
  }

  .mt-sm-2 {
    margin-top: 8px !important;
  }

  .mt-sm-3 {
    margin-top: 12px !important;
  }

  .mt-sm-4 {
    margin-top: 16px !important;
  }

  .mt-sm-5 {
    margin-top: 20px !important;
  }

  .mt-sm-6 {
    margin-top: 24px !important;
  }

  .mt-sm-7 {
    margin-top: 28px !important;
  }

  .mt-sm-8 {
    margin-top: 32px !important;
  }

  .mt-sm-9 {
    margin-top: 36px !important;
  }

  .mt-sm-10 {
    margin-top: 40px !important;
  }

  .mt-sm-11 {
    margin-top: 44px !important;
  }

  .mt-sm-12 {
    margin-top: 48px !important;
  }

  .mt-sm-13 {
    margin-top: 52px !important;
  }

  .mt-sm-14 {
    margin-top: 56px !important;
  }

  .mt-sm-15 {
    margin-top: 60px !important;
  }

  .mt-sm-16 {
    margin-top: 64px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: 4px !important;
  }

  .mr-sm-2 {
    margin-right: 8px !important;
  }

  .mr-sm-3 {
    margin-right: 12px !important;
  }

  .mr-sm-4 {
    margin-right: 16px !important;
  }

  .mr-sm-5 {
    margin-right: 20px !important;
  }

  .mr-sm-6 {
    margin-right: 24px !important;
  }

  .mr-sm-7 {
    margin-right: 28px !important;
  }

  .mr-sm-8 {
    margin-right: 32px !important;
  }

  .mr-sm-9 {
    margin-right: 36px !important;
  }

  .mr-sm-10 {
    margin-right: 40px !important;
  }

  .mr-sm-11 {
    margin-right: 44px !important;
  }

  .mr-sm-12 {
    margin-right: 48px !important;
  }

  .mr-sm-13 {
    margin-right: 52px !important;
  }

  .mr-sm-14 {
    margin-right: 56px !important;
  }

  .mr-sm-15 {
    margin-right: 60px !important;
  }

  .mr-sm-16 {
    margin-right: 64px !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 4px !important;
  }

  .mb-sm-2 {
    margin-bottom: 8px !important;
  }

  .mb-sm-3 {
    margin-bottom: 12px !important;
  }

  .mb-sm-4 {
    margin-bottom: 16px !important;
  }

  .mb-sm-5 {
    margin-bottom: 20px !important;
  }

  .mb-sm-6 {
    margin-bottom: 24px !important;
  }

  .mb-sm-7 {
    margin-bottom: 28px !important;
  }

  .mb-sm-8 {
    margin-bottom: 32px !important;
  }

  .mb-sm-9 {
    margin-bottom: 36px !important;
  }

  .mb-sm-10 {
    margin-bottom: 40px !important;
  }

  .mb-sm-11 {
    margin-bottom: 44px !important;
  }

  .mb-sm-12 {
    margin-bottom: 48px !important;
  }

  .mb-sm-13 {
    margin-bottom: 52px !important;
  }

  .mb-sm-14 {
    margin-bottom: 56px !important;
  }

  .mb-sm-15 {
    margin-bottom: 60px !important;
  }

  .mb-sm-16 {
    margin-bottom: 64px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: 4px !important;
  }

  .ml-sm-2 {
    margin-left: 8px !important;
  }

  .ml-sm-3 {
    margin-left: 12px !important;
  }

  .ml-sm-4 {
    margin-left: 16px !important;
  }

  .ml-sm-5 {
    margin-left: 20px !important;
  }

  .ml-sm-6 {
    margin-left: 24px !important;
  }

  .ml-sm-7 {
    margin-left: 28px !important;
  }

  .ml-sm-8 {
    margin-left: 32px !important;
  }

  .ml-sm-9 {
    margin-left: 36px !important;
  }

  .ml-sm-10 {
    margin-left: 40px !important;
  }

  .ml-sm-11 {
    margin-left: 44px !important;
  }

  .ml-sm-12 {
    margin-left: 48px !important;
  }

  .ml-sm-13 {
    margin-left: 52px !important;
  }

  .ml-sm-14 {
    margin-left: 56px !important;
  }

  .ml-sm-15 {
    margin-left: 60px !important;
  }

  .ml-sm-16 {
    margin-left: 64px !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .ms-sm-0 {
    margin-inline-start: 0 !important;
  }

  .ms-sm-1 {
    margin-inline-start: 4px !important;
  }

  .ms-sm-2 {
    margin-inline-start: 8px !important;
  }

  .ms-sm-3 {
    margin-inline-start: 12px !important;
  }

  .ms-sm-4 {
    margin-inline-start: 16px !important;
  }

  .ms-sm-5 {
    margin-inline-start: 20px !important;
  }

  .ms-sm-6 {
    margin-inline-start: 24px !important;
  }

  .ms-sm-7 {
    margin-inline-start: 28px !important;
  }

  .ms-sm-8 {
    margin-inline-start: 32px !important;
  }

  .ms-sm-9 {
    margin-inline-start: 36px !important;
  }

  .ms-sm-10 {
    margin-inline-start: 40px !important;
  }

  .ms-sm-11 {
    margin-inline-start: 44px !important;
  }

  .ms-sm-12 {
    margin-inline-start: 48px !important;
  }

  .ms-sm-13 {
    margin-inline-start: 52px !important;
  }

  .ms-sm-14 {
    margin-inline-start: 56px !important;
  }

  .ms-sm-15 {
    margin-inline-start: 60px !important;
  }

  .ms-sm-16 {
    margin-inline-start: 64px !important;
  }

  .ms-sm-auto {
    margin-inline-start: auto !important;
  }

  .me-sm-0 {
    margin-inline-end: 0 !important;
  }

  .me-sm-1 {
    margin-inline-end: 4px !important;
  }

  .me-sm-2 {
    margin-inline-end: 8px !important;
  }

  .me-sm-3 {
    margin-inline-end: 12px !important;
  }

  .me-sm-4 {
    margin-inline-end: 16px !important;
  }

  .me-sm-5 {
    margin-inline-end: 20px !important;
  }

  .me-sm-6 {
    margin-inline-end: 24px !important;
  }

  .me-sm-7 {
    margin-inline-end: 28px !important;
  }

  .me-sm-8 {
    margin-inline-end: 32px !important;
  }

  .me-sm-9 {
    margin-inline-end: 36px !important;
  }

  .me-sm-10 {
    margin-inline-end: 40px !important;
  }

  .me-sm-11 {
    margin-inline-end: 44px !important;
  }

  .me-sm-12 {
    margin-inline-end: 48px !important;
  }

  .me-sm-13 {
    margin-inline-end: 52px !important;
  }

  .me-sm-14 {
    margin-inline-end: 56px !important;
  }

  .me-sm-15 {
    margin-inline-end: 60px !important;
  }

  .me-sm-16 {
    margin-inline-end: 64px !important;
  }

  .me-sm-auto {
    margin-inline-end: auto !important;
  }

  .ma-sm-n1 {
    margin: -4px !important;
  }

  .ma-sm-n2 {
    margin: -8px !important;
  }

  .ma-sm-n3 {
    margin: -12px !important;
  }

  .ma-sm-n4 {
    margin: -16px !important;
  }

  .ma-sm-n5 {
    margin: -20px !important;
  }

  .ma-sm-n6 {
    margin: -24px !important;
  }

  .ma-sm-n7 {
    margin: -28px !important;
  }

  .ma-sm-n8 {
    margin: -32px !important;
  }

  .ma-sm-n9 {
    margin: -36px !important;
  }

  .ma-sm-n10 {
    margin: -40px !important;
  }

  .ma-sm-n11 {
    margin: -44px !important;
  }

  .ma-sm-n12 {
    margin: -48px !important;
  }

  .ma-sm-n13 {
    margin: -52px !important;
  }

  .ma-sm-n14 {
    margin: -56px !important;
  }

  .ma-sm-n15 {
    margin: -60px !important;
  }

  .ma-sm-n16 {
    margin: -64px !important;
  }

  .mx-sm-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .mx-sm-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .mx-sm-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .mx-sm-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .mx-sm-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .mx-sm-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mx-sm-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .mx-sm-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .mx-sm-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .mx-sm-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .mx-sm-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .mx-sm-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .mx-sm-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .mx-sm-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .mx-sm-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .mx-sm-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .my-sm-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-sm-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-sm-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-sm-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-sm-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-sm-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-sm-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .my-sm-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-sm-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-sm-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-sm-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .my-sm-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-sm-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .my-sm-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .my-sm-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .my-sm-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .mt-sm-n1 {
    margin-top: -4px !important;
  }

  .mt-sm-n2 {
    margin-top: -8px !important;
  }

  .mt-sm-n3 {
    margin-top: -12px !important;
  }

  .mt-sm-n4 {
    margin-top: -16px !important;
  }

  .mt-sm-n5 {
    margin-top: -20px !important;
  }

  .mt-sm-n6 {
    margin-top: -24px !important;
  }

  .mt-sm-n7 {
    margin-top: -28px !important;
  }

  .mt-sm-n8 {
    margin-top: -32px !important;
  }

  .mt-sm-n9 {
    margin-top: -36px !important;
  }

  .mt-sm-n10 {
    margin-top: -40px !important;
  }

  .mt-sm-n11 {
    margin-top: -44px !important;
  }

  .mt-sm-n12 {
    margin-top: -48px !important;
  }

  .mt-sm-n13 {
    margin-top: -52px !important;
  }

  .mt-sm-n14 {
    margin-top: -56px !important;
  }

  .mt-sm-n15 {
    margin-top: -60px !important;
  }

  .mt-sm-n16 {
    margin-top: -64px !important;
  }

  .mr-sm-n1 {
    margin-right: -4px !important;
  }

  .mr-sm-n2 {
    margin-right: -8px !important;
  }

  .mr-sm-n3 {
    margin-right: -12px !important;
  }

  .mr-sm-n4 {
    margin-right: -16px !important;
  }

  .mr-sm-n5 {
    margin-right: -20px !important;
  }

  .mr-sm-n6 {
    margin-right: -24px !important;
  }

  .mr-sm-n7 {
    margin-right: -28px !important;
  }

  .mr-sm-n8 {
    margin-right: -32px !important;
  }

  .mr-sm-n9 {
    margin-right: -36px !important;
  }

  .mr-sm-n10 {
    margin-right: -40px !important;
  }

  .mr-sm-n11 {
    margin-right: -44px !important;
  }

  .mr-sm-n12 {
    margin-right: -48px !important;
  }

  .mr-sm-n13 {
    margin-right: -52px !important;
  }

  .mr-sm-n14 {
    margin-right: -56px !important;
  }

  .mr-sm-n15 {
    margin-right: -60px !important;
  }

  .mr-sm-n16 {
    margin-right: -64px !important;
  }

  .mb-sm-n1 {
    margin-bottom: -4px !important;
  }

  .mb-sm-n2 {
    margin-bottom: -8px !important;
  }

  .mb-sm-n3 {
    margin-bottom: -12px !important;
  }

  .mb-sm-n4 {
    margin-bottom: -16px !important;
  }

  .mb-sm-n5 {
    margin-bottom: -20px !important;
  }

  .mb-sm-n6 {
    margin-bottom: -24px !important;
  }

  .mb-sm-n7 {
    margin-bottom: -28px !important;
  }

  .mb-sm-n8 {
    margin-bottom: -32px !important;
  }

  .mb-sm-n9 {
    margin-bottom: -36px !important;
  }

  .mb-sm-n10 {
    margin-bottom: -40px !important;
  }

  .mb-sm-n11 {
    margin-bottom: -44px !important;
  }

  .mb-sm-n12 {
    margin-bottom: -48px !important;
  }

  .mb-sm-n13 {
    margin-bottom: -52px !important;
  }

  .mb-sm-n14 {
    margin-bottom: -56px !important;
  }

  .mb-sm-n15 {
    margin-bottom: -60px !important;
  }

  .mb-sm-n16 {
    margin-bottom: -64px !important;
  }

  .ml-sm-n1 {
    margin-left: -4px !important;
  }

  .ml-sm-n2 {
    margin-left: -8px !important;
  }

  .ml-sm-n3 {
    margin-left: -12px !important;
  }

  .ml-sm-n4 {
    margin-left: -16px !important;
  }

  .ml-sm-n5 {
    margin-left: -20px !important;
  }

  .ml-sm-n6 {
    margin-left: -24px !important;
  }

  .ml-sm-n7 {
    margin-left: -28px !important;
  }

  .ml-sm-n8 {
    margin-left: -32px !important;
  }

  .ml-sm-n9 {
    margin-left: -36px !important;
  }

  .ml-sm-n10 {
    margin-left: -40px !important;
  }

  .ml-sm-n11 {
    margin-left: -44px !important;
  }

  .ml-sm-n12 {
    margin-left: -48px !important;
  }

  .ml-sm-n13 {
    margin-left: -52px !important;
  }

  .ml-sm-n14 {
    margin-left: -56px !important;
  }

  .ml-sm-n15 {
    margin-left: -60px !important;
  }

  .ml-sm-n16 {
    margin-left: -64px !important;
  }

  .ms-sm-n1 {
    margin-inline-start: -4px !important;
  }

  .ms-sm-n2 {
    margin-inline-start: -8px !important;
  }

  .ms-sm-n3 {
    margin-inline-start: -12px !important;
  }

  .ms-sm-n4 {
    margin-inline-start: -16px !important;
  }

  .ms-sm-n5 {
    margin-inline-start: -20px !important;
  }

  .ms-sm-n6 {
    margin-inline-start: -24px !important;
  }

  .ms-sm-n7 {
    margin-inline-start: -28px !important;
  }

  .ms-sm-n8 {
    margin-inline-start: -32px !important;
  }

  .ms-sm-n9 {
    margin-inline-start: -36px !important;
  }

  .ms-sm-n10 {
    margin-inline-start: -40px !important;
  }

  .ms-sm-n11 {
    margin-inline-start: -44px !important;
  }

  .ms-sm-n12 {
    margin-inline-start: -48px !important;
  }

  .ms-sm-n13 {
    margin-inline-start: -52px !important;
  }

  .ms-sm-n14 {
    margin-inline-start: -56px !important;
  }

  .ms-sm-n15 {
    margin-inline-start: -60px !important;
  }

  .ms-sm-n16 {
    margin-inline-start: -64px !important;
  }

  .me-sm-n1 {
    margin-inline-end: -4px !important;
  }

  .me-sm-n2 {
    margin-inline-end: -8px !important;
  }

  .me-sm-n3 {
    margin-inline-end: -12px !important;
  }

  .me-sm-n4 {
    margin-inline-end: -16px !important;
  }

  .me-sm-n5 {
    margin-inline-end: -20px !important;
  }

  .me-sm-n6 {
    margin-inline-end: -24px !important;
  }

  .me-sm-n7 {
    margin-inline-end: -28px !important;
  }

  .me-sm-n8 {
    margin-inline-end: -32px !important;
  }

  .me-sm-n9 {
    margin-inline-end: -36px !important;
  }

  .me-sm-n10 {
    margin-inline-end: -40px !important;
  }

  .me-sm-n11 {
    margin-inline-end: -44px !important;
  }

  .me-sm-n12 {
    margin-inline-end: -48px !important;
  }

  .me-sm-n13 {
    margin-inline-end: -52px !important;
  }

  .me-sm-n14 {
    margin-inline-end: -56px !important;
  }

  .me-sm-n15 {
    margin-inline-end: -60px !important;
  }

  .me-sm-n16 {
    margin-inline-end: -64px !important;
  }

  .pa-sm-0 {
    padding: 0 !important;
  }

  .pa-sm-1 {
    padding: 4px !important;
  }

  .pa-sm-2 {
    padding: 8px !important;
  }

  .pa-sm-3 {
    padding: 12px !important;
  }

  .pa-sm-4 {
    padding: 16px !important;
  }

  .pa-sm-5 {
    padding: 20px !important;
  }

  .pa-sm-6 {
    padding: 24px !important;
  }

  .pa-sm-7 {
    padding: 28px !important;
  }

  .pa-sm-8 {
    padding: 32px !important;
  }

  .pa-sm-9 {
    padding: 36px !important;
  }

  .pa-sm-10 {
    padding: 40px !important;
  }

  .pa-sm-11 {
    padding: 44px !important;
  }

  .pa-sm-12 {
    padding: 48px !important;
  }

  .pa-sm-13 {
    padding: 52px !important;
  }

  .pa-sm-14 {
    padding: 56px !important;
  }

  .pa-sm-15 {
    padding: 60px !important;
  }

  .pa-sm-16 {
    padding: 64px !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .px-sm-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-sm-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-sm-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-sm-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-sm-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-sm-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-sm-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-sm-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .px-sm-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-sm-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-sm-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-sm-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .px-sm-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .px-sm-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-sm-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-sm-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-sm-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-sm-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-sm-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-sm-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .py-sm-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-sm-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-sm-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-sm-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .py-sm-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-sm-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .py-sm-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .py-sm-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-sm-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 4px !important;
  }

  .pt-sm-2 {
    padding-top: 8px !important;
  }

  .pt-sm-3 {
    padding-top: 12px !important;
  }

  .pt-sm-4 {
    padding-top: 16px !important;
  }

  .pt-sm-5 {
    padding-top: 20px !important;
  }

  .pt-sm-6 {
    padding-top: 24px !important;
  }

  .pt-sm-7 {
    padding-top: 28px !important;
  }

  .pt-sm-8 {
    padding-top: 32px !important;
  }

  .pt-sm-9 {
    padding-top: 36px !important;
  }

  .pt-sm-10 {
    padding-top: 40px !important;
  }

  .pt-sm-11 {
    padding-top: 44px !important;
  }

  .pt-sm-12 {
    padding-top: 48px !important;
  }

  .pt-sm-13 {
    padding-top: 52px !important;
  }

  .pt-sm-14 {
    padding-top: 56px !important;
  }

  .pt-sm-15 {
    padding-top: 60px !important;
  }

  .pt-sm-16 {
    padding-top: 64px !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-1 {
    padding-right: 4px !important;
  }

  .pr-sm-2 {
    padding-right: 8px !important;
  }

  .pr-sm-3 {
    padding-right: 12px !important;
  }

  .pr-sm-4 {
    padding-right: 16px !important;
  }

  .pr-sm-5 {
    padding-right: 20px !important;
  }

  .pr-sm-6 {
    padding-right: 24px !important;
  }

  .pr-sm-7 {
    padding-right: 28px !important;
  }

  .pr-sm-8 {
    padding-right: 32px !important;
  }

  .pr-sm-9 {
    padding-right: 36px !important;
  }

  .pr-sm-10 {
    padding-right: 40px !important;
  }

  .pr-sm-11 {
    padding-right: 44px !important;
  }

  .pr-sm-12 {
    padding-right: 48px !important;
  }

  .pr-sm-13 {
    padding-right: 52px !important;
  }

  .pr-sm-14 {
    padding-right: 56px !important;
  }

  .pr-sm-15 {
    padding-right: 60px !important;
  }

  .pr-sm-16 {
    padding-right: 64px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 4px !important;
  }

  .pb-sm-2 {
    padding-bottom: 8px !important;
  }

  .pb-sm-3 {
    padding-bottom: 12px !important;
  }

  .pb-sm-4 {
    padding-bottom: 16px !important;
  }

  .pb-sm-5 {
    padding-bottom: 20px !important;
  }

  .pb-sm-6 {
    padding-bottom: 24px !important;
  }

  .pb-sm-7 {
    padding-bottom: 28px !important;
  }

  .pb-sm-8 {
    padding-bottom: 32px !important;
  }

  .pb-sm-9 {
    padding-bottom: 36px !important;
  }

  .pb-sm-10 {
    padding-bottom: 40px !important;
  }

  .pb-sm-11 {
    padding-bottom: 44px !important;
  }

  .pb-sm-12 {
    padding-bottom: 48px !important;
  }

  .pb-sm-13 {
    padding-bottom: 52px !important;
  }

  .pb-sm-14 {
    padding-bottom: 56px !important;
  }

  .pb-sm-15 {
    padding-bottom: 60px !important;
  }

  .pb-sm-16 {
    padding-bottom: 64px !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-1 {
    padding-left: 4px !important;
  }

  .pl-sm-2 {
    padding-left: 8px !important;
  }

  .pl-sm-3 {
    padding-left: 12px !important;
  }

  .pl-sm-4 {
    padding-left: 16px !important;
  }

  .pl-sm-5 {
    padding-left: 20px !important;
  }

  .pl-sm-6 {
    padding-left: 24px !important;
  }

  .pl-sm-7 {
    padding-left: 28px !important;
  }

  .pl-sm-8 {
    padding-left: 32px !important;
  }

  .pl-sm-9 {
    padding-left: 36px !important;
  }

  .pl-sm-10 {
    padding-left: 40px !important;
  }

  .pl-sm-11 {
    padding-left: 44px !important;
  }

  .pl-sm-12 {
    padding-left: 48px !important;
  }

  .pl-sm-13 {
    padding-left: 52px !important;
  }

  .pl-sm-14 {
    padding-left: 56px !important;
  }

  .pl-sm-15 {
    padding-left: 60px !important;
  }

  .pl-sm-16 {
    padding-left: 64px !important;
  }

  .ps-sm-0 {
    padding-inline-start: 0 !important;
  }

  .ps-sm-1 {
    padding-inline-start: 4px !important;
  }

  .ps-sm-2 {
    padding-inline-start: 8px !important;
  }

  .ps-sm-3 {
    padding-inline-start: 12px !important;
  }

  .ps-sm-4 {
    padding-inline-start: 16px !important;
  }

  .ps-sm-5 {
    padding-inline-start: 20px !important;
  }

  .ps-sm-6 {
    padding-inline-start: 24px !important;
  }

  .ps-sm-7 {
    padding-inline-start: 28px !important;
  }

  .ps-sm-8 {
    padding-inline-start: 32px !important;
  }

  .ps-sm-9 {
    padding-inline-start: 36px !important;
  }

  .ps-sm-10 {
    padding-inline-start: 40px !important;
  }

  .ps-sm-11 {
    padding-inline-start: 44px !important;
  }

  .ps-sm-12 {
    padding-inline-start: 48px !important;
  }

  .ps-sm-13 {
    padding-inline-start: 52px !important;
  }

  .ps-sm-14 {
    padding-inline-start: 56px !important;
  }

  .ps-sm-15 {
    padding-inline-start: 60px !important;
  }

  .ps-sm-16 {
    padding-inline-start: 64px !important;
  }

  .pe-sm-0 {
    padding-inline-end: 0 !important;
  }

  .pe-sm-1 {
    padding-inline-end: 4px !important;
  }

  .pe-sm-2 {
    padding-inline-end: 8px !important;
  }

  .pe-sm-3 {
    padding-inline-end: 12px !important;
  }

  .pe-sm-4 {
    padding-inline-end: 16px !important;
  }

  .pe-sm-5 {
    padding-inline-end: 20px !important;
  }

  .pe-sm-6 {
    padding-inline-end: 24px !important;
  }

  .pe-sm-7 {
    padding-inline-end: 28px !important;
  }

  .pe-sm-8 {
    padding-inline-end: 32px !important;
  }

  .pe-sm-9 {
    padding-inline-end: 36px !important;
  }

  .pe-sm-10 {
    padding-inline-end: 40px !important;
  }

  .pe-sm-11 {
    padding-inline-end: 44px !important;
  }

  .pe-sm-12 {
    padding-inline-end: 48px !important;
  }

  .pe-sm-13 {
    padding-inline-end: 52px !important;
  }

  .pe-sm-14 {
    padding-inline-end: 56px !important;
  }

  .pe-sm-15 {
    padding-inline-end: 60px !important;
  }

  .pe-sm-16 {
    padding-inline-end: 64px !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }

  .text-sm-start {
    text-align: start !important;
  }

  .text-sm-end {
    text-align: end !important;
  }

  .text-sm-h1 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.015625em !important;
    text-transform: none !important;
    font-size: 6rem !important;
  }

  .text-sm-h2 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.00833333em !important;
    text-transform: none !important;
    font-size: 3.75rem !important;
  }

  .text-sm-h3 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 3rem !important;
  }

  .text-sm-h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: .00735294em !important;
    text-transform: none !important;
    font-size: 2.125rem !important;
  }

  .text-sm-h5 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
  }

  .text-sm-h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .0125em !important;
    text-transform: none !important;
    font-size: 1.25rem !important;
  }

  .text-sm-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: .009375em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-sm-subtitle-2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .00714286em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-sm-body-1 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .03125em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-sm-body-2 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.425;
    letter-spacing: .0178571em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-sm-button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.6;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
    font-size: .875rem !important;
  }

  .text-sm-caption {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.667;
    letter-spacing: .0333333em !important;
    text-transform: none !important;
    font-size: .75rem !important;
  }

  .text-sm-overline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.667;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
    font-size: .75rem !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-screen {
    height: 100vh !important;
  }

  .h-sm-0 {
    height: 0 !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-0 {
    width: 0 !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-33 {
    width: 33% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-66 {
    width: 66% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@media (width >= 960px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .float-md-none {
    float: none !important;
  }

  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-md-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-md-start, .v-locale--is-ltr .float-md-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-md-start {
    float: left !important;
  }

  .flex-md-fill, .flex-md-1-1 {
    flex: auto !important;
  }

  .flex-md-1-0 {
    flex: 1 0 auto !important;
  }

  .flex-md-0-1 {
    flex: 0 auto !important;
  }

  .flex-md-0-0 {
    flex: none !important;
  }

  .flex-md-1-1-100 {
    flex: 100% !important;
  }

  .flex-md-1-0-100 {
    flex: 1 0 100% !important;
  }

  .flex-md-0-1-100 {
    flex: 0 100% !important;
  }

  .flex-md-0-0-100 {
    flex: 0 0 100% !important;
  }

  .flex-md-1-1-0 {
    flex: 1 1 0 !important;
  }

  .flex-md-1-0-0 {
    flex: 1 0 0 !important;
  }

  .flex-md-0-1-0 {
    flex: 0 1 0 !important;
  }

  .flex-md-0-0-0 {
    flex: 0 0 0 !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-md-start {
    justify-content: flex-start !important;
  }

  .justify-md-end {
    justify-content: flex-end !important;
  }

  .justify-md-center {
    justify-content: center !important;
  }

  .justify-md-space-between {
    justify-content: space-between !important;
  }

  .justify-md-space-around {
    justify-content: space-around !important;
  }

  .justify-md-space-evenly {
    justify-content: space-evenly !important;
  }

  .align-md-start {
    align-items: flex-start !important;
  }

  .align-md-end {
    align-items: flex-end !important;
  }

  .align-md-center {
    align-items: center !important;
  }

  .align-md-baseline {
    align-items: baseline !important;
  }

  .align-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-space-between {
    align-content: space-between !important;
  }

  .align-content-md-space-around {
    align-content: space-around !important;
  }

  .align-content-md-space-evenly {
    align-content: space-evenly !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-6 {
    order: 6 !important;
  }

  .order-md-7 {
    order: 7 !important;
  }

  .order-md-8 {
    order: 8 !important;
  }

  .order-md-9 {
    order: 9 !important;
  }

  .order-md-10 {
    order: 10 !important;
  }

  .order-md-11 {
    order: 11 !important;
  }

  .order-md-12 {
    order: 12 !important;
  }

  .order-md-last {
    order: 13 !important;
  }

  .ga-md-0 {
    gap: 0 !important;
  }

  .ga-md-1 {
    gap: 4px !important;
  }

  .ga-md-2 {
    gap: 8px !important;
  }

  .ga-md-3 {
    gap: 12px !important;
  }

  .ga-md-4 {
    gap: 16px !important;
  }

  .ga-md-5 {
    gap: 20px !important;
  }

  .ga-md-6 {
    gap: 24px !important;
  }

  .ga-md-7 {
    gap: 28px !important;
  }

  .ga-md-8 {
    gap: 32px !important;
  }

  .ga-md-9 {
    gap: 36px !important;
  }

  .ga-md-10 {
    gap: 40px !important;
  }

  .ga-md-11 {
    gap: 44px !important;
  }

  .ga-md-12 {
    gap: 48px !important;
  }

  .ga-md-13 {
    gap: 52px !important;
  }

  .ga-md-14 {
    gap: 56px !important;
  }

  .ga-md-15 {
    gap: 60px !important;
  }

  .ga-md-16 {
    gap: 64px !important;
  }

  .ga-md-auto {
    gap: auto !important;
  }

  .gr-md-0 {
    row-gap: 0 !important;
  }

  .gr-md-1 {
    row-gap: 4px !important;
  }

  .gr-md-2 {
    row-gap: 8px !important;
  }

  .gr-md-3 {
    row-gap: 12px !important;
  }

  .gr-md-4 {
    row-gap: 16px !important;
  }

  .gr-md-5 {
    row-gap: 20px !important;
  }

  .gr-md-6 {
    row-gap: 24px !important;
  }

  .gr-md-7 {
    row-gap: 28px !important;
  }

  .gr-md-8 {
    row-gap: 32px !important;
  }

  .gr-md-9 {
    row-gap: 36px !important;
  }

  .gr-md-10 {
    row-gap: 40px !important;
  }

  .gr-md-11 {
    row-gap: 44px !important;
  }

  .gr-md-12 {
    row-gap: 48px !important;
  }

  .gr-md-13 {
    row-gap: 52px !important;
  }

  .gr-md-14 {
    row-gap: 56px !important;
  }

  .gr-md-15 {
    row-gap: 60px !important;
  }

  .gr-md-16 {
    row-gap: 64px !important;
  }

  .gr-md-auto {
    row-gap: auto !important;
  }

  .gc-md-0 {
    column-gap: 0 !important;
  }

  .gc-md-1 {
    column-gap: 4px !important;
  }

  .gc-md-2 {
    column-gap: 8px !important;
  }

  .gc-md-3 {
    column-gap: 12px !important;
  }

  .gc-md-4 {
    column-gap: 16px !important;
  }

  .gc-md-5 {
    column-gap: 20px !important;
  }

  .gc-md-6 {
    column-gap: 24px !important;
  }

  .gc-md-7 {
    column-gap: 28px !important;
  }

  .gc-md-8 {
    column-gap: 32px !important;
  }

  .gc-md-9 {
    column-gap: 36px !important;
  }

  .gc-md-10 {
    column-gap: 40px !important;
  }

  .gc-md-11 {
    column-gap: 44px !important;
  }

  .gc-md-12 {
    column-gap: 48px !important;
  }

  .gc-md-13 {
    column-gap: 52px !important;
  }

  .gc-md-14 {
    column-gap: 56px !important;
  }

  .gc-md-15 {
    column-gap: 60px !important;
  }

  .gc-md-16 {
    column-gap: 64px !important;
  }

  .gc-md-auto {
    column-gap: auto !important;
  }

  .ma-md-0 {
    margin: 0 !important;
  }

  .ma-md-1 {
    margin: 4px !important;
  }

  .ma-md-2 {
    margin: 8px !important;
  }

  .ma-md-3 {
    margin: 12px !important;
  }

  .ma-md-4 {
    margin: 16px !important;
  }

  .ma-md-5 {
    margin: 20px !important;
  }

  .ma-md-6 {
    margin: 24px !important;
  }

  .ma-md-7 {
    margin: 28px !important;
  }

  .ma-md-8 {
    margin: 32px !important;
  }

  .ma-md-9 {
    margin: 36px !important;
  }

  .ma-md-10 {
    margin: 40px !important;
  }

  .ma-md-11 {
    margin: 44px !important;
  }

  .ma-md-12 {
    margin: 48px !important;
  }

  .ma-md-13 {
    margin: 52px !important;
  }

  .ma-md-14 {
    margin: 56px !important;
  }

  .ma-md-15 {
    margin: 60px !important;
  }

  .ma-md-16 {
    margin: 64px !important;
  }

  .ma-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-md-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-md-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-md-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-md-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-md-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-md-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .mx-md-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-md-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .mx-md-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-md-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .mx-md-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .mx-md-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .mx-md-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .mx-md-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-md-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-md-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-md-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-md-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-md-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .my-md-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-md-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-md-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-md-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .my-md-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-md-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .my-md-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .my-md-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-md-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 4px !important;
  }

  .mt-md-2 {
    margin-top: 8px !important;
  }

  .mt-md-3 {
    margin-top: 12px !important;
  }

  .mt-md-4 {
    margin-top: 16px !important;
  }

  .mt-md-5 {
    margin-top: 20px !important;
  }

  .mt-md-6 {
    margin-top: 24px !important;
  }

  .mt-md-7 {
    margin-top: 28px !important;
  }

  .mt-md-8 {
    margin-top: 32px !important;
  }

  .mt-md-9 {
    margin-top: 36px !important;
  }

  .mt-md-10 {
    margin-top: 40px !important;
  }

  .mt-md-11 {
    margin-top: 44px !important;
  }

  .mt-md-12 {
    margin-top: 48px !important;
  }

  .mt-md-13 {
    margin-top: 52px !important;
  }

  .mt-md-14 {
    margin-top: 56px !important;
  }

  .mt-md-15 {
    margin-top: 60px !important;
  }

  .mt-md-16 {
    margin-top: 64px !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: 4px !important;
  }

  .mr-md-2 {
    margin-right: 8px !important;
  }

  .mr-md-3 {
    margin-right: 12px !important;
  }

  .mr-md-4 {
    margin-right: 16px !important;
  }

  .mr-md-5 {
    margin-right: 20px !important;
  }

  .mr-md-6 {
    margin-right: 24px !important;
  }

  .mr-md-7 {
    margin-right: 28px !important;
  }

  .mr-md-8 {
    margin-right: 32px !important;
  }

  .mr-md-9 {
    margin-right: 36px !important;
  }

  .mr-md-10 {
    margin-right: 40px !important;
  }

  .mr-md-11 {
    margin-right: 44px !important;
  }

  .mr-md-12 {
    margin-right: 48px !important;
  }

  .mr-md-13 {
    margin-right: 52px !important;
  }

  .mr-md-14 {
    margin-right: 56px !important;
  }

  .mr-md-15 {
    margin-right: 60px !important;
  }

  .mr-md-16 {
    margin-right: 64px !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 4px !important;
  }

  .mb-md-2 {
    margin-bottom: 8px !important;
  }

  .mb-md-3 {
    margin-bottom: 12px !important;
  }

  .mb-md-4 {
    margin-bottom: 16px !important;
  }

  .mb-md-5 {
    margin-bottom: 20px !important;
  }

  .mb-md-6 {
    margin-bottom: 24px !important;
  }

  .mb-md-7 {
    margin-bottom: 28px !important;
  }

  .mb-md-8 {
    margin-bottom: 32px !important;
  }

  .mb-md-9 {
    margin-bottom: 36px !important;
  }

  .mb-md-10 {
    margin-bottom: 40px !important;
  }

  .mb-md-11 {
    margin-bottom: 44px !important;
  }

  .mb-md-12 {
    margin-bottom: 48px !important;
  }

  .mb-md-13 {
    margin-bottom: 52px !important;
  }

  .mb-md-14 {
    margin-bottom: 56px !important;
  }

  .mb-md-15 {
    margin-bottom: 60px !important;
  }

  .mb-md-16 {
    margin-bottom: 64px !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: 4px !important;
  }

  .ml-md-2 {
    margin-left: 8px !important;
  }

  .ml-md-3 {
    margin-left: 12px !important;
  }

  .ml-md-4 {
    margin-left: 16px !important;
  }

  .ml-md-5 {
    margin-left: 20px !important;
  }

  .ml-md-6 {
    margin-left: 24px !important;
  }

  .ml-md-7 {
    margin-left: 28px !important;
  }

  .ml-md-8 {
    margin-left: 32px !important;
  }

  .ml-md-9 {
    margin-left: 36px !important;
  }

  .ml-md-10 {
    margin-left: 40px !important;
  }

  .ml-md-11 {
    margin-left: 44px !important;
  }

  .ml-md-12 {
    margin-left: 48px !important;
  }

  .ml-md-13 {
    margin-left: 52px !important;
  }

  .ml-md-14 {
    margin-left: 56px !important;
  }

  .ml-md-15 {
    margin-left: 60px !important;
  }

  .ml-md-16 {
    margin-left: 64px !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .ms-md-0 {
    margin-inline-start: 0 !important;
  }

  .ms-md-1 {
    margin-inline-start: 4px !important;
  }

  .ms-md-2 {
    margin-inline-start: 8px !important;
  }

  .ms-md-3 {
    margin-inline-start: 12px !important;
  }

  .ms-md-4 {
    margin-inline-start: 16px !important;
  }

  .ms-md-5 {
    margin-inline-start: 20px !important;
  }

  .ms-md-6 {
    margin-inline-start: 24px !important;
  }

  .ms-md-7 {
    margin-inline-start: 28px !important;
  }

  .ms-md-8 {
    margin-inline-start: 32px !important;
  }

  .ms-md-9 {
    margin-inline-start: 36px !important;
  }

  .ms-md-10 {
    margin-inline-start: 40px !important;
  }

  .ms-md-11 {
    margin-inline-start: 44px !important;
  }

  .ms-md-12 {
    margin-inline-start: 48px !important;
  }

  .ms-md-13 {
    margin-inline-start: 52px !important;
  }

  .ms-md-14 {
    margin-inline-start: 56px !important;
  }

  .ms-md-15 {
    margin-inline-start: 60px !important;
  }

  .ms-md-16 {
    margin-inline-start: 64px !important;
  }

  .ms-md-auto {
    margin-inline-start: auto !important;
  }

  .me-md-0 {
    margin-inline-end: 0 !important;
  }

  .me-md-1 {
    margin-inline-end: 4px !important;
  }

  .me-md-2 {
    margin-inline-end: 8px !important;
  }

  .me-md-3 {
    margin-inline-end: 12px !important;
  }

  .me-md-4 {
    margin-inline-end: 16px !important;
  }

  .me-md-5 {
    margin-inline-end: 20px !important;
  }

  .me-md-6 {
    margin-inline-end: 24px !important;
  }

  .me-md-7 {
    margin-inline-end: 28px !important;
  }

  .me-md-8 {
    margin-inline-end: 32px !important;
  }

  .me-md-9 {
    margin-inline-end: 36px !important;
  }

  .me-md-10 {
    margin-inline-end: 40px !important;
  }

  .me-md-11 {
    margin-inline-end: 44px !important;
  }

  .me-md-12 {
    margin-inline-end: 48px !important;
  }

  .me-md-13 {
    margin-inline-end: 52px !important;
  }

  .me-md-14 {
    margin-inline-end: 56px !important;
  }

  .me-md-15 {
    margin-inline-end: 60px !important;
  }

  .me-md-16 {
    margin-inline-end: 64px !important;
  }

  .me-md-auto {
    margin-inline-end: auto !important;
  }

  .ma-md-n1 {
    margin: -4px !important;
  }

  .ma-md-n2 {
    margin: -8px !important;
  }

  .ma-md-n3 {
    margin: -12px !important;
  }

  .ma-md-n4 {
    margin: -16px !important;
  }

  .ma-md-n5 {
    margin: -20px !important;
  }

  .ma-md-n6 {
    margin: -24px !important;
  }

  .ma-md-n7 {
    margin: -28px !important;
  }

  .ma-md-n8 {
    margin: -32px !important;
  }

  .ma-md-n9 {
    margin: -36px !important;
  }

  .ma-md-n10 {
    margin: -40px !important;
  }

  .ma-md-n11 {
    margin: -44px !important;
  }

  .ma-md-n12 {
    margin: -48px !important;
  }

  .ma-md-n13 {
    margin: -52px !important;
  }

  .ma-md-n14 {
    margin: -56px !important;
  }

  .ma-md-n15 {
    margin: -60px !important;
  }

  .ma-md-n16 {
    margin: -64px !important;
  }

  .mx-md-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .mx-md-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .mx-md-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .mx-md-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .mx-md-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .mx-md-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mx-md-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .mx-md-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .mx-md-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .mx-md-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .mx-md-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .mx-md-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .mx-md-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .mx-md-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .mx-md-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .mx-md-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .my-md-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-md-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-md-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-md-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-md-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-md-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-md-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .my-md-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-md-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-md-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-md-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .my-md-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-md-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .my-md-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .my-md-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .my-md-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .mt-md-n1 {
    margin-top: -4px !important;
  }

  .mt-md-n2 {
    margin-top: -8px !important;
  }

  .mt-md-n3 {
    margin-top: -12px !important;
  }

  .mt-md-n4 {
    margin-top: -16px !important;
  }

  .mt-md-n5 {
    margin-top: -20px !important;
  }

  .mt-md-n6 {
    margin-top: -24px !important;
  }

  .mt-md-n7 {
    margin-top: -28px !important;
  }

  .mt-md-n8 {
    margin-top: -32px !important;
  }

  .mt-md-n9 {
    margin-top: -36px !important;
  }

  .mt-md-n10 {
    margin-top: -40px !important;
  }

  .mt-md-n11 {
    margin-top: -44px !important;
  }

  .mt-md-n12 {
    margin-top: -48px !important;
  }

  .mt-md-n13 {
    margin-top: -52px !important;
  }

  .mt-md-n14 {
    margin-top: -56px !important;
  }

  .mt-md-n15 {
    margin-top: -60px !important;
  }

  .mt-md-n16 {
    margin-top: -64px !important;
  }

  .mr-md-n1 {
    margin-right: -4px !important;
  }

  .mr-md-n2 {
    margin-right: -8px !important;
  }

  .mr-md-n3 {
    margin-right: -12px !important;
  }

  .mr-md-n4 {
    margin-right: -16px !important;
  }

  .mr-md-n5 {
    margin-right: -20px !important;
  }

  .mr-md-n6 {
    margin-right: -24px !important;
  }

  .mr-md-n7 {
    margin-right: -28px !important;
  }

  .mr-md-n8 {
    margin-right: -32px !important;
  }

  .mr-md-n9 {
    margin-right: -36px !important;
  }

  .mr-md-n10 {
    margin-right: -40px !important;
  }

  .mr-md-n11 {
    margin-right: -44px !important;
  }

  .mr-md-n12 {
    margin-right: -48px !important;
  }

  .mr-md-n13 {
    margin-right: -52px !important;
  }

  .mr-md-n14 {
    margin-right: -56px !important;
  }

  .mr-md-n15 {
    margin-right: -60px !important;
  }

  .mr-md-n16 {
    margin-right: -64px !important;
  }

  .mb-md-n1 {
    margin-bottom: -4px !important;
  }

  .mb-md-n2 {
    margin-bottom: -8px !important;
  }

  .mb-md-n3 {
    margin-bottom: -12px !important;
  }

  .mb-md-n4 {
    margin-bottom: -16px !important;
  }

  .mb-md-n5 {
    margin-bottom: -20px !important;
  }

  .mb-md-n6 {
    margin-bottom: -24px !important;
  }

  .mb-md-n7 {
    margin-bottom: -28px !important;
  }

  .mb-md-n8 {
    margin-bottom: -32px !important;
  }

  .mb-md-n9 {
    margin-bottom: -36px !important;
  }

  .mb-md-n10 {
    margin-bottom: -40px !important;
  }

  .mb-md-n11 {
    margin-bottom: -44px !important;
  }

  .mb-md-n12 {
    margin-bottom: -48px !important;
  }

  .mb-md-n13 {
    margin-bottom: -52px !important;
  }

  .mb-md-n14 {
    margin-bottom: -56px !important;
  }

  .mb-md-n15 {
    margin-bottom: -60px !important;
  }

  .mb-md-n16 {
    margin-bottom: -64px !important;
  }

  .ml-md-n1 {
    margin-left: -4px !important;
  }

  .ml-md-n2 {
    margin-left: -8px !important;
  }

  .ml-md-n3 {
    margin-left: -12px !important;
  }

  .ml-md-n4 {
    margin-left: -16px !important;
  }

  .ml-md-n5 {
    margin-left: -20px !important;
  }

  .ml-md-n6 {
    margin-left: -24px !important;
  }

  .ml-md-n7 {
    margin-left: -28px !important;
  }

  .ml-md-n8 {
    margin-left: -32px !important;
  }

  .ml-md-n9 {
    margin-left: -36px !important;
  }

  .ml-md-n10 {
    margin-left: -40px !important;
  }

  .ml-md-n11 {
    margin-left: -44px !important;
  }

  .ml-md-n12 {
    margin-left: -48px !important;
  }

  .ml-md-n13 {
    margin-left: -52px !important;
  }

  .ml-md-n14 {
    margin-left: -56px !important;
  }

  .ml-md-n15 {
    margin-left: -60px !important;
  }

  .ml-md-n16 {
    margin-left: -64px !important;
  }

  .ms-md-n1 {
    margin-inline-start: -4px !important;
  }

  .ms-md-n2 {
    margin-inline-start: -8px !important;
  }

  .ms-md-n3 {
    margin-inline-start: -12px !important;
  }

  .ms-md-n4 {
    margin-inline-start: -16px !important;
  }

  .ms-md-n5 {
    margin-inline-start: -20px !important;
  }

  .ms-md-n6 {
    margin-inline-start: -24px !important;
  }

  .ms-md-n7 {
    margin-inline-start: -28px !important;
  }

  .ms-md-n8 {
    margin-inline-start: -32px !important;
  }

  .ms-md-n9 {
    margin-inline-start: -36px !important;
  }

  .ms-md-n10 {
    margin-inline-start: -40px !important;
  }

  .ms-md-n11 {
    margin-inline-start: -44px !important;
  }

  .ms-md-n12 {
    margin-inline-start: -48px !important;
  }

  .ms-md-n13 {
    margin-inline-start: -52px !important;
  }

  .ms-md-n14 {
    margin-inline-start: -56px !important;
  }

  .ms-md-n15 {
    margin-inline-start: -60px !important;
  }

  .ms-md-n16 {
    margin-inline-start: -64px !important;
  }

  .me-md-n1 {
    margin-inline-end: -4px !important;
  }

  .me-md-n2 {
    margin-inline-end: -8px !important;
  }

  .me-md-n3 {
    margin-inline-end: -12px !important;
  }

  .me-md-n4 {
    margin-inline-end: -16px !important;
  }

  .me-md-n5 {
    margin-inline-end: -20px !important;
  }

  .me-md-n6 {
    margin-inline-end: -24px !important;
  }

  .me-md-n7 {
    margin-inline-end: -28px !important;
  }

  .me-md-n8 {
    margin-inline-end: -32px !important;
  }

  .me-md-n9 {
    margin-inline-end: -36px !important;
  }

  .me-md-n10 {
    margin-inline-end: -40px !important;
  }

  .me-md-n11 {
    margin-inline-end: -44px !important;
  }

  .me-md-n12 {
    margin-inline-end: -48px !important;
  }

  .me-md-n13 {
    margin-inline-end: -52px !important;
  }

  .me-md-n14 {
    margin-inline-end: -56px !important;
  }

  .me-md-n15 {
    margin-inline-end: -60px !important;
  }

  .me-md-n16 {
    margin-inline-end: -64px !important;
  }

  .pa-md-0 {
    padding: 0 !important;
  }

  .pa-md-1 {
    padding: 4px !important;
  }

  .pa-md-2 {
    padding: 8px !important;
  }

  .pa-md-3 {
    padding: 12px !important;
  }

  .pa-md-4 {
    padding: 16px !important;
  }

  .pa-md-5 {
    padding: 20px !important;
  }

  .pa-md-6 {
    padding: 24px !important;
  }

  .pa-md-7 {
    padding: 28px !important;
  }

  .pa-md-8 {
    padding: 32px !important;
  }

  .pa-md-9 {
    padding: 36px !important;
  }

  .pa-md-10 {
    padding: 40px !important;
  }

  .pa-md-11 {
    padding: 44px !important;
  }

  .pa-md-12 {
    padding: 48px !important;
  }

  .pa-md-13 {
    padding: 52px !important;
  }

  .pa-md-14 {
    padding: 56px !important;
  }

  .pa-md-15 {
    padding: 60px !important;
  }

  .pa-md-16 {
    padding: 64px !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .px-md-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-md-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-md-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-md-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-md-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-md-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-md-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-md-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .px-md-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-md-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-md-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-md-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .px-md-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .px-md-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-md-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-md-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-md-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-md-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-md-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-md-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .py-md-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-md-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-md-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-md-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .py-md-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-md-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .py-md-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .py-md-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-md-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 4px !important;
  }

  .pt-md-2 {
    padding-top: 8px !important;
  }

  .pt-md-3 {
    padding-top: 12px !important;
  }

  .pt-md-4 {
    padding-top: 16px !important;
  }

  .pt-md-5 {
    padding-top: 20px !important;
  }

  .pt-md-6 {
    padding-top: 24px !important;
  }

  .pt-md-7 {
    padding-top: 28px !important;
  }

  .pt-md-8 {
    padding-top: 32px !important;
  }

  .pt-md-9 {
    padding-top: 36px !important;
  }

  .pt-md-10 {
    padding-top: 40px !important;
  }

  .pt-md-11 {
    padding-top: 44px !important;
  }

  .pt-md-12 {
    padding-top: 48px !important;
  }

  .pt-md-13 {
    padding-top: 52px !important;
  }

  .pt-md-14 {
    padding-top: 56px !important;
  }

  .pt-md-15 {
    padding-top: 60px !important;
  }

  .pt-md-16 {
    padding-top: 64px !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-1 {
    padding-right: 4px !important;
  }

  .pr-md-2 {
    padding-right: 8px !important;
  }

  .pr-md-3 {
    padding-right: 12px !important;
  }

  .pr-md-4 {
    padding-right: 16px !important;
  }

  .pr-md-5 {
    padding-right: 20px !important;
  }

  .pr-md-6 {
    padding-right: 24px !important;
  }

  .pr-md-7 {
    padding-right: 28px !important;
  }

  .pr-md-8 {
    padding-right: 32px !important;
  }

  .pr-md-9 {
    padding-right: 36px !important;
  }

  .pr-md-10 {
    padding-right: 40px !important;
  }

  .pr-md-11 {
    padding-right: 44px !important;
  }

  .pr-md-12 {
    padding-right: 48px !important;
  }

  .pr-md-13 {
    padding-right: 52px !important;
  }

  .pr-md-14 {
    padding-right: 56px !important;
  }

  .pr-md-15 {
    padding-right: 60px !important;
  }

  .pr-md-16 {
    padding-right: 64px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 4px !important;
  }

  .pb-md-2 {
    padding-bottom: 8px !important;
  }

  .pb-md-3 {
    padding-bottom: 12px !important;
  }

  .pb-md-4 {
    padding-bottom: 16px !important;
  }

  .pb-md-5 {
    padding-bottom: 20px !important;
  }

  .pb-md-6 {
    padding-bottom: 24px !important;
  }

  .pb-md-7 {
    padding-bottom: 28px !important;
  }

  .pb-md-8 {
    padding-bottom: 32px !important;
  }

  .pb-md-9 {
    padding-bottom: 36px !important;
  }

  .pb-md-10 {
    padding-bottom: 40px !important;
  }

  .pb-md-11 {
    padding-bottom: 44px !important;
  }

  .pb-md-12 {
    padding-bottom: 48px !important;
  }

  .pb-md-13 {
    padding-bottom: 52px !important;
  }

  .pb-md-14 {
    padding-bottom: 56px !important;
  }

  .pb-md-15 {
    padding-bottom: 60px !important;
  }

  .pb-md-16 {
    padding-bottom: 64px !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-1 {
    padding-left: 4px !important;
  }

  .pl-md-2 {
    padding-left: 8px !important;
  }

  .pl-md-3 {
    padding-left: 12px !important;
  }

  .pl-md-4 {
    padding-left: 16px !important;
  }

  .pl-md-5 {
    padding-left: 20px !important;
  }

  .pl-md-6 {
    padding-left: 24px !important;
  }

  .pl-md-7 {
    padding-left: 28px !important;
  }

  .pl-md-8 {
    padding-left: 32px !important;
  }

  .pl-md-9 {
    padding-left: 36px !important;
  }

  .pl-md-10 {
    padding-left: 40px !important;
  }

  .pl-md-11 {
    padding-left: 44px !important;
  }

  .pl-md-12 {
    padding-left: 48px !important;
  }

  .pl-md-13 {
    padding-left: 52px !important;
  }

  .pl-md-14 {
    padding-left: 56px !important;
  }

  .pl-md-15 {
    padding-left: 60px !important;
  }

  .pl-md-16 {
    padding-left: 64px !important;
  }

  .ps-md-0 {
    padding-inline-start: 0 !important;
  }

  .ps-md-1 {
    padding-inline-start: 4px !important;
  }

  .ps-md-2 {
    padding-inline-start: 8px !important;
  }

  .ps-md-3 {
    padding-inline-start: 12px !important;
  }

  .ps-md-4 {
    padding-inline-start: 16px !important;
  }

  .ps-md-5 {
    padding-inline-start: 20px !important;
  }

  .ps-md-6 {
    padding-inline-start: 24px !important;
  }

  .ps-md-7 {
    padding-inline-start: 28px !important;
  }

  .ps-md-8 {
    padding-inline-start: 32px !important;
  }

  .ps-md-9 {
    padding-inline-start: 36px !important;
  }

  .ps-md-10 {
    padding-inline-start: 40px !important;
  }

  .ps-md-11 {
    padding-inline-start: 44px !important;
  }

  .ps-md-12 {
    padding-inline-start: 48px !important;
  }

  .ps-md-13 {
    padding-inline-start: 52px !important;
  }

  .ps-md-14 {
    padding-inline-start: 56px !important;
  }

  .ps-md-15 {
    padding-inline-start: 60px !important;
  }

  .ps-md-16 {
    padding-inline-start: 64px !important;
  }

  .pe-md-0 {
    padding-inline-end: 0 !important;
  }

  .pe-md-1 {
    padding-inline-end: 4px !important;
  }

  .pe-md-2 {
    padding-inline-end: 8px !important;
  }

  .pe-md-3 {
    padding-inline-end: 12px !important;
  }

  .pe-md-4 {
    padding-inline-end: 16px !important;
  }

  .pe-md-5 {
    padding-inline-end: 20px !important;
  }

  .pe-md-6 {
    padding-inline-end: 24px !important;
  }

  .pe-md-7 {
    padding-inline-end: 28px !important;
  }

  .pe-md-8 {
    padding-inline-end: 32px !important;
  }

  .pe-md-9 {
    padding-inline-end: 36px !important;
  }

  .pe-md-10 {
    padding-inline-end: 40px !important;
  }

  .pe-md-11 {
    padding-inline-end: 44px !important;
  }

  .pe-md-12 {
    padding-inline-end: 48px !important;
  }

  .pe-md-13 {
    padding-inline-end: 52px !important;
  }

  .pe-md-14 {
    padding-inline-end: 56px !important;
  }

  .pe-md-15 {
    padding-inline-end: 60px !important;
  }

  .pe-md-16 {
    padding-inline-end: 64px !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-justify {
    text-align: justify !important;
  }

  .text-md-start {
    text-align: start !important;
  }

  .text-md-end {
    text-align: end !important;
  }

  .text-md-h1 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.015625em !important;
    text-transform: none !important;
    font-size: 6rem !important;
  }

  .text-md-h2 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.00833333em !important;
    text-transform: none !important;
    font-size: 3.75rem !important;
  }

  .text-md-h3 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 3rem !important;
  }

  .text-md-h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: .00735294em !important;
    text-transform: none !important;
    font-size: 2.125rem !important;
  }

  .text-md-h5 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
  }

  .text-md-h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .0125em !important;
    text-transform: none !important;
    font-size: 1.25rem !important;
  }

  .text-md-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: .009375em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-md-subtitle-2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .00714286em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-md-body-1 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .03125em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-md-body-2 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.425;
    letter-spacing: .0178571em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-md-button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.6;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
    font-size: .875rem !important;
  }

  .text-md-caption {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.667;
    letter-spacing: .0333333em !important;
    text-transform: none !important;
    font-size: .75rem !important;
  }

  .text-md-overline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.667;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
    font-size: .75rem !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-screen {
    height: 100vh !important;
  }

  .h-md-0 {
    height: 0 !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-0 {
    width: 0 !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-33 {
    width: 33% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-66 {
    width: 66% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}

@media (width >= 1280px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-lg-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-lg-start, .v-locale--is-ltr .float-lg-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-lg-start {
    float: left !important;
  }

  .flex-lg-fill, .flex-lg-1-1 {
    flex: auto !important;
  }

  .flex-lg-1-0 {
    flex: 1 0 auto !important;
  }

  .flex-lg-0-1 {
    flex: 0 auto !important;
  }

  .flex-lg-0-0 {
    flex: none !important;
  }

  .flex-lg-1-1-100 {
    flex: 100% !important;
  }

  .flex-lg-1-0-100 {
    flex: 1 0 100% !important;
  }

  .flex-lg-0-1-100 {
    flex: 0 100% !important;
  }

  .flex-lg-0-0-100 {
    flex: 0 0 100% !important;
  }

  .flex-lg-1-1-0 {
    flex: 1 1 0 !important;
  }

  .flex-lg-1-0-0 {
    flex: 1 0 0 !important;
  }

  .flex-lg-0-1-0 {
    flex: 0 1 0 !important;
  }

  .flex-lg-0-0-0 {
    flex: 0 0 0 !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-lg-start {
    justify-content: flex-start !important;
  }

  .justify-lg-end {
    justify-content: flex-end !important;
  }

  .justify-lg-center {
    justify-content: center !important;
  }

  .justify-lg-space-between {
    justify-content: space-between !important;
  }

  .justify-lg-space-around {
    justify-content: space-around !important;
  }

  .justify-lg-space-evenly {
    justify-content: space-evenly !important;
  }

  .align-lg-start {
    align-items: flex-start !important;
  }

  .align-lg-end {
    align-items: flex-end !important;
  }

  .align-lg-center {
    align-items: center !important;
  }

  .align-lg-baseline {
    align-items: baseline !important;
  }

  .align-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-space-between {
    align-content: space-between !important;
  }

  .align-content-lg-space-around {
    align-content: space-around !important;
  }

  .align-content-lg-space-evenly {
    align-content: space-evenly !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-6 {
    order: 6 !important;
  }

  .order-lg-7 {
    order: 7 !important;
  }

  .order-lg-8 {
    order: 8 !important;
  }

  .order-lg-9 {
    order: 9 !important;
  }

  .order-lg-10 {
    order: 10 !important;
  }

  .order-lg-11 {
    order: 11 !important;
  }

  .order-lg-12 {
    order: 12 !important;
  }

  .order-lg-last {
    order: 13 !important;
  }

  .ga-lg-0 {
    gap: 0 !important;
  }

  .ga-lg-1 {
    gap: 4px !important;
  }

  .ga-lg-2 {
    gap: 8px !important;
  }

  .ga-lg-3 {
    gap: 12px !important;
  }

  .ga-lg-4 {
    gap: 16px !important;
  }

  .ga-lg-5 {
    gap: 20px !important;
  }

  .ga-lg-6 {
    gap: 24px !important;
  }

  .ga-lg-7 {
    gap: 28px !important;
  }

  .ga-lg-8 {
    gap: 32px !important;
  }

  .ga-lg-9 {
    gap: 36px !important;
  }

  .ga-lg-10 {
    gap: 40px !important;
  }

  .ga-lg-11 {
    gap: 44px !important;
  }

  .ga-lg-12 {
    gap: 48px !important;
  }

  .ga-lg-13 {
    gap: 52px !important;
  }

  .ga-lg-14 {
    gap: 56px !important;
  }

  .ga-lg-15 {
    gap: 60px !important;
  }

  .ga-lg-16 {
    gap: 64px !important;
  }

  .ga-lg-auto {
    gap: auto !important;
  }

  .gr-lg-0 {
    row-gap: 0 !important;
  }

  .gr-lg-1 {
    row-gap: 4px !important;
  }

  .gr-lg-2 {
    row-gap: 8px !important;
  }

  .gr-lg-3 {
    row-gap: 12px !important;
  }

  .gr-lg-4 {
    row-gap: 16px !important;
  }

  .gr-lg-5 {
    row-gap: 20px !important;
  }

  .gr-lg-6 {
    row-gap: 24px !important;
  }

  .gr-lg-7 {
    row-gap: 28px !important;
  }

  .gr-lg-8 {
    row-gap: 32px !important;
  }

  .gr-lg-9 {
    row-gap: 36px !important;
  }

  .gr-lg-10 {
    row-gap: 40px !important;
  }

  .gr-lg-11 {
    row-gap: 44px !important;
  }

  .gr-lg-12 {
    row-gap: 48px !important;
  }

  .gr-lg-13 {
    row-gap: 52px !important;
  }

  .gr-lg-14 {
    row-gap: 56px !important;
  }

  .gr-lg-15 {
    row-gap: 60px !important;
  }

  .gr-lg-16 {
    row-gap: 64px !important;
  }

  .gr-lg-auto {
    row-gap: auto !important;
  }

  .gc-lg-0 {
    column-gap: 0 !important;
  }

  .gc-lg-1 {
    column-gap: 4px !important;
  }

  .gc-lg-2 {
    column-gap: 8px !important;
  }

  .gc-lg-3 {
    column-gap: 12px !important;
  }

  .gc-lg-4 {
    column-gap: 16px !important;
  }

  .gc-lg-5 {
    column-gap: 20px !important;
  }

  .gc-lg-6 {
    column-gap: 24px !important;
  }

  .gc-lg-7 {
    column-gap: 28px !important;
  }

  .gc-lg-8 {
    column-gap: 32px !important;
  }

  .gc-lg-9 {
    column-gap: 36px !important;
  }

  .gc-lg-10 {
    column-gap: 40px !important;
  }

  .gc-lg-11 {
    column-gap: 44px !important;
  }

  .gc-lg-12 {
    column-gap: 48px !important;
  }

  .gc-lg-13 {
    column-gap: 52px !important;
  }

  .gc-lg-14 {
    column-gap: 56px !important;
  }

  .gc-lg-15 {
    column-gap: 60px !important;
  }

  .gc-lg-16 {
    column-gap: 64px !important;
  }

  .gc-lg-auto {
    column-gap: auto !important;
  }

  .ma-lg-0 {
    margin: 0 !important;
  }

  .ma-lg-1 {
    margin: 4px !important;
  }

  .ma-lg-2 {
    margin: 8px !important;
  }

  .ma-lg-3 {
    margin: 12px !important;
  }

  .ma-lg-4 {
    margin: 16px !important;
  }

  .ma-lg-5 {
    margin: 20px !important;
  }

  .ma-lg-6 {
    margin: 24px !important;
  }

  .ma-lg-7 {
    margin: 28px !important;
  }

  .ma-lg-8 {
    margin: 32px !important;
  }

  .ma-lg-9 {
    margin: 36px !important;
  }

  .ma-lg-10 {
    margin: 40px !important;
  }

  .ma-lg-11 {
    margin: 44px !important;
  }

  .ma-lg-12 {
    margin: 48px !important;
  }

  .ma-lg-13 {
    margin: 52px !important;
  }

  .ma-lg-14 {
    margin: 56px !important;
  }

  .ma-lg-15 {
    margin: 60px !important;
  }

  .ma-lg-16 {
    margin: 64px !important;
  }

  .ma-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-lg-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-lg-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-lg-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-lg-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-lg-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-lg-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .mx-lg-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-lg-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .mx-lg-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-lg-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .mx-lg-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .mx-lg-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .mx-lg-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .mx-lg-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-lg-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-lg-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-lg-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-lg-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-lg-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-lg-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .my-lg-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-lg-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-lg-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-lg-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .my-lg-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-lg-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .my-lg-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .my-lg-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-lg-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 4px !important;
  }

  .mt-lg-2 {
    margin-top: 8px !important;
  }

  .mt-lg-3 {
    margin-top: 12px !important;
  }

  .mt-lg-4 {
    margin-top: 16px !important;
  }

  .mt-lg-5 {
    margin-top: 20px !important;
  }

  .mt-lg-6 {
    margin-top: 24px !important;
  }

  .mt-lg-7 {
    margin-top: 28px !important;
  }

  .mt-lg-8 {
    margin-top: 32px !important;
  }

  .mt-lg-9 {
    margin-top: 36px !important;
  }

  .mt-lg-10 {
    margin-top: 40px !important;
  }

  .mt-lg-11 {
    margin-top: 44px !important;
  }

  .mt-lg-12 {
    margin-top: 48px !important;
  }

  .mt-lg-13 {
    margin-top: 52px !important;
  }

  .mt-lg-14 {
    margin-top: 56px !important;
  }

  .mt-lg-15 {
    margin-top: 60px !important;
  }

  .mt-lg-16 {
    margin-top: 64px !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: 4px !important;
  }

  .mr-lg-2 {
    margin-right: 8px !important;
  }

  .mr-lg-3 {
    margin-right: 12px !important;
  }

  .mr-lg-4 {
    margin-right: 16px !important;
  }

  .mr-lg-5 {
    margin-right: 20px !important;
  }

  .mr-lg-6 {
    margin-right: 24px !important;
  }

  .mr-lg-7 {
    margin-right: 28px !important;
  }

  .mr-lg-8 {
    margin-right: 32px !important;
  }

  .mr-lg-9 {
    margin-right: 36px !important;
  }

  .mr-lg-10 {
    margin-right: 40px !important;
  }

  .mr-lg-11 {
    margin-right: 44px !important;
  }

  .mr-lg-12 {
    margin-right: 48px !important;
  }

  .mr-lg-13 {
    margin-right: 52px !important;
  }

  .mr-lg-14 {
    margin-right: 56px !important;
  }

  .mr-lg-15 {
    margin-right: 60px !important;
  }

  .mr-lg-16 {
    margin-right: 64px !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 4px !important;
  }

  .mb-lg-2 {
    margin-bottom: 8px !important;
  }

  .mb-lg-3 {
    margin-bottom: 12px !important;
  }

  .mb-lg-4 {
    margin-bottom: 16px !important;
  }

  .mb-lg-5 {
    margin-bottom: 20px !important;
  }

  .mb-lg-6 {
    margin-bottom: 24px !important;
  }

  .mb-lg-7 {
    margin-bottom: 28px !important;
  }

  .mb-lg-8 {
    margin-bottom: 32px !important;
  }

  .mb-lg-9 {
    margin-bottom: 36px !important;
  }

  .mb-lg-10 {
    margin-bottom: 40px !important;
  }

  .mb-lg-11 {
    margin-bottom: 44px !important;
  }

  .mb-lg-12 {
    margin-bottom: 48px !important;
  }

  .mb-lg-13 {
    margin-bottom: 52px !important;
  }

  .mb-lg-14 {
    margin-bottom: 56px !important;
  }

  .mb-lg-15 {
    margin-bottom: 60px !important;
  }

  .mb-lg-16 {
    margin-bottom: 64px !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: 4px !important;
  }

  .ml-lg-2 {
    margin-left: 8px !important;
  }

  .ml-lg-3 {
    margin-left: 12px !important;
  }

  .ml-lg-4 {
    margin-left: 16px !important;
  }

  .ml-lg-5 {
    margin-left: 20px !important;
  }

  .ml-lg-6 {
    margin-left: 24px !important;
  }

  .ml-lg-7 {
    margin-left: 28px !important;
  }

  .ml-lg-8 {
    margin-left: 32px !important;
  }

  .ml-lg-9 {
    margin-left: 36px !important;
  }

  .ml-lg-10 {
    margin-left: 40px !important;
  }

  .ml-lg-11 {
    margin-left: 44px !important;
  }

  .ml-lg-12 {
    margin-left: 48px !important;
  }

  .ml-lg-13 {
    margin-left: 52px !important;
  }

  .ml-lg-14 {
    margin-left: 56px !important;
  }

  .ml-lg-15 {
    margin-left: 60px !important;
  }

  .ml-lg-16 {
    margin-left: 64px !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .ms-lg-0 {
    margin-inline-start: 0 !important;
  }

  .ms-lg-1 {
    margin-inline-start: 4px !important;
  }

  .ms-lg-2 {
    margin-inline-start: 8px !important;
  }

  .ms-lg-3 {
    margin-inline-start: 12px !important;
  }

  .ms-lg-4 {
    margin-inline-start: 16px !important;
  }

  .ms-lg-5 {
    margin-inline-start: 20px !important;
  }

  .ms-lg-6 {
    margin-inline-start: 24px !important;
  }

  .ms-lg-7 {
    margin-inline-start: 28px !important;
  }

  .ms-lg-8 {
    margin-inline-start: 32px !important;
  }

  .ms-lg-9 {
    margin-inline-start: 36px !important;
  }

  .ms-lg-10 {
    margin-inline-start: 40px !important;
  }

  .ms-lg-11 {
    margin-inline-start: 44px !important;
  }

  .ms-lg-12 {
    margin-inline-start: 48px !important;
  }

  .ms-lg-13 {
    margin-inline-start: 52px !important;
  }

  .ms-lg-14 {
    margin-inline-start: 56px !important;
  }

  .ms-lg-15 {
    margin-inline-start: 60px !important;
  }

  .ms-lg-16 {
    margin-inline-start: 64px !important;
  }

  .ms-lg-auto {
    margin-inline-start: auto !important;
  }

  .me-lg-0 {
    margin-inline-end: 0 !important;
  }

  .me-lg-1 {
    margin-inline-end: 4px !important;
  }

  .me-lg-2 {
    margin-inline-end: 8px !important;
  }

  .me-lg-3 {
    margin-inline-end: 12px !important;
  }

  .me-lg-4 {
    margin-inline-end: 16px !important;
  }

  .me-lg-5 {
    margin-inline-end: 20px !important;
  }

  .me-lg-6 {
    margin-inline-end: 24px !important;
  }

  .me-lg-7 {
    margin-inline-end: 28px !important;
  }

  .me-lg-8 {
    margin-inline-end: 32px !important;
  }

  .me-lg-9 {
    margin-inline-end: 36px !important;
  }

  .me-lg-10 {
    margin-inline-end: 40px !important;
  }

  .me-lg-11 {
    margin-inline-end: 44px !important;
  }

  .me-lg-12 {
    margin-inline-end: 48px !important;
  }

  .me-lg-13 {
    margin-inline-end: 52px !important;
  }

  .me-lg-14 {
    margin-inline-end: 56px !important;
  }

  .me-lg-15 {
    margin-inline-end: 60px !important;
  }

  .me-lg-16 {
    margin-inline-end: 64px !important;
  }

  .me-lg-auto {
    margin-inline-end: auto !important;
  }

  .ma-lg-n1 {
    margin: -4px !important;
  }

  .ma-lg-n2 {
    margin: -8px !important;
  }

  .ma-lg-n3 {
    margin: -12px !important;
  }

  .ma-lg-n4 {
    margin: -16px !important;
  }

  .ma-lg-n5 {
    margin: -20px !important;
  }

  .ma-lg-n6 {
    margin: -24px !important;
  }

  .ma-lg-n7 {
    margin: -28px !important;
  }

  .ma-lg-n8 {
    margin: -32px !important;
  }

  .ma-lg-n9 {
    margin: -36px !important;
  }

  .ma-lg-n10 {
    margin: -40px !important;
  }

  .ma-lg-n11 {
    margin: -44px !important;
  }

  .ma-lg-n12 {
    margin: -48px !important;
  }

  .ma-lg-n13 {
    margin: -52px !important;
  }

  .ma-lg-n14 {
    margin: -56px !important;
  }

  .ma-lg-n15 {
    margin: -60px !important;
  }

  .ma-lg-n16 {
    margin: -64px !important;
  }

  .mx-lg-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .mx-lg-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .mx-lg-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .mx-lg-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .mx-lg-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .mx-lg-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mx-lg-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .mx-lg-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .mx-lg-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .mx-lg-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .mx-lg-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .mx-lg-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .mx-lg-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .mx-lg-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .mx-lg-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .mx-lg-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .my-lg-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-lg-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-lg-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-lg-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-lg-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-lg-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-lg-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .my-lg-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-lg-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-lg-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-lg-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .my-lg-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-lg-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .my-lg-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .my-lg-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .my-lg-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .mt-lg-n1 {
    margin-top: -4px !important;
  }

  .mt-lg-n2 {
    margin-top: -8px !important;
  }

  .mt-lg-n3 {
    margin-top: -12px !important;
  }

  .mt-lg-n4 {
    margin-top: -16px !important;
  }

  .mt-lg-n5 {
    margin-top: -20px !important;
  }

  .mt-lg-n6 {
    margin-top: -24px !important;
  }

  .mt-lg-n7 {
    margin-top: -28px !important;
  }

  .mt-lg-n8 {
    margin-top: -32px !important;
  }

  .mt-lg-n9 {
    margin-top: -36px !important;
  }

  .mt-lg-n10 {
    margin-top: -40px !important;
  }

  .mt-lg-n11 {
    margin-top: -44px !important;
  }

  .mt-lg-n12 {
    margin-top: -48px !important;
  }

  .mt-lg-n13 {
    margin-top: -52px !important;
  }

  .mt-lg-n14 {
    margin-top: -56px !important;
  }

  .mt-lg-n15 {
    margin-top: -60px !important;
  }

  .mt-lg-n16 {
    margin-top: -64px !important;
  }

  .mr-lg-n1 {
    margin-right: -4px !important;
  }

  .mr-lg-n2 {
    margin-right: -8px !important;
  }

  .mr-lg-n3 {
    margin-right: -12px !important;
  }

  .mr-lg-n4 {
    margin-right: -16px !important;
  }

  .mr-lg-n5 {
    margin-right: -20px !important;
  }

  .mr-lg-n6 {
    margin-right: -24px !important;
  }

  .mr-lg-n7 {
    margin-right: -28px !important;
  }

  .mr-lg-n8 {
    margin-right: -32px !important;
  }

  .mr-lg-n9 {
    margin-right: -36px !important;
  }

  .mr-lg-n10 {
    margin-right: -40px !important;
  }

  .mr-lg-n11 {
    margin-right: -44px !important;
  }

  .mr-lg-n12 {
    margin-right: -48px !important;
  }

  .mr-lg-n13 {
    margin-right: -52px !important;
  }

  .mr-lg-n14 {
    margin-right: -56px !important;
  }

  .mr-lg-n15 {
    margin-right: -60px !important;
  }

  .mr-lg-n16 {
    margin-right: -64px !important;
  }

  .mb-lg-n1 {
    margin-bottom: -4px !important;
  }

  .mb-lg-n2 {
    margin-bottom: -8px !important;
  }

  .mb-lg-n3 {
    margin-bottom: -12px !important;
  }

  .mb-lg-n4 {
    margin-bottom: -16px !important;
  }

  .mb-lg-n5 {
    margin-bottom: -20px !important;
  }

  .mb-lg-n6 {
    margin-bottom: -24px !important;
  }

  .mb-lg-n7 {
    margin-bottom: -28px !important;
  }

  .mb-lg-n8 {
    margin-bottom: -32px !important;
  }

  .mb-lg-n9 {
    margin-bottom: -36px !important;
  }

  .mb-lg-n10 {
    margin-bottom: -40px !important;
  }

  .mb-lg-n11 {
    margin-bottom: -44px !important;
  }

  .mb-lg-n12 {
    margin-bottom: -48px !important;
  }

  .mb-lg-n13 {
    margin-bottom: -52px !important;
  }

  .mb-lg-n14 {
    margin-bottom: -56px !important;
  }

  .mb-lg-n15 {
    margin-bottom: -60px !important;
  }

  .mb-lg-n16 {
    margin-bottom: -64px !important;
  }

  .ml-lg-n1 {
    margin-left: -4px !important;
  }

  .ml-lg-n2 {
    margin-left: -8px !important;
  }

  .ml-lg-n3 {
    margin-left: -12px !important;
  }

  .ml-lg-n4 {
    margin-left: -16px !important;
  }

  .ml-lg-n5 {
    margin-left: -20px !important;
  }

  .ml-lg-n6 {
    margin-left: -24px !important;
  }

  .ml-lg-n7 {
    margin-left: -28px !important;
  }

  .ml-lg-n8 {
    margin-left: -32px !important;
  }

  .ml-lg-n9 {
    margin-left: -36px !important;
  }

  .ml-lg-n10 {
    margin-left: -40px !important;
  }

  .ml-lg-n11 {
    margin-left: -44px !important;
  }

  .ml-lg-n12 {
    margin-left: -48px !important;
  }

  .ml-lg-n13 {
    margin-left: -52px !important;
  }

  .ml-lg-n14 {
    margin-left: -56px !important;
  }

  .ml-lg-n15 {
    margin-left: -60px !important;
  }

  .ml-lg-n16 {
    margin-left: -64px !important;
  }

  .ms-lg-n1 {
    margin-inline-start: -4px !important;
  }

  .ms-lg-n2 {
    margin-inline-start: -8px !important;
  }

  .ms-lg-n3 {
    margin-inline-start: -12px !important;
  }

  .ms-lg-n4 {
    margin-inline-start: -16px !important;
  }

  .ms-lg-n5 {
    margin-inline-start: -20px !important;
  }

  .ms-lg-n6 {
    margin-inline-start: -24px !important;
  }

  .ms-lg-n7 {
    margin-inline-start: -28px !important;
  }

  .ms-lg-n8 {
    margin-inline-start: -32px !important;
  }

  .ms-lg-n9 {
    margin-inline-start: -36px !important;
  }

  .ms-lg-n10 {
    margin-inline-start: -40px !important;
  }

  .ms-lg-n11 {
    margin-inline-start: -44px !important;
  }

  .ms-lg-n12 {
    margin-inline-start: -48px !important;
  }

  .ms-lg-n13 {
    margin-inline-start: -52px !important;
  }

  .ms-lg-n14 {
    margin-inline-start: -56px !important;
  }

  .ms-lg-n15 {
    margin-inline-start: -60px !important;
  }

  .ms-lg-n16 {
    margin-inline-start: -64px !important;
  }

  .me-lg-n1 {
    margin-inline-end: -4px !important;
  }

  .me-lg-n2 {
    margin-inline-end: -8px !important;
  }

  .me-lg-n3 {
    margin-inline-end: -12px !important;
  }

  .me-lg-n4 {
    margin-inline-end: -16px !important;
  }

  .me-lg-n5 {
    margin-inline-end: -20px !important;
  }

  .me-lg-n6 {
    margin-inline-end: -24px !important;
  }

  .me-lg-n7 {
    margin-inline-end: -28px !important;
  }

  .me-lg-n8 {
    margin-inline-end: -32px !important;
  }

  .me-lg-n9 {
    margin-inline-end: -36px !important;
  }

  .me-lg-n10 {
    margin-inline-end: -40px !important;
  }

  .me-lg-n11 {
    margin-inline-end: -44px !important;
  }

  .me-lg-n12 {
    margin-inline-end: -48px !important;
  }

  .me-lg-n13 {
    margin-inline-end: -52px !important;
  }

  .me-lg-n14 {
    margin-inline-end: -56px !important;
  }

  .me-lg-n15 {
    margin-inline-end: -60px !important;
  }

  .me-lg-n16 {
    margin-inline-end: -64px !important;
  }

  .pa-lg-0 {
    padding: 0 !important;
  }

  .pa-lg-1 {
    padding: 4px !important;
  }

  .pa-lg-2 {
    padding: 8px !important;
  }

  .pa-lg-3 {
    padding: 12px !important;
  }

  .pa-lg-4 {
    padding: 16px !important;
  }

  .pa-lg-5 {
    padding: 20px !important;
  }

  .pa-lg-6 {
    padding: 24px !important;
  }

  .pa-lg-7 {
    padding: 28px !important;
  }

  .pa-lg-8 {
    padding: 32px !important;
  }

  .pa-lg-9 {
    padding: 36px !important;
  }

  .pa-lg-10 {
    padding: 40px !important;
  }

  .pa-lg-11 {
    padding: 44px !important;
  }

  .pa-lg-12 {
    padding: 48px !important;
  }

  .pa-lg-13 {
    padding: 52px !important;
  }

  .pa-lg-14 {
    padding: 56px !important;
  }

  .pa-lg-15 {
    padding: 60px !important;
  }

  .pa-lg-16 {
    padding: 64px !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .px-lg-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-lg-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-lg-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-lg-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-lg-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-lg-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-lg-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-lg-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .px-lg-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-lg-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-lg-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-lg-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .px-lg-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .px-lg-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-lg-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-lg-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-lg-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-lg-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-lg-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-lg-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .py-lg-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-lg-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-lg-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-lg-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .py-lg-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-lg-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .py-lg-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .py-lg-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-lg-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 4px !important;
  }

  .pt-lg-2 {
    padding-top: 8px !important;
  }

  .pt-lg-3 {
    padding-top: 12px !important;
  }

  .pt-lg-4 {
    padding-top: 16px !important;
  }

  .pt-lg-5 {
    padding-top: 20px !important;
  }

  .pt-lg-6 {
    padding-top: 24px !important;
  }

  .pt-lg-7 {
    padding-top: 28px !important;
  }

  .pt-lg-8 {
    padding-top: 32px !important;
  }

  .pt-lg-9 {
    padding-top: 36px !important;
  }

  .pt-lg-10 {
    padding-top: 40px !important;
  }

  .pt-lg-11 {
    padding-top: 44px !important;
  }

  .pt-lg-12 {
    padding-top: 48px !important;
  }

  .pt-lg-13 {
    padding-top: 52px !important;
  }

  .pt-lg-14 {
    padding-top: 56px !important;
  }

  .pt-lg-15 {
    padding-top: 60px !important;
  }

  .pt-lg-16 {
    padding-top: 64px !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pr-lg-1 {
    padding-right: 4px !important;
  }

  .pr-lg-2 {
    padding-right: 8px !important;
  }

  .pr-lg-3 {
    padding-right: 12px !important;
  }

  .pr-lg-4 {
    padding-right: 16px !important;
  }

  .pr-lg-5 {
    padding-right: 20px !important;
  }

  .pr-lg-6 {
    padding-right: 24px !important;
  }

  .pr-lg-7 {
    padding-right: 28px !important;
  }

  .pr-lg-8 {
    padding-right: 32px !important;
  }

  .pr-lg-9 {
    padding-right: 36px !important;
  }

  .pr-lg-10 {
    padding-right: 40px !important;
  }

  .pr-lg-11 {
    padding-right: 44px !important;
  }

  .pr-lg-12 {
    padding-right: 48px !important;
  }

  .pr-lg-13 {
    padding-right: 52px !important;
  }

  .pr-lg-14 {
    padding-right: 56px !important;
  }

  .pr-lg-15 {
    padding-right: 60px !important;
  }

  .pr-lg-16 {
    padding-right: 64px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 4px !important;
  }

  .pb-lg-2 {
    padding-bottom: 8px !important;
  }

  .pb-lg-3 {
    padding-bottom: 12px !important;
  }

  .pb-lg-4 {
    padding-bottom: 16px !important;
  }

  .pb-lg-5 {
    padding-bottom: 20px !important;
  }

  .pb-lg-6 {
    padding-bottom: 24px !important;
  }

  .pb-lg-7 {
    padding-bottom: 28px !important;
  }

  .pb-lg-8 {
    padding-bottom: 32px !important;
  }

  .pb-lg-9 {
    padding-bottom: 36px !important;
  }

  .pb-lg-10 {
    padding-bottom: 40px !important;
  }

  .pb-lg-11 {
    padding-bottom: 44px !important;
  }

  .pb-lg-12 {
    padding-bottom: 48px !important;
  }

  .pb-lg-13 {
    padding-bottom: 52px !important;
  }

  .pb-lg-14 {
    padding-bottom: 56px !important;
  }

  .pb-lg-15 {
    padding-bottom: 60px !important;
  }

  .pb-lg-16 {
    padding-bottom: 64px !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pl-lg-1 {
    padding-left: 4px !important;
  }

  .pl-lg-2 {
    padding-left: 8px !important;
  }

  .pl-lg-3 {
    padding-left: 12px !important;
  }

  .pl-lg-4 {
    padding-left: 16px !important;
  }

  .pl-lg-5 {
    padding-left: 20px !important;
  }

  .pl-lg-6 {
    padding-left: 24px !important;
  }

  .pl-lg-7 {
    padding-left: 28px !important;
  }

  .pl-lg-8 {
    padding-left: 32px !important;
  }

  .pl-lg-9 {
    padding-left: 36px !important;
  }

  .pl-lg-10 {
    padding-left: 40px !important;
  }

  .pl-lg-11 {
    padding-left: 44px !important;
  }

  .pl-lg-12 {
    padding-left: 48px !important;
  }

  .pl-lg-13 {
    padding-left: 52px !important;
  }

  .pl-lg-14 {
    padding-left: 56px !important;
  }

  .pl-lg-15 {
    padding-left: 60px !important;
  }

  .pl-lg-16 {
    padding-left: 64px !important;
  }

  .ps-lg-0 {
    padding-inline-start: 0 !important;
  }

  .ps-lg-1 {
    padding-inline-start: 4px !important;
  }

  .ps-lg-2 {
    padding-inline-start: 8px !important;
  }

  .ps-lg-3 {
    padding-inline-start: 12px !important;
  }

  .ps-lg-4 {
    padding-inline-start: 16px !important;
  }

  .ps-lg-5 {
    padding-inline-start: 20px !important;
  }

  .ps-lg-6 {
    padding-inline-start: 24px !important;
  }

  .ps-lg-7 {
    padding-inline-start: 28px !important;
  }

  .ps-lg-8 {
    padding-inline-start: 32px !important;
  }

  .ps-lg-9 {
    padding-inline-start: 36px !important;
  }

  .ps-lg-10 {
    padding-inline-start: 40px !important;
  }

  .ps-lg-11 {
    padding-inline-start: 44px !important;
  }

  .ps-lg-12 {
    padding-inline-start: 48px !important;
  }

  .ps-lg-13 {
    padding-inline-start: 52px !important;
  }

  .ps-lg-14 {
    padding-inline-start: 56px !important;
  }

  .ps-lg-15 {
    padding-inline-start: 60px !important;
  }

  .ps-lg-16 {
    padding-inline-start: 64px !important;
  }

  .pe-lg-0 {
    padding-inline-end: 0 !important;
  }

  .pe-lg-1 {
    padding-inline-end: 4px !important;
  }

  .pe-lg-2 {
    padding-inline-end: 8px !important;
  }

  .pe-lg-3 {
    padding-inline-end: 12px !important;
  }

  .pe-lg-4 {
    padding-inline-end: 16px !important;
  }

  .pe-lg-5 {
    padding-inline-end: 20px !important;
  }

  .pe-lg-6 {
    padding-inline-end: 24px !important;
  }

  .pe-lg-7 {
    padding-inline-end: 28px !important;
  }

  .pe-lg-8 {
    padding-inline-end: 32px !important;
  }

  .pe-lg-9 {
    padding-inline-end: 36px !important;
  }

  .pe-lg-10 {
    padding-inline-end: 40px !important;
  }

  .pe-lg-11 {
    padding-inline-end: 44px !important;
  }

  .pe-lg-12 {
    padding-inline-end: 48px !important;
  }

  .pe-lg-13 {
    padding-inline-end: 52px !important;
  }

  .pe-lg-14 {
    padding-inline-end: 56px !important;
  }

  .pe-lg-15 {
    padding-inline-end: 60px !important;
  }

  .pe-lg-16 {
    padding-inline-end: 64px !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-justify {
    text-align: justify !important;
  }

  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }

  .text-lg-h1 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.015625em !important;
    text-transform: none !important;
    font-size: 6rem !important;
  }

  .text-lg-h2 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.00833333em !important;
    text-transform: none !important;
    font-size: 3.75rem !important;
  }

  .text-lg-h3 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 3rem !important;
  }

  .text-lg-h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: .00735294em !important;
    text-transform: none !important;
    font-size: 2.125rem !important;
  }

  .text-lg-h5 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
  }

  .text-lg-h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .0125em !important;
    text-transform: none !important;
    font-size: 1.25rem !important;
  }

  .text-lg-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: .009375em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-lg-subtitle-2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .00714286em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-lg-body-1 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .03125em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-lg-body-2 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.425;
    letter-spacing: .0178571em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-lg-button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.6;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
    font-size: .875rem !important;
  }

  .text-lg-caption {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.667;
    letter-spacing: .0333333em !important;
    text-transform: none !important;
    font-size: .75rem !important;
  }

  .text-lg-overline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.667;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
    font-size: .75rem !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-screen {
    height: 100vh !important;
  }

  .h-lg-0 {
    height: 0 !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-0 {
    width: 0 !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-33 {
    width: 33% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-66 {
    width: 66% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }
}

@media (width >= 1920px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-xl-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-xl-start, .v-locale--is-ltr .float-xl-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-xl-start {
    float: left !important;
  }

  .flex-xl-fill, .flex-xl-1-1 {
    flex: auto !important;
  }

  .flex-xl-1-0 {
    flex: 1 0 auto !important;
  }

  .flex-xl-0-1 {
    flex: 0 auto !important;
  }

  .flex-xl-0-0 {
    flex: none !important;
  }

  .flex-xl-1-1-100 {
    flex: 100% !important;
  }

  .flex-xl-1-0-100 {
    flex: 1 0 100% !important;
  }

  .flex-xl-0-1-100 {
    flex: 0 100% !important;
  }

  .flex-xl-0-0-100 {
    flex: 0 0 100% !important;
  }

  .flex-xl-1-1-0 {
    flex: 1 1 0 !important;
  }

  .flex-xl-1-0-0 {
    flex: 1 0 0 !important;
  }

  .flex-xl-0-1-0 {
    flex: 0 1 0 !important;
  }

  .flex-xl-0-0-0 {
    flex: 0 0 0 !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-xl-start {
    justify-content: flex-start !important;
  }

  .justify-xl-end {
    justify-content: flex-end !important;
  }

  .justify-xl-center {
    justify-content: center !important;
  }

  .justify-xl-space-between {
    justify-content: space-between !important;
  }

  .justify-xl-space-around {
    justify-content: space-around !important;
  }

  .justify-xl-space-evenly {
    justify-content: space-evenly !important;
  }

  .align-xl-start {
    align-items: flex-start !important;
  }

  .align-xl-end {
    align-items: flex-end !important;
  }

  .align-xl-center {
    align-items: center !important;
  }

  .align-xl-baseline {
    align-items: baseline !important;
  }

  .align-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-space-between {
    align-content: space-between !important;
  }

  .align-content-xl-space-around {
    align-content: space-around !important;
  }

  .align-content-xl-space-evenly {
    align-content: space-evenly !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-6 {
    order: 6 !important;
  }

  .order-xl-7 {
    order: 7 !important;
  }

  .order-xl-8 {
    order: 8 !important;
  }

  .order-xl-9 {
    order: 9 !important;
  }

  .order-xl-10 {
    order: 10 !important;
  }

  .order-xl-11 {
    order: 11 !important;
  }

  .order-xl-12 {
    order: 12 !important;
  }

  .order-xl-last {
    order: 13 !important;
  }

  .ga-xl-0 {
    gap: 0 !important;
  }

  .ga-xl-1 {
    gap: 4px !important;
  }

  .ga-xl-2 {
    gap: 8px !important;
  }

  .ga-xl-3 {
    gap: 12px !important;
  }

  .ga-xl-4 {
    gap: 16px !important;
  }

  .ga-xl-5 {
    gap: 20px !important;
  }

  .ga-xl-6 {
    gap: 24px !important;
  }

  .ga-xl-7 {
    gap: 28px !important;
  }

  .ga-xl-8 {
    gap: 32px !important;
  }

  .ga-xl-9 {
    gap: 36px !important;
  }

  .ga-xl-10 {
    gap: 40px !important;
  }

  .ga-xl-11 {
    gap: 44px !important;
  }

  .ga-xl-12 {
    gap: 48px !important;
  }

  .ga-xl-13 {
    gap: 52px !important;
  }

  .ga-xl-14 {
    gap: 56px !important;
  }

  .ga-xl-15 {
    gap: 60px !important;
  }

  .ga-xl-16 {
    gap: 64px !important;
  }

  .ga-xl-auto {
    gap: auto !important;
  }

  .gr-xl-0 {
    row-gap: 0 !important;
  }

  .gr-xl-1 {
    row-gap: 4px !important;
  }

  .gr-xl-2 {
    row-gap: 8px !important;
  }

  .gr-xl-3 {
    row-gap: 12px !important;
  }

  .gr-xl-4 {
    row-gap: 16px !important;
  }

  .gr-xl-5 {
    row-gap: 20px !important;
  }

  .gr-xl-6 {
    row-gap: 24px !important;
  }

  .gr-xl-7 {
    row-gap: 28px !important;
  }

  .gr-xl-8 {
    row-gap: 32px !important;
  }

  .gr-xl-9 {
    row-gap: 36px !important;
  }

  .gr-xl-10 {
    row-gap: 40px !important;
  }

  .gr-xl-11 {
    row-gap: 44px !important;
  }

  .gr-xl-12 {
    row-gap: 48px !important;
  }

  .gr-xl-13 {
    row-gap: 52px !important;
  }

  .gr-xl-14 {
    row-gap: 56px !important;
  }

  .gr-xl-15 {
    row-gap: 60px !important;
  }

  .gr-xl-16 {
    row-gap: 64px !important;
  }

  .gr-xl-auto {
    row-gap: auto !important;
  }

  .gc-xl-0 {
    column-gap: 0 !important;
  }

  .gc-xl-1 {
    column-gap: 4px !important;
  }

  .gc-xl-2 {
    column-gap: 8px !important;
  }

  .gc-xl-3 {
    column-gap: 12px !important;
  }

  .gc-xl-4 {
    column-gap: 16px !important;
  }

  .gc-xl-5 {
    column-gap: 20px !important;
  }

  .gc-xl-6 {
    column-gap: 24px !important;
  }

  .gc-xl-7 {
    column-gap: 28px !important;
  }

  .gc-xl-8 {
    column-gap: 32px !important;
  }

  .gc-xl-9 {
    column-gap: 36px !important;
  }

  .gc-xl-10 {
    column-gap: 40px !important;
  }

  .gc-xl-11 {
    column-gap: 44px !important;
  }

  .gc-xl-12 {
    column-gap: 48px !important;
  }

  .gc-xl-13 {
    column-gap: 52px !important;
  }

  .gc-xl-14 {
    column-gap: 56px !important;
  }

  .gc-xl-15 {
    column-gap: 60px !important;
  }

  .gc-xl-16 {
    column-gap: 64px !important;
  }

  .gc-xl-auto {
    column-gap: auto !important;
  }

  .ma-xl-0 {
    margin: 0 !important;
  }

  .ma-xl-1 {
    margin: 4px !important;
  }

  .ma-xl-2 {
    margin: 8px !important;
  }

  .ma-xl-3 {
    margin: 12px !important;
  }

  .ma-xl-4 {
    margin: 16px !important;
  }

  .ma-xl-5 {
    margin: 20px !important;
  }

  .ma-xl-6 {
    margin: 24px !important;
  }

  .ma-xl-7 {
    margin: 28px !important;
  }

  .ma-xl-8 {
    margin: 32px !important;
  }

  .ma-xl-9 {
    margin: 36px !important;
  }

  .ma-xl-10 {
    margin: 40px !important;
  }

  .ma-xl-11 {
    margin: 44px !important;
  }

  .ma-xl-12 {
    margin: 48px !important;
  }

  .ma-xl-13 {
    margin: 52px !important;
  }

  .ma-xl-14 {
    margin: 56px !important;
  }

  .ma-xl-15 {
    margin: 60px !important;
  }

  .ma-xl-16 {
    margin: 64px !important;
  }

  .ma-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-xl-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-xl-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-xl-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-xl-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-xl-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-xl-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .mx-xl-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-xl-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .mx-xl-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-xl-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .mx-xl-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .mx-xl-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .mx-xl-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .mx-xl-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-xl-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-xl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-xl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-xl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-xl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xl-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-xl-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .my-xl-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-xl-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-xl-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xl-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .my-xl-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-xl-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .my-xl-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .my-xl-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-xl-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 4px !important;
  }

  .mt-xl-2 {
    margin-top: 8px !important;
  }

  .mt-xl-3 {
    margin-top: 12px !important;
  }

  .mt-xl-4 {
    margin-top: 16px !important;
  }

  .mt-xl-5 {
    margin-top: 20px !important;
  }

  .mt-xl-6 {
    margin-top: 24px !important;
  }

  .mt-xl-7 {
    margin-top: 28px !important;
  }

  .mt-xl-8 {
    margin-top: 32px !important;
  }

  .mt-xl-9 {
    margin-top: 36px !important;
  }

  .mt-xl-10 {
    margin-top: 40px !important;
  }

  .mt-xl-11 {
    margin-top: 44px !important;
  }

  .mt-xl-12 {
    margin-top: 48px !important;
  }

  .mt-xl-13 {
    margin-top: 52px !important;
  }

  .mt-xl-14 {
    margin-top: 56px !important;
  }

  .mt-xl-15 {
    margin-top: 60px !important;
  }

  .mt-xl-16 {
    margin-top: 64px !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: 4px !important;
  }

  .mr-xl-2 {
    margin-right: 8px !important;
  }

  .mr-xl-3 {
    margin-right: 12px !important;
  }

  .mr-xl-4 {
    margin-right: 16px !important;
  }

  .mr-xl-5 {
    margin-right: 20px !important;
  }

  .mr-xl-6 {
    margin-right: 24px !important;
  }

  .mr-xl-7 {
    margin-right: 28px !important;
  }

  .mr-xl-8 {
    margin-right: 32px !important;
  }

  .mr-xl-9 {
    margin-right: 36px !important;
  }

  .mr-xl-10 {
    margin-right: 40px !important;
  }

  .mr-xl-11 {
    margin-right: 44px !important;
  }

  .mr-xl-12 {
    margin-right: 48px !important;
  }

  .mr-xl-13 {
    margin-right: 52px !important;
  }

  .mr-xl-14 {
    margin-right: 56px !important;
  }

  .mr-xl-15 {
    margin-right: 60px !important;
  }

  .mr-xl-16 {
    margin-right: 64px !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 4px !important;
  }

  .mb-xl-2 {
    margin-bottom: 8px !important;
  }

  .mb-xl-3 {
    margin-bottom: 12px !important;
  }

  .mb-xl-4 {
    margin-bottom: 16px !important;
  }

  .mb-xl-5 {
    margin-bottom: 20px !important;
  }

  .mb-xl-6 {
    margin-bottom: 24px !important;
  }

  .mb-xl-7 {
    margin-bottom: 28px !important;
  }

  .mb-xl-8 {
    margin-bottom: 32px !important;
  }

  .mb-xl-9 {
    margin-bottom: 36px !important;
  }

  .mb-xl-10 {
    margin-bottom: 40px !important;
  }

  .mb-xl-11 {
    margin-bottom: 44px !important;
  }

  .mb-xl-12 {
    margin-bottom: 48px !important;
  }

  .mb-xl-13 {
    margin-bottom: 52px !important;
  }

  .mb-xl-14 {
    margin-bottom: 56px !important;
  }

  .mb-xl-15 {
    margin-bottom: 60px !important;
  }

  .mb-xl-16 {
    margin-bottom: 64px !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: 4px !important;
  }

  .ml-xl-2 {
    margin-left: 8px !important;
  }

  .ml-xl-3 {
    margin-left: 12px !important;
  }

  .ml-xl-4 {
    margin-left: 16px !important;
  }

  .ml-xl-5 {
    margin-left: 20px !important;
  }

  .ml-xl-6 {
    margin-left: 24px !important;
  }

  .ml-xl-7 {
    margin-left: 28px !important;
  }

  .ml-xl-8 {
    margin-left: 32px !important;
  }

  .ml-xl-9 {
    margin-left: 36px !important;
  }

  .ml-xl-10 {
    margin-left: 40px !important;
  }

  .ml-xl-11 {
    margin-left: 44px !important;
  }

  .ml-xl-12 {
    margin-left: 48px !important;
  }

  .ml-xl-13 {
    margin-left: 52px !important;
  }

  .ml-xl-14 {
    margin-left: 56px !important;
  }

  .ml-xl-15 {
    margin-left: 60px !important;
  }

  .ml-xl-16 {
    margin-left: 64px !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .ms-xl-0 {
    margin-inline-start: 0 !important;
  }

  .ms-xl-1 {
    margin-inline-start: 4px !important;
  }

  .ms-xl-2 {
    margin-inline-start: 8px !important;
  }

  .ms-xl-3 {
    margin-inline-start: 12px !important;
  }

  .ms-xl-4 {
    margin-inline-start: 16px !important;
  }

  .ms-xl-5 {
    margin-inline-start: 20px !important;
  }

  .ms-xl-6 {
    margin-inline-start: 24px !important;
  }

  .ms-xl-7 {
    margin-inline-start: 28px !important;
  }

  .ms-xl-8 {
    margin-inline-start: 32px !important;
  }

  .ms-xl-9 {
    margin-inline-start: 36px !important;
  }

  .ms-xl-10 {
    margin-inline-start: 40px !important;
  }

  .ms-xl-11 {
    margin-inline-start: 44px !important;
  }

  .ms-xl-12 {
    margin-inline-start: 48px !important;
  }

  .ms-xl-13 {
    margin-inline-start: 52px !important;
  }

  .ms-xl-14 {
    margin-inline-start: 56px !important;
  }

  .ms-xl-15 {
    margin-inline-start: 60px !important;
  }

  .ms-xl-16 {
    margin-inline-start: 64px !important;
  }

  .ms-xl-auto {
    margin-inline-start: auto !important;
  }

  .me-xl-0 {
    margin-inline-end: 0 !important;
  }

  .me-xl-1 {
    margin-inline-end: 4px !important;
  }

  .me-xl-2 {
    margin-inline-end: 8px !important;
  }

  .me-xl-3 {
    margin-inline-end: 12px !important;
  }

  .me-xl-4 {
    margin-inline-end: 16px !important;
  }

  .me-xl-5 {
    margin-inline-end: 20px !important;
  }

  .me-xl-6 {
    margin-inline-end: 24px !important;
  }

  .me-xl-7 {
    margin-inline-end: 28px !important;
  }

  .me-xl-8 {
    margin-inline-end: 32px !important;
  }

  .me-xl-9 {
    margin-inline-end: 36px !important;
  }

  .me-xl-10 {
    margin-inline-end: 40px !important;
  }

  .me-xl-11 {
    margin-inline-end: 44px !important;
  }

  .me-xl-12 {
    margin-inline-end: 48px !important;
  }

  .me-xl-13 {
    margin-inline-end: 52px !important;
  }

  .me-xl-14 {
    margin-inline-end: 56px !important;
  }

  .me-xl-15 {
    margin-inline-end: 60px !important;
  }

  .me-xl-16 {
    margin-inline-end: 64px !important;
  }

  .me-xl-auto {
    margin-inline-end: auto !important;
  }

  .ma-xl-n1 {
    margin: -4px !important;
  }

  .ma-xl-n2 {
    margin: -8px !important;
  }

  .ma-xl-n3 {
    margin: -12px !important;
  }

  .ma-xl-n4 {
    margin: -16px !important;
  }

  .ma-xl-n5 {
    margin: -20px !important;
  }

  .ma-xl-n6 {
    margin: -24px !important;
  }

  .ma-xl-n7 {
    margin: -28px !important;
  }

  .ma-xl-n8 {
    margin: -32px !important;
  }

  .ma-xl-n9 {
    margin: -36px !important;
  }

  .ma-xl-n10 {
    margin: -40px !important;
  }

  .ma-xl-n11 {
    margin: -44px !important;
  }

  .ma-xl-n12 {
    margin: -48px !important;
  }

  .ma-xl-n13 {
    margin: -52px !important;
  }

  .ma-xl-n14 {
    margin: -56px !important;
  }

  .ma-xl-n15 {
    margin: -60px !important;
  }

  .ma-xl-n16 {
    margin: -64px !important;
  }

  .mx-xl-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .mx-xl-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .mx-xl-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .mx-xl-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .mx-xl-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .mx-xl-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mx-xl-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .mx-xl-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .mx-xl-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .mx-xl-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .mx-xl-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .mx-xl-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .mx-xl-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .mx-xl-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .mx-xl-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .mx-xl-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .my-xl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-xl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-xl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-xl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-xl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-xl-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-xl-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .my-xl-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-xl-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-xl-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-xl-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .my-xl-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-xl-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .my-xl-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .my-xl-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .my-xl-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .mt-xl-n1 {
    margin-top: -4px !important;
  }

  .mt-xl-n2 {
    margin-top: -8px !important;
  }

  .mt-xl-n3 {
    margin-top: -12px !important;
  }

  .mt-xl-n4 {
    margin-top: -16px !important;
  }

  .mt-xl-n5 {
    margin-top: -20px !important;
  }

  .mt-xl-n6 {
    margin-top: -24px !important;
  }

  .mt-xl-n7 {
    margin-top: -28px !important;
  }

  .mt-xl-n8 {
    margin-top: -32px !important;
  }

  .mt-xl-n9 {
    margin-top: -36px !important;
  }

  .mt-xl-n10 {
    margin-top: -40px !important;
  }

  .mt-xl-n11 {
    margin-top: -44px !important;
  }

  .mt-xl-n12 {
    margin-top: -48px !important;
  }

  .mt-xl-n13 {
    margin-top: -52px !important;
  }

  .mt-xl-n14 {
    margin-top: -56px !important;
  }

  .mt-xl-n15 {
    margin-top: -60px !important;
  }

  .mt-xl-n16 {
    margin-top: -64px !important;
  }

  .mr-xl-n1 {
    margin-right: -4px !important;
  }

  .mr-xl-n2 {
    margin-right: -8px !important;
  }

  .mr-xl-n3 {
    margin-right: -12px !important;
  }

  .mr-xl-n4 {
    margin-right: -16px !important;
  }

  .mr-xl-n5 {
    margin-right: -20px !important;
  }

  .mr-xl-n6 {
    margin-right: -24px !important;
  }

  .mr-xl-n7 {
    margin-right: -28px !important;
  }

  .mr-xl-n8 {
    margin-right: -32px !important;
  }

  .mr-xl-n9 {
    margin-right: -36px !important;
  }

  .mr-xl-n10 {
    margin-right: -40px !important;
  }

  .mr-xl-n11 {
    margin-right: -44px !important;
  }

  .mr-xl-n12 {
    margin-right: -48px !important;
  }

  .mr-xl-n13 {
    margin-right: -52px !important;
  }

  .mr-xl-n14 {
    margin-right: -56px !important;
  }

  .mr-xl-n15 {
    margin-right: -60px !important;
  }

  .mr-xl-n16 {
    margin-right: -64px !important;
  }

  .mb-xl-n1 {
    margin-bottom: -4px !important;
  }

  .mb-xl-n2 {
    margin-bottom: -8px !important;
  }

  .mb-xl-n3 {
    margin-bottom: -12px !important;
  }

  .mb-xl-n4 {
    margin-bottom: -16px !important;
  }

  .mb-xl-n5 {
    margin-bottom: -20px !important;
  }

  .mb-xl-n6 {
    margin-bottom: -24px !important;
  }

  .mb-xl-n7 {
    margin-bottom: -28px !important;
  }

  .mb-xl-n8 {
    margin-bottom: -32px !important;
  }

  .mb-xl-n9 {
    margin-bottom: -36px !important;
  }

  .mb-xl-n10 {
    margin-bottom: -40px !important;
  }

  .mb-xl-n11 {
    margin-bottom: -44px !important;
  }

  .mb-xl-n12 {
    margin-bottom: -48px !important;
  }

  .mb-xl-n13 {
    margin-bottom: -52px !important;
  }

  .mb-xl-n14 {
    margin-bottom: -56px !important;
  }

  .mb-xl-n15 {
    margin-bottom: -60px !important;
  }

  .mb-xl-n16 {
    margin-bottom: -64px !important;
  }

  .ml-xl-n1 {
    margin-left: -4px !important;
  }

  .ml-xl-n2 {
    margin-left: -8px !important;
  }

  .ml-xl-n3 {
    margin-left: -12px !important;
  }

  .ml-xl-n4 {
    margin-left: -16px !important;
  }

  .ml-xl-n5 {
    margin-left: -20px !important;
  }

  .ml-xl-n6 {
    margin-left: -24px !important;
  }

  .ml-xl-n7 {
    margin-left: -28px !important;
  }

  .ml-xl-n8 {
    margin-left: -32px !important;
  }

  .ml-xl-n9 {
    margin-left: -36px !important;
  }

  .ml-xl-n10 {
    margin-left: -40px !important;
  }

  .ml-xl-n11 {
    margin-left: -44px !important;
  }

  .ml-xl-n12 {
    margin-left: -48px !important;
  }

  .ml-xl-n13 {
    margin-left: -52px !important;
  }

  .ml-xl-n14 {
    margin-left: -56px !important;
  }

  .ml-xl-n15 {
    margin-left: -60px !important;
  }

  .ml-xl-n16 {
    margin-left: -64px !important;
  }

  .ms-xl-n1 {
    margin-inline-start: -4px !important;
  }

  .ms-xl-n2 {
    margin-inline-start: -8px !important;
  }

  .ms-xl-n3 {
    margin-inline-start: -12px !important;
  }

  .ms-xl-n4 {
    margin-inline-start: -16px !important;
  }

  .ms-xl-n5 {
    margin-inline-start: -20px !important;
  }

  .ms-xl-n6 {
    margin-inline-start: -24px !important;
  }

  .ms-xl-n7 {
    margin-inline-start: -28px !important;
  }

  .ms-xl-n8 {
    margin-inline-start: -32px !important;
  }

  .ms-xl-n9 {
    margin-inline-start: -36px !important;
  }

  .ms-xl-n10 {
    margin-inline-start: -40px !important;
  }

  .ms-xl-n11 {
    margin-inline-start: -44px !important;
  }

  .ms-xl-n12 {
    margin-inline-start: -48px !important;
  }

  .ms-xl-n13 {
    margin-inline-start: -52px !important;
  }

  .ms-xl-n14 {
    margin-inline-start: -56px !important;
  }

  .ms-xl-n15 {
    margin-inline-start: -60px !important;
  }

  .ms-xl-n16 {
    margin-inline-start: -64px !important;
  }

  .me-xl-n1 {
    margin-inline-end: -4px !important;
  }

  .me-xl-n2 {
    margin-inline-end: -8px !important;
  }

  .me-xl-n3 {
    margin-inline-end: -12px !important;
  }

  .me-xl-n4 {
    margin-inline-end: -16px !important;
  }

  .me-xl-n5 {
    margin-inline-end: -20px !important;
  }

  .me-xl-n6 {
    margin-inline-end: -24px !important;
  }

  .me-xl-n7 {
    margin-inline-end: -28px !important;
  }

  .me-xl-n8 {
    margin-inline-end: -32px !important;
  }

  .me-xl-n9 {
    margin-inline-end: -36px !important;
  }

  .me-xl-n10 {
    margin-inline-end: -40px !important;
  }

  .me-xl-n11 {
    margin-inline-end: -44px !important;
  }

  .me-xl-n12 {
    margin-inline-end: -48px !important;
  }

  .me-xl-n13 {
    margin-inline-end: -52px !important;
  }

  .me-xl-n14 {
    margin-inline-end: -56px !important;
  }

  .me-xl-n15 {
    margin-inline-end: -60px !important;
  }

  .me-xl-n16 {
    margin-inline-end: -64px !important;
  }

  .pa-xl-0 {
    padding: 0 !important;
  }

  .pa-xl-1 {
    padding: 4px !important;
  }

  .pa-xl-2 {
    padding: 8px !important;
  }

  .pa-xl-3 {
    padding: 12px !important;
  }

  .pa-xl-4 {
    padding: 16px !important;
  }

  .pa-xl-5 {
    padding: 20px !important;
  }

  .pa-xl-6 {
    padding: 24px !important;
  }

  .pa-xl-7 {
    padding: 28px !important;
  }

  .pa-xl-8 {
    padding: 32px !important;
  }

  .pa-xl-9 {
    padding: 36px !important;
  }

  .pa-xl-10 {
    padding: 40px !important;
  }

  .pa-xl-11 {
    padding: 44px !important;
  }

  .pa-xl-12 {
    padding: 48px !important;
  }

  .pa-xl-13 {
    padding: 52px !important;
  }

  .pa-xl-14 {
    padding: 56px !important;
  }

  .pa-xl-15 {
    padding: 60px !important;
  }

  .pa-xl-16 {
    padding: 64px !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .px-xl-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-xl-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-xl-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-xl-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-xl-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-xl-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-xl-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-xl-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .px-xl-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-xl-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-xl-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-xl-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .px-xl-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .px-xl-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-xl-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-xl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-xl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-xl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-xl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xl-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-xl-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .py-xl-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-xl-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-xl-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xl-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .py-xl-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-xl-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .py-xl-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .py-xl-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-xl-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 4px !important;
  }

  .pt-xl-2 {
    padding-top: 8px !important;
  }

  .pt-xl-3 {
    padding-top: 12px !important;
  }

  .pt-xl-4 {
    padding-top: 16px !important;
  }

  .pt-xl-5 {
    padding-top: 20px !important;
  }

  .pt-xl-6 {
    padding-top: 24px !important;
  }

  .pt-xl-7 {
    padding-top: 28px !important;
  }

  .pt-xl-8 {
    padding-top: 32px !important;
  }

  .pt-xl-9 {
    padding-top: 36px !important;
  }

  .pt-xl-10 {
    padding-top: 40px !important;
  }

  .pt-xl-11 {
    padding-top: 44px !important;
  }

  .pt-xl-12 {
    padding-top: 48px !important;
  }

  .pt-xl-13 {
    padding-top: 52px !important;
  }

  .pt-xl-14 {
    padding-top: 56px !important;
  }

  .pt-xl-15 {
    padding-top: 60px !important;
  }

  .pt-xl-16 {
    padding-top: 64px !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pr-xl-1 {
    padding-right: 4px !important;
  }

  .pr-xl-2 {
    padding-right: 8px !important;
  }

  .pr-xl-3 {
    padding-right: 12px !important;
  }

  .pr-xl-4 {
    padding-right: 16px !important;
  }

  .pr-xl-5 {
    padding-right: 20px !important;
  }

  .pr-xl-6 {
    padding-right: 24px !important;
  }

  .pr-xl-7 {
    padding-right: 28px !important;
  }

  .pr-xl-8 {
    padding-right: 32px !important;
  }

  .pr-xl-9 {
    padding-right: 36px !important;
  }

  .pr-xl-10 {
    padding-right: 40px !important;
  }

  .pr-xl-11 {
    padding-right: 44px !important;
  }

  .pr-xl-12 {
    padding-right: 48px !important;
  }

  .pr-xl-13 {
    padding-right: 52px !important;
  }

  .pr-xl-14 {
    padding-right: 56px !important;
  }

  .pr-xl-15 {
    padding-right: 60px !important;
  }

  .pr-xl-16 {
    padding-right: 64px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 4px !important;
  }

  .pb-xl-2 {
    padding-bottom: 8px !important;
  }

  .pb-xl-3 {
    padding-bottom: 12px !important;
  }

  .pb-xl-4 {
    padding-bottom: 16px !important;
  }

  .pb-xl-5 {
    padding-bottom: 20px !important;
  }

  .pb-xl-6 {
    padding-bottom: 24px !important;
  }

  .pb-xl-7 {
    padding-bottom: 28px !important;
  }

  .pb-xl-8 {
    padding-bottom: 32px !important;
  }

  .pb-xl-9 {
    padding-bottom: 36px !important;
  }

  .pb-xl-10 {
    padding-bottom: 40px !important;
  }

  .pb-xl-11 {
    padding-bottom: 44px !important;
  }

  .pb-xl-12 {
    padding-bottom: 48px !important;
  }

  .pb-xl-13 {
    padding-bottom: 52px !important;
  }

  .pb-xl-14 {
    padding-bottom: 56px !important;
  }

  .pb-xl-15 {
    padding-bottom: 60px !important;
  }

  .pb-xl-16 {
    padding-bottom: 64px !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .pl-xl-1 {
    padding-left: 4px !important;
  }

  .pl-xl-2 {
    padding-left: 8px !important;
  }

  .pl-xl-3 {
    padding-left: 12px !important;
  }

  .pl-xl-4 {
    padding-left: 16px !important;
  }

  .pl-xl-5 {
    padding-left: 20px !important;
  }

  .pl-xl-6 {
    padding-left: 24px !important;
  }

  .pl-xl-7 {
    padding-left: 28px !important;
  }

  .pl-xl-8 {
    padding-left: 32px !important;
  }

  .pl-xl-9 {
    padding-left: 36px !important;
  }

  .pl-xl-10 {
    padding-left: 40px !important;
  }

  .pl-xl-11 {
    padding-left: 44px !important;
  }

  .pl-xl-12 {
    padding-left: 48px !important;
  }

  .pl-xl-13 {
    padding-left: 52px !important;
  }

  .pl-xl-14 {
    padding-left: 56px !important;
  }

  .pl-xl-15 {
    padding-left: 60px !important;
  }

  .pl-xl-16 {
    padding-left: 64px !important;
  }

  .ps-xl-0 {
    padding-inline-start: 0 !important;
  }

  .ps-xl-1 {
    padding-inline-start: 4px !important;
  }

  .ps-xl-2 {
    padding-inline-start: 8px !important;
  }

  .ps-xl-3 {
    padding-inline-start: 12px !important;
  }

  .ps-xl-4 {
    padding-inline-start: 16px !important;
  }

  .ps-xl-5 {
    padding-inline-start: 20px !important;
  }

  .ps-xl-6 {
    padding-inline-start: 24px !important;
  }

  .ps-xl-7 {
    padding-inline-start: 28px !important;
  }

  .ps-xl-8 {
    padding-inline-start: 32px !important;
  }

  .ps-xl-9 {
    padding-inline-start: 36px !important;
  }

  .ps-xl-10 {
    padding-inline-start: 40px !important;
  }

  .ps-xl-11 {
    padding-inline-start: 44px !important;
  }

  .ps-xl-12 {
    padding-inline-start: 48px !important;
  }

  .ps-xl-13 {
    padding-inline-start: 52px !important;
  }

  .ps-xl-14 {
    padding-inline-start: 56px !important;
  }

  .ps-xl-15 {
    padding-inline-start: 60px !important;
  }

  .ps-xl-16 {
    padding-inline-start: 64px !important;
  }

  .pe-xl-0 {
    padding-inline-end: 0 !important;
  }

  .pe-xl-1 {
    padding-inline-end: 4px !important;
  }

  .pe-xl-2 {
    padding-inline-end: 8px !important;
  }

  .pe-xl-3 {
    padding-inline-end: 12px !important;
  }

  .pe-xl-4 {
    padding-inline-end: 16px !important;
  }

  .pe-xl-5 {
    padding-inline-end: 20px !important;
  }

  .pe-xl-6 {
    padding-inline-end: 24px !important;
  }

  .pe-xl-7 {
    padding-inline-end: 28px !important;
  }

  .pe-xl-8 {
    padding-inline-end: 32px !important;
  }

  .pe-xl-9 {
    padding-inline-end: 36px !important;
  }

  .pe-xl-10 {
    padding-inline-end: 40px !important;
  }

  .pe-xl-11 {
    padding-inline-end: 44px !important;
  }

  .pe-xl-12 {
    padding-inline-end: 48px !important;
  }

  .pe-xl-13 {
    padding-inline-end: 52px !important;
  }

  .pe-xl-14 {
    padding-inline-end: 56px !important;
  }

  .pe-xl-15 {
    padding-inline-end: 60px !important;
  }

  .pe-xl-16 {
    padding-inline-end: 64px !important;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-justify {
    text-align: justify !important;
  }

  .text-xl-start {
    text-align: start !important;
  }

  .text-xl-end {
    text-align: end !important;
  }

  .text-xl-h1 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.015625em !important;
    text-transform: none !important;
    font-size: 6rem !important;
  }

  .text-xl-h2 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.00833333em !important;
    text-transform: none !important;
    font-size: 3.75rem !important;
  }

  .text-xl-h3 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 3rem !important;
  }

  .text-xl-h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: .00735294em !important;
    text-transform: none !important;
    font-size: 2.125rem !important;
  }

  .text-xl-h5 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
  }

  .text-xl-h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .0125em !important;
    text-transform: none !important;
    font-size: 1.25rem !important;
  }

  .text-xl-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: .009375em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-xl-subtitle-2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .00714286em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-xl-body-1 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .03125em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-xl-body-2 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.425;
    letter-spacing: .0178571em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-xl-button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.6;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
    font-size: .875rem !important;
  }

  .text-xl-caption {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.667;
    letter-spacing: .0333333em !important;
    text-transform: none !important;
    font-size: .75rem !important;
  }

  .text-xl-overline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.667;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
    font-size: .75rem !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-screen {
    height: 100vh !important;
  }

  .h-xl-0 {
    height: 0 !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-0 {
    width: 0 !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-33 {
    width: 33% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-66 {
    width: 66% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}

@media (width >= 2560px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-xxl-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-xxl-start, .v-locale--is-ltr .float-xxl-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-xxl-start {
    float: left !important;
  }

  .flex-xxl-fill, .flex-xxl-1-1 {
    flex: auto !important;
  }

  .flex-xxl-1-0 {
    flex: 1 0 auto !important;
  }

  .flex-xxl-0-1 {
    flex: 0 auto !important;
  }

  .flex-xxl-0-0 {
    flex: none !important;
  }

  .flex-xxl-1-1-100 {
    flex: 100% !important;
  }

  .flex-xxl-1-0-100 {
    flex: 1 0 100% !important;
  }

  .flex-xxl-0-1-100 {
    flex: 0 100% !important;
  }

  .flex-xxl-0-0-100 {
    flex: 0 0 100% !important;
  }

  .flex-xxl-1-1-0 {
    flex: 1 1 0 !important;
  }

  .flex-xxl-1-0-0 {
    flex: 1 0 0 !important;
  }

  .flex-xxl-0-1-0 {
    flex: 0 1 0 !important;
  }

  .flex-xxl-0-0-0 {
    flex: 0 0 0 !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-xxl-center {
    justify-content: center !important;
  }

  .justify-xxl-space-between {
    justify-content: space-between !important;
  }

  .justify-xxl-space-around {
    justify-content: space-around !important;
  }

  .justify-xxl-space-evenly {
    justify-content: space-evenly !important;
  }

  .align-xxl-start {
    align-items: flex-start !important;
  }

  .align-xxl-end {
    align-items: flex-end !important;
  }

  .align-xxl-center {
    align-items: center !important;
  }

  .align-xxl-baseline {
    align-items: baseline !important;
  }

  .align-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-space-between {
    align-content: space-between !important;
  }

  .align-content-xxl-space-around {
    align-content: space-around !important;
  }

  .align-content-xxl-space-evenly {
    align-content: space-evenly !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-6 {
    order: 6 !important;
  }

  .order-xxl-7 {
    order: 7 !important;
  }

  .order-xxl-8 {
    order: 8 !important;
  }

  .order-xxl-9 {
    order: 9 !important;
  }

  .order-xxl-10 {
    order: 10 !important;
  }

  .order-xxl-11 {
    order: 11 !important;
  }

  .order-xxl-12 {
    order: 12 !important;
  }

  .order-xxl-last {
    order: 13 !important;
  }

  .ga-xxl-0 {
    gap: 0 !important;
  }

  .ga-xxl-1 {
    gap: 4px !important;
  }

  .ga-xxl-2 {
    gap: 8px !important;
  }

  .ga-xxl-3 {
    gap: 12px !important;
  }

  .ga-xxl-4 {
    gap: 16px !important;
  }

  .ga-xxl-5 {
    gap: 20px !important;
  }

  .ga-xxl-6 {
    gap: 24px !important;
  }

  .ga-xxl-7 {
    gap: 28px !important;
  }

  .ga-xxl-8 {
    gap: 32px !important;
  }

  .ga-xxl-9 {
    gap: 36px !important;
  }

  .ga-xxl-10 {
    gap: 40px !important;
  }

  .ga-xxl-11 {
    gap: 44px !important;
  }

  .ga-xxl-12 {
    gap: 48px !important;
  }

  .ga-xxl-13 {
    gap: 52px !important;
  }

  .ga-xxl-14 {
    gap: 56px !important;
  }

  .ga-xxl-15 {
    gap: 60px !important;
  }

  .ga-xxl-16 {
    gap: 64px !important;
  }

  .ga-xxl-auto {
    gap: auto !important;
  }

  .gr-xxl-0 {
    row-gap: 0 !important;
  }

  .gr-xxl-1 {
    row-gap: 4px !important;
  }

  .gr-xxl-2 {
    row-gap: 8px !important;
  }

  .gr-xxl-3 {
    row-gap: 12px !important;
  }

  .gr-xxl-4 {
    row-gap: 16px !important;
  }

  .gr-xxl-5 {
    row-gap: 20px !important;
  }

  .gr-xxl-6 {
    row-gap: 24px !important;
  }

  .gr-xxl-7 {
    row-gap: 28px !important;
  }

  .gr-xxl-8 {
    row-gap: 32px !important;
  }

  .gr-xxl-9 {
    row-gap: 36px !important;
  }

  .gr-xxl-10 {
    row-gap: 40px !important;
  }

  .gr-xxl-11 {
    row-gap: 44px !important;
  }

  .gr-xxl-12 {
    row-gap: 48px !important;
  }

  .gr-xxl-13 {
    row-gap: 52px !important;
  }

  .gr-xxl-14 {
    row-gap: 56px !important;
  }

  .gr-xxl-15 {
    row-gap: 60px !important;
  }

  .gr-xxl-16 {
    row-gap: 64px !important;
  }

  .gr-xxl-auto {
    row-gap: auto !important;
  }

  .gc-xxl-0 {
    column-gap: 0 !important;
  }

  .gc-xxl-1 {
    column-gap: 4px !important;
  }

  .gc-xxl-2 {
    column-gap: 8px !important;
  }

  .gc-xxl-3 {
    column-gap: 12px !important;
  }

  .gc-xxl-4 {
    column-gap: 16px !important;
  }

  .gc-xxl-5 {
    column-gap: 20px !important;
  }

  .gc-xxl-6 {
    column-gap: 24px !important;
  }

  .gc-xxl-7 {
    column-gap: 28px !important;
  }

  .gc-xxl-8 {
    column-gap: 32px !important;
  }

  .gc-xxl-9 {
    column-gap: 36px !important;
  }

  .gc-xxl-10 {
    column-gap: 40px !important;
  }

  .gc-xxl-11 {
    column-gap: 44px !important;
  }

  .gc-xxl-12 {
    column-gap: 48px !important;
  }

  .gc-xxl-13 {
    column-gap: 52px !important;
  }

  .gc-xxl-14 {
    column-gap: 56px !important;
  }

  .gc-xxl-15 {
    column-gap: 60px !important;
  }

  .gc-xxl-16 {
    column-gap: 64px !important;
  }

  .gc-xxl-auto {
    column-gap: auto !important;
  }

  .ma-xxl-0 {
    margin: 0 !important;
  }

  .ma-xxl-1 {
    margin: 4px !important;
  }

  .ma-xxl-2 {
    margin: 8px !important;
  }

  .ma-xxl-3 {
    margin: 12px !important;
  }

  .ma-xxl-4 {
    margin: 16px !important;
  }

  .ma-xxl-5 {
    margin: 20px !important;
  }

  .ma-xxl-6 {
    margin: 24px !important;
  }

  .ma-xxl-7 {
    margin: 28px !important;
  }

  .ma-xxl-8 {
    margin: 32px !important;
  }

  .ma-xxl-9 {
    margin: 36px !important;
  }

  .ma-xxl-10 {
    margin: 40px !important;
  }

  .ma-xxl-11 {
    margin: 44px !important;
  }

  .ma-xxl-12 {
    margin: 48px !important;
  }

  .ma-xxl-13 {
    margin: 52px !important;
  }

  .ma-xxl-14 {
    margin: 56px !important;
  }

  .ma-xxl-15 {
    margin: 60px !important;
  }

  .ma-xxl-16 {
    margin: 64px !important;
  }

  .ma-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-xxl-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-xxl-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-xxl-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-xxl-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-xxl-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-xxl-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .mx-xxl-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-xxl-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .mx-xxl-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-xxl-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .mx-xxl-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .mx-xxl-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .mx-xxl-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .mx-xxl-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-xxl-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-xxl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-xxl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .my-xxl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-xxl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xxl-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-xxl-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .my-xxl-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .my-xxl-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .my-xxl-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xxl-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .my-xxl-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-xxl-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .my-xxl-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .my-xxl-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-xxl-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 4px !important;
  }

  .mt-xxl-2 {
    margin-top: 8px !important;
  }

  .mt-xxl-3 {
    margin-top: 12px !important;
  }

  .mt-xxl-4 {
    margin-top: 16px !important;
  }

  .mt-xxl-5 {
    margin-top: 20px !important;
  }

  .mt-xxl-6 {
    margin-top: 24px !important;
  }

  .mt-xxl-7 {
    margin-top: 28px !important;
  }

  .mt-xxl-8 {
    margin-top: 32px !important;
  }

  .mt-xxl-9 {
    margin-top: 36px !important;
  }

  .mt-xxl-10 {
    margin-top: 40px !important;
  }

  .mt-xxl-11 {
    margin-top: 44px !important;
  }

  .mt-xxl-12 {
    margin-top: 48px !important;
  }

  .mt-xxl-13 {
    margin-top: 52px !important;
  }

  .mt-xxl-14 {
    margin-top: 56px !important;
  }

  .mt-xxl-15 {
    margin-top: 60px !important;
  }

  .mt-xxl-16 {
    margin-top: 64px !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mr-xxl-1 {
    margin-right: 4px !important;
  }

  .mr-xxl-2 {
    margin-right: 8px !important;
  }

  .mr-xxl-3 {
    margin-right: 12px !important;
  }

  .mr-xxl-4 {
    margin-right: 16px !important;
  }

  .mr-xxl-5 {
    margin-right: 20px !important;
  }

  .mr-xxl-6 {
    margin-right: 24px !important;
  }

  .mr-xxl-7 {
    margin-right: 28px !important;
  }

  .mr-xxl-8 {
    margin-right: 32px !important;
  }

  .mr-xxl-9 {
    margin-right: 36px !important;
  }

  .mr-xxl-10 {
    margin-right: 40px !important;
  }

  .mr-xxl-11 {
    margin-right: 44px !important;
  }

  .mr-xxl-12 {
    margin-right: 48px !important;
  }

  .mr-xxl-13 {
    margin-right: 52px !important;
  }

  .mr-xxl-14 {
    margin-right: 56px !important;
  }

  .mr-xxl-15 {
    margin-right: 60px !important;
  }

  .mr-xxl-16 {
    margin-right: 64px !important;
  }

  .mr-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 4px !important;
  }

  .mb-xxl-2 {
    margin-bottom: 8px !important;
  }

  .mb-xxl-3 {
    margin-bottom: 12px !important;
  }

  .mb-xxl-4 {
    margin-bottom: 16px !important;
  }

  .mb-xxl-5 {
    margin-bottom: 20px !important;
  }

  .mb-xxl-6 {
    margin-bottom: 24px !important;
  }

  .mb-xxl-7 {
    margin-bottom: 28px !important;
  }

  .mb-xxl-8 {
    margin-bottom: 32px !important;
  }

  .mb-xxl-9 {
    margin-bottom: 36px !important;
  }

  .mb-xxl-10 {
    margin-bottom: 40px !important;
  }

  .mb-xxl-11 {
    margin-bottom: 44px !important;
  }

  .mb-xxl-12 {
    margin-bottom: 48px !important;
  }

  .mb-xxl-13 {
    margin-bottom: 52px !important;
  }

  .mb-xxl-14 {
    margin-bottom: 56px !important;
  }

  .mb-xxl-15 {
    margin-bottom: 60px !important;
  }

  .mb-xxl-16 {
    margin-bottom: 64px !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .ml-xxl-1 {
    margin-left: 4px !important;
  }

  .ml-xxl-2 {
    margin-left: 8px !important;
  }

  .ml-xxl-3 {
    margin-left: 12px !important;
  }

  .ml-xxl-4 {
    margin-left: 16px !important;
  }

  .ml-xxl-5 {
    margin-left: 20px !important;
  }

  .ml-xxl-6 {
    margin-left: 24px !important;
  }

  .ml-xxl-7 {
    margin-left: 28px !important;
  }

  .ml-xxl-8 {
    margin-left: 32px !important;
  }

  .ml-xxl-9 {
    margin-left: 36px !important;
  }

  .ml-xxl-10 {
    margin-left: 40px !important;
  }

  .ml-xxl-11 {
    margin-left: 44px !important;
  }

  .ml-xxl-12 {
    margin-left: 48px !important;
  }

  .ml-xxl-13 {
    margin-left: 52px !important;
  }

  .ml-xxl-14 {
    margin-left: 56px !important;
  }

  .ml-xxl-15 {
    margin-left: 60px !important;
  }

  .ml-xxl-16 {
    margin-left: 64px !important;
  }

  .ml-xxl-auto {
    margin-left: auto !important;
  }

  .ms-xxl-0 {
    margin-inline-start: 0 !important;
  }

  .ms-xxl-1 {
    margin-inline-start: 4px !important;
  }

  .ms-xxl-2 {
    margin-inline-start: 8px !important;
  }

  .ms-xxl-3 {
    margin-inline-start: 12px !important;
  }

  .ms-xxl-4 {
    margin-inline-start: 16px !important;
  }

  .ms-xxl-5 {
    margin-inline-start: 20px !important;
  }

  .ms-xxl-6 {
    margin-inline-start: 24px !important;
  }

  .ms-xxl-7 {
    margin-inline-start: 28px !important;
  }

  .ms-xxl-8 {
    margin-inline-start: 32px !important;
  }

  .ms-xxl-9 {
    margin-inline-start: 36px !important;
  }

  .ms-xxl-10 {
    margin-inline-start: 40px !important;
  }

  .ms-xxl-11 {
    margin-inline-start: 44px !important;
  }

  .ms-xxl-12 {
    margin-inline-start: 48px !important;
  }

  .ms-xxl-13 {
    margin-inline-start: 52px !important;
  }

  .ms-xxl-14 {
    margin-inline-start: 56px !important;
  }

  .ms-xxl-15 {
    margin-inline-start: 60px !important;
  }

  .ms-xxl-16 {
    margin-inline-start: 64px !important;
  }

  .ms-xxl-auto {
    margin-inline-start: auto !important;
  }

  .me-xxl-0 {
    margin-inline-end: 0 !important;
  }

  .me-xxl-1 {
    margin-inline-end: 4px !important;
  }

  .me-xxl-2 {
    margin-inline-end: 8px !important;
  }

  .me-xxl-3 {
    margin-inline-end: 12px !important;
  }

  .me-xxl-4 {
    margin-inline-end: 16px !important;
  }

  .me-xxl-5 {
    margin-inline-end: 20px !important;
  }

  .me-xxl-6 {
    margin-inline-end: 24px !important;
  }

  .me-xxl-7 {
    margin-inline-end: 28px !important;
  }

  .me-xxl-8 {
    margin-inline-end: 32px !important;
  }

  .me-xxl-9 {
    margin-inline-end: 36px !important;
  }

  .me-xxl-10 {
    margin-inline-end: 40px !important;
  }

  .me-xxl-11 {
    margin-inline-end: 44px !important;
  }

  .me-xxl-12 {
    margin-inline-end: 48px !important;
  }

  .me-xxl-13 {
    margin-inline-end: 52px !important;
  }

  .me-xxl-14 {
    margin-inline-end: 56px !important;
  }

  .me-xxl-15 {
    margin-inline-end: 60px !important;
  }

  .me-xxl-16 {
    margin-inline-end: 64px !important;
  }

  .me-xxl-auto {
    margin-inline-end: auto !important;
  }

  .ma-xxl-n1 {
    margin: -4px !important;
  }

  .ma-xxl-n2 {
    margin: -8px !important;
  }

  .ma-xxl-n3 {
    margin: -12px !important;
  }

  .ma-xxl-n4 {
    margin: -16px !important;
  }

  .ma-xxl-n5 {
    margin: -20px !important;
  }

  .ma-xxl-n6 {
    margin: -24px !important;
  }

  .ma-xxl-n7 {
    margin: -28px !important;
  }

  .ma-xxl-n8 {
    margin: -32px !important;
  }

  .ma-xxl-n9 {
    margin: -36px !important;
  }

  .ma-xxl-n10 {
    margin: -40px !important;
  }

  .ma-xxl-n11 {
    margin: -44px !important;
  }

  .ma-xxl-n12 {
    margin: -48px !important;
  }

  .ma-xxl-n13 {
    margin: -52px !important;
  }

  .ma-xxl-n14 {
    margin: -56px !important;
  }

  .ma-xxl-n15 {
    margin: -60px !important;
  }

  .ma-xxl-n16 {
    margin: -64px !important;
  }

  .mx-xxl-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .mx-xxl-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .mx-xxl-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .mx-xxl-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .mx-xxl-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .mx-xxl-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mx-xxl-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .mx-xxl-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .mx-xxl-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .mx-xxl-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .mx-xxl-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .mx-xxl-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .mx-xxl-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .mx-xxl-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .mx-xxl-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .mx-xxl-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .my-xxl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .my-xxl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .my-xxl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .my-xxl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .my-xxl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .my-xxl-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .my-xxl-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .my-xxl-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .my-xxl-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .my-xxl-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .my-xxl-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .my-xxl-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .my-xxl-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .my-xxl-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .my-xxl-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .my-xxl-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .mt-xxl-n1 {
    margin-top: -4px !important;
  }

  .mt-xxl-n2 {
    margin-top: -8px !important;
  }

  .mt-xxl-n3 {
    margin-top: -12px !important;
  }

  .mt-xxl-n4 {
    margin-top: -16px !important;
  }

  .mt-xxl-n5 {
    margin-top: -20px !important;
  }

  .mt-xxl-n6 {
    margin-top: -24px !important;
  }

  .mt-xxl-n7 {
    margin-top: -28px !important;
  }

  .mt-xxl-n8 {
    margin-top: -32px !important;
  }

  .mt-xxl-n9 {
    margin-top: -36px !important;
  }

  .mt-xxl-n10 {
    margin-top: -40px !important;
  }

  .mt-xxl-n11 {
    margin-top: -44px !important;
  }

  .mt-xxl-n12 {
    margin-top: -48px !important;
  }

  .mt-xxl-n13 {
    margin-top: -52px !important;
  }

  .mt-xxl-n14 {
    margin-top: -56px !important;
  }

  .mt-xxl-n15 {
    margin-top: -60px !important;
  }

  .mt-xxl-n16 {
    margin-top: -64px !important;
  }

  .mr-xxl-n1 {
    margin-right: -4px !important;
  }

  .mr-xxl-n2 {
    margin-right: -8px !important;
  }

  .mr-xxl-n3 {
    margin-right: -12px !important;
  }

  .mr-xxl-n4 {
    margin-right: -16px !important;
  }

  .mr-xxl-n5 {
    margin-right: -20px !important;
  }

  .mr-xxl-n6 {
    margin-right: -24px !important;
  }

  .mr-xxl-n7 {
    margin-right: -28px !important;
  }

  .mr-xxl-n8 {
    margin-right: -32px !important;
  }

  .mr-xxl-n9 {
    margin-right: -36px !important;
  }

  .mr-xxl-n10 {
    margin-right: -40px !important;
  }

  .mr-xxl-n11 {
    margin-right: -44px !important;
  }

  .mr-xxl-n12 {
    margin-right: -48px !important;
  }

  .mr-xxl-n13 {
    margin-right: -52px !important;
  }

  .mr-xxl-n14 {
    margin-right: -56px !important;
  }

  .mr-xxl-n15 {
    margin-right: -60px !important;
  }

  .mr-xxl-n16 {
    margin-right: -64px !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -4px !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -8px !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -12px !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -16px !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -20px !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -24px !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -28px !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -32px !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -36px !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -40px !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -44px !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -48px !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -52px !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -56px !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -60px !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -64px !important;
  }

  .ml-xxl-n1 {
    margin-left: -4px !important;
  }

  .ml-xxl-n2 {
    margin-left: -8px !important;
  }

  .ml-xxl-n3 {
    margin-left: -12px !important;
  }

  .ml-xxl-n4 {
    margin-left: -16px !important;
  }

  .ml-xxl-n5 {
    margin-left: -20px !important;
  }

  .ml-xxl-n6 {
    margin-left: -24px !important;
  }

  .ml-xxl-n7 {
    margin-left: -28px !important;
  }

  .ml-xxl-n8 {
    margin-left: -32px !important;
  }

  .ml-xxl-n9 {
    margin-left: -36px !important;
  }

  .ml-xxl-n10 {
    margin-left: -40px !important;
  }

  .ml-xxl-n11 {
    margin-left: -44px !important;
  }

  .ml-xxl-n12 {
    margin-left: -48px !important;
  }

  .ml-xxl-n13 {
    margin-left: -52px !important;
  }

  .ml-xxl-n14 {
    margin-left: -56px !important;
  }

  .ml-xxl-n15 {
    margin-left: -60px !important;
  }

  .ml-xxl-n16 {
    margin-left: -64px !important;
  }

  .ms-xxl-n1 {
    margin-inline-start: -4px !important;
  }

  .ms-xxl-n2 {
    margin-inline-start: -8px !important;
  }

  .ms-xxl-n3 {
    margin-inline-start: -12px !important;
  }

  .ms-xxl-n4 {
    margin-inline-start: -16px !important;
  }

  .ms-xxl-n5 {
    margin-inline-start: -20px !important;
  }

  .ms-xxl-n6 {
    margin-inline-start: -24px !important;
  }

  .ms-xxl-n7 {
    margin-inline-start: -28px !important;
  }

  .ms-xxl-n8 {
    margin-inline-start: -32px !important;
  }

  .ms-xxl-n9 {
    margin-inline-start: -36px !important;
  }

  .ms-xxl-n10 {
    margin-inline-start: -40px !important;
  }

  .ms-xxl-n11 {
    margin-inline-start: -44px !important;
  }

  .ms-xxl-n12 {
    margin-inline-start: -48px !important;
  }

  .ms-xxl-n13 {
    margin-inline-start: -52px !important;
  }

  .ms-xxl-n14 {
    margin-inline-start: -56px !important;
  }

  .ms-xxl-n15 {
    margin-inline-start: -60px !important;
  }

  .ms-xxl-n16 {
    margin-inline-start: -64px !important;
  }

  .me-xxl-n1 {
    margin-inline-end: -4px !important;
  }

  .me-xxl-n2 {
    margin-inline-end: -8px !important;
  }

  .me-xxl-n3 {
    margin-inline-end: -12px !important;
  }

  .me-xxl-n4 {
    margin-inline-end: -16px !important;
  }

  .me-xxl-n5 {
    margin-inline-end: -20px !important;
  }

  .me-xxl-n6 {
    margin-inline-end: -24px !important;
  }

  .me-xxl-n7 {
    margin-inline-end: -28px !important;
  }

  .me-xxl-n8 {
    margin-inline-end: -32px !important;
  }

  .me-xxl-n9 {
    margin-inline-end: -36px !important;
  }

  .me-xxl-n10 {
    margin-inline-end: -40px !important;
  }

  .me-xxl-n11 {
    margin-inline-end: -44px !important;
  }

  .me-xxl-n12 {
    margin-inline-end: -48px !important;
  }

  .me-xxl-n13 {
    margin-inline-end: -52px !important;
  }

  .me-xxl-n14 {
    margin-inline-end: -56px !important;
  }

  .me-xxl-n15 {
    margin-inline-end: -60px !important;
  }

  .me-xxl-n16 {
    margin-inline-end: -64px !important;
  }

  .pa-xxl-0 {
    padding: 0 !important;
  }

  .pa-xxl-1 {
    padding: 4px !important;
  }

  .pa-xxl-2 {
    padding: 8px !important;
  }

  .pa-xxl-3 {
    padding: 12px !important;
  }

  .pa-xxl-4 {
    padding: 16px !important;
  }

  .pa-xxl-5 {
    padding: 20px !important;
  }

  .pa-xxl-6 {
    padding: 24px !important;
  }

  .pa-xxl-7 {
    padding: 28px !important;
  }

  .pa-xxl-8 {
    padding: 32px !important;
  }

  .pa-xxl-9 {
    padding: 36px !important;
  }

  .pa-xxl-10 {
    padding: 40px !important;
  }

  .pa-xxl-11 {
    padding: 44px !important;
  }

  .pa-xxl-12 {
    padding: 48px !important;
  }

  .pa-xxl-13 {
    padding: 52px !important;
  }

  .pa-xxl-14 {
    padding: 56px !important;
  }

  .pa-xxl-15 {
    padding: 60px !important;
  }

  .pa-xxl-16 {
    padding: 64px !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .px-xxl-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-xxl-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-xxl-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-xxl-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-xxl-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-xxl-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-xxl-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-xxl-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .px-xxl-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-xxl-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-xxl-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-xxl-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .px-xxl-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .px-xxl-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-xxl-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-xxl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-xxl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .py-xxl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-xxl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xxl-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-xxl-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .py-xxl-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .py-xxl-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .py-xxl-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xxl-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .py-xxl-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .py-xxl-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .py-xxl-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .py-xxl-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-xxl-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 4px !important;
  }

  .pt-xxl-2 {
    padding-top: 8px !important;
  }

  .pt-xxl-3 {
    padding-top: 12px !important;
  }

  .pt-xxl-4 {
    padding-top: 16px !important;
  }

  .pt-xxl-5 {
    padding-top: 20px !important;
  }

  .pt-xxl-6 {
    padding-top: 24px !important;
  }

  .pt-xxl-7 {
    padding-top: 28px !important;
  }

  .pt-xxl-8 {
    padding-top: 32px !important;
  }

  .pt-xxl-9 {
    padding-top: 36px !important;
  }

  .pt-xxl-10 {
    padding-top: 40px !important;
  }

  .pt-xxl-11 {
    padding-top: 44px !important;
  }

  .pt-xxl-12 {
    padding-top: 48px !important;
  }

  .pt-xxl-13 {
    padding-top: 52px !important;
  }

  .pt-xxl-14 {
    padding-top: 56px !important;
  }

  .pt-xxl-15 {
    padding-top: 60px !important;
  }

  .pt-xxl-16 {
    padding-top: 64px !important;
  }

  .pr-xxl-0 {
    padding-right: 0 !important;
  }

  .pr-xxl-1 {
    padding-right: 4px !important;
  }

  .pr-xxl-2 {
    padding-right: 8px !important;
  }

  .pr-xxl-3 {
    padding-right: 12px !important;
  }

  .pr-xxl-4 {
    padding-right: 16px !important;
  }

  .pr-xxl-5 {
    padding-right: 20px !important;
  }

  .pr-xxl-6 {
    padding-right: 24px !important;
  }

  .pr-xxl-7 {
    padding-right: 28px !important;
  }

  .pr-xxl-8 {
    padding-right: 32px !important;
  }

  .pr-xxl-9 {
    padding-right: 36px !important;
  }

  .pr-xxl-10 {
    padding-right: 40px !important;
  }

  .pr-xxl-11 {
    padding-right: 44px !important;
  }

  .pr-xxl-12 {
    padding-right: 48px !important;
  }

  .pr-xxl-13 {
    padding-right: 52px !important;
  }

  .pr-xxl-14 {
    padding-right: 56px !important;
  }

  .pr-xxl-15 {
    padding-right: 60px !important;
  }

  .pr-xxl-16 {
    padding-right: 64px !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 4px !important;
  }

  .pb-xxl-2 {
    padding-bottom: 8px !important;
  }

  .pb-xxl-3 {
    padding-bottom: 12px !important;
  }

  .pb-xxl-4 {
    padding-bottom: 16px !important;
  }

  .pb-xxl-5 {
    padding-bottom: 20px !important;
  }

  .pb-xxl-6 {
    padding-bottom: 24px !important;
  }

  .pb-xxl-7 {
    padding-bottom: 28px !important;
  }

  .pb-xxl-8 {
    padding-bottom: 32px !important;
  }

  .pb-xxl-9 {
    padding-bottom: 36px !important;
  }

  .pb-xxl-10 {
    padding-bottom: 40px !important;
  }

  .pb-xxl-11 {
    padding-bottom: 44px !important;
  }

  .pb-xxl-12 {
    padding-bottom: 48px !important;
  }

  .pb-xxl-13 {
    padding-bottom: 52px !important;
  }

  .pb-xxl-14 {
    padding-bottom: 56px !important;
  }

  .pb-xxl-15 {
    padding-bottom: 60px !important;
  }

  .pb-xxl-16 {
    padding-bottom: 64px !important;
  }

  .pl-xxl-0 {
    padding-left: 0 !important;
  }

  .pl-xxl-1 {
    padding-left: 4px !important;
  }

  .pl-xxl-2 {
    padding-left: 8px !important;
  }

  .pl-xxl-3 {
    padding-left: 12px !important;
  }

  .pl-xxl-4 {
    padding-left: 16px !important;
  }

  .pl-xxl-5 {
    padding-left: 20px !important;
  }

  .pl-xxl-6 {
    padding-left: 24px !important;
  }

  .pl-xxl-7 {
    padding-left: 28px !important;
  }

  .pl-xxl-8 {
    padding-left: 32px !important;
  }

  .pl-xxl-9 {
    padding-left: 36px !important;
  }

  .pl-xxl-10 {
    padding-left: 40px !important;
  }

  .pl-xxl-11 {
    padding-left: 44px !important;
  }

  .pl-xxl-12 {
    padding-left: 48px !important;
  }

  .pl-xxl-13 {
    padding-left: 52px !important;
  }

  .pl-xxl-14 {
    padding-left: 56px !important;
  }

  .pl-xxl-15 {
    padding-left: 60px !important;
  }

  .pl-xxl-16 {
    padding-left: 64px !important;
  }

  .ps-xxl-0 {
    padding-inline-start: 0 !important;
  }

  .ps-xxl-1 {
    padding-inline-start: 4px !important;
  }

  .ps-xxl-2 {
    padding-inline-start: 8px !important;
  }

  .ps-xxl-3 {
    padding-inline-start: 12px !important;
  }

  .ps-xxl-4 {
    padding-inline-start: 16px !important;
  }

  .ps-xxl-5 {
    padding-inline-start: 20px !important;
  }

  .ps-xxl-6 {
    padding-inline-start: 24px !important;
  }

  .ps-xxl-7 {
    padding-inline-start: 28px !important;
  }

  .ps-xxl-8 {
    padding-inline-start: 32px !important;
  }

  .ps-xxl-9 {
    padding-inline-start: 36px !important;
  }

  .ps-xxl-10 {
    padding-inline-start: 40px !important;
  }

  .ps-xxl-11 {
    padding-inline-start: 44px !important;
  }

  .ps-xxl-12 {
    padding-inline-start: 48px !important;
  }

  .ps-xxl-13 {
    padding-inline-start: 52px !important;
  }

  .ps-xxl-14 {
    padding-inline-start: 56px !important;
  }

  .ps-xxl-15 {
    padding-inline-start: 60px !important;
  }

  .ps-xxl-16 {
    padding-inline-start: 64px !important;
  }

  .pe-xxl-0 {
    padding-inline-end: 0 !important;
  }

  .pe-xxl-1 {
    padding-inline-end: 4px !important;
  }

  .pe-xxl-2 {
    padding-inline-end: 8px !important;
  }

  .pe-xxl-3 {
    padding-inline-end: 12px !important;
  }

  .pe-xxl-4 {
    padding-inline-end: 16px !important;
  }

  .pe-xxl-5 {
    padding-inline-end: 20px !important;
  }

  .pe-xxl-6 {
    padding-inline-end: 24px !important;
  }

  .pe-xxl-7 {
    padding-inline-end: 28px !important;
  }

  .pe-xxl-8 {
    padding-inline-end: 32px !important;
  }

  .pe-xxl-9 {
    padding-inline-end: 36px !important;
  }

  .pe-xxl-10 {
    padding-inline-end: 40px !important;
  }

  .pe-xxl-11 {
    padding-inline-end: 44px !important;
  }

  .pe-xxl-12 {
    padding-inline-end: 48px !important;
  }

  .pe-xxl-13 {
    padding-inline-end: 52px !important;
  }

  .pe-xxl-14 {
    padding-inline-end: 56px !important;
  }

  .pe-xxl-15 {
    padding-inline-end: 60px !important;
  }

  .pe-xxl-16 {
    padding-inline-end: 64px !important;
  }

  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .text-xxl-justify {
    text-align: justify !important;
  }

  .text-xxl-start {
    text-align: start !important;
  }

  .text-xxl-end {
    text-align: end !important;
  }

  .text-xxl-h1 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.015625em !important;
    text-transform: none !important;
    font-size: 6rem !important;
  }

  .text-xxl-h2 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.00833333em !important;
    text-transform: none !important;
    font-size: 3.75rem !important;
  }

  .text-xxl-h3 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 3rem !important;
  }

  .text-xxl-h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: .00735294em !important;
    text-transform: none !important;
    font-size: 2.125rem !important;
  }

  .text-xxl-h5 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
  }

  .text-xxl-h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .0125em !important;
    text-transform: none !important;
    font-size: 1.25rem !important;
  }

  .text-xxl-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: .009375em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-xxl-subtitle-2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .00714286em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-xxl-body-1 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .03125em !important;
    text-transform: none !important;
    font-size: 1rem !important;
  }

  .text-xxl-body-2 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.425;
    letter-spacing: .0178571em !important;
    text-transform: none !important;
    font-size: .875rem !important;
  }

  .text-xxl-button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.6;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
    font-size: .875rem !important;
  }

  .text-xxl-caption {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.667;
    letter-spacing: .0333333em !important;
    text-transform: none !important;
    font-size: .75rem !important;
  }

  .text-xxl-overline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 2.667;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
    font-size: .75rem !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .h-xxl-screen {
    height: 100vh !important;
  }

  .h-xxl-0 {
    height: 0 !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-0 {
    width: 0 !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-33 {
    width: 33% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-66 {
    width: 66% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .float-print-none {
    float: none !important;
  }

  .float-print-left {
    float: left !important;
  }

  .float-print-right {
    float: right !important;
  }

  .v-locale--is-rtl .float-print-end {
    float: left !important;
  }

  .v-locale--is-rtl .float-print-start, .v-locale--is-ltr .float-print-end {
    float: right !important;
  }

  .v-locale--is-ltr .float-print-start {
    float: left !important;
  }
}

.v-application {
  background: rgb(var(--v-theme-background));
  color: rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity));
  display: flex;
}

.v-application__wrap {
  backface-visibility: hidden;
  flex-direction: column;
  flex: auto;
  max-width: 100%;
  min-height: 100dvh;
  display: flex;
  position: relative;
}

.v-avatar {
  text-align: center;
  vertical-align: middle;
  flex: none;
  justify-content: center;
  align-items: center;
  line-height: normal;
  transition: width .2s cubic-bezier(.4, 0, .2, 1), height .2s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.v-avatar.v-avatar--size-x-small {
  --v-avatar-height: 24px;
}

.v-avatar.v-avatar--size-small {
  --v-avatar-height: 32px;
}

.v-avatar.v-avatar--size-default {
  --v-avatar-height: 40px;
}

.v-avatar.v-avatar--size-large {
  --v-avatar-height: 48px;
}

.v-avatar.v-avatar--size-x-large {
  --v-avatar-height: 56px;
}

.v-avatar.v-avatar--density-default {
  height: calc(var(--v-avatar-height)  + 0px);
  width: calc(var(--v-avatar-height)  + 0px);
}

.v-avatar.v-avatar--density-comfortable {
  height: calc(var(--v-avatar-height)  + -4px);
  width: calc(var(--v-avatar-height)  + -4px);
}

.v-avatar.v-avatar--density-compact {
  height: calc(var(--v-avatar-height)  + -8px);
  width: calc(var(--v-avatar-height)  + -8px);
}

.v-avatar {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: thin;
}

.v-avatar--border {
  box-shadow: none;
  border-width: thin;
}

.v-avatar {
  border-radius: 50%;
}

.v-avatar--variant-plain, .v-avatar--variant-outlined, .v-avatar--variant-text, .v-avatar--variant-tonal {
  color: inherit;
  background: none;
}

.v-avatar--variant-plain {
  opacity: .62;
}

.v-avatar--variant-plain:focus, .v-avatar--variant-plain:hover {
  opacity: 1;
}

.v-avatar--variant-plain .v-avatar__overlay {
  display: none;
}

.v-avatar--variant-elevated, .v-avatar--variant-flat {
  background: var(--v-theme-surface);
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
}

.v-avatar--variant-elevated {
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 3px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-avatar--variant-flat {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, #0003), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-avatar--variant-outlined {
  border: thin solid;
}

.v-avatar--variant-text .v-avatar__overlay {
  background: currentColor;
}

.v-avatar--variant-tonal .v-avatar__underlay {
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  pointer-events: none;
  background: currentColor;
  inset: 0;
}

.v-avatar .v-avatar__underlay {
  position: absolute;
}

.v-avatar--rounded {
  border-radius: 4px;
}

.v-avatar--start {
  margin-inline-end: 8px;
}

.v-avatar--end {
  margin-inline-start: 8px;
}

.v-avatar .v-img {
  width: 100%;
  height: 100%;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  font-feature-settings: "liga";
  letter-spacing: normal;
  text-indent: 0;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 1em;
  min-width: 1em;
  height: 1em;
  line-height: 1;
  display: inline-flex;
  position: relative;
}

.v-icon--clickable {
  cursor: pointer;
}

.v-icon--disabled {
  pointer-events: none;
  opacity: .38;
}

.v-icon--size-x-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1em);
}

.v-icon--size-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.25em);
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.5em);
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 1.75em);
}

.v-icon--size-x-large {
  font-size: calc(var(--v-icon-size-multiplier) * 2em);
}

.v-icon__svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.v-icon--start {
  margin-inline-end: 8px;
}

.v-icon--end {
  margin-inline-start: 8px;
}

.v-img {
  --v-theme-overlay-multiplier: 3;
  z-index: 0;
}

.v-img.v-img--absolute {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-img--booting .v-responsive__sizer {
  transition: none;
}

.v-img--rounded {
  border-radius: 4px;
}

.v-img__img, .v-img__picture, .v-img__gradient, .v-img__placeholder, .v-img__error {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.v-img__img--preload {
  filter: blur(4px);
}

.v-img__img--contain {
  object-fit: contain;
}

.v-img__img--cover {
  object-fit: cover;
}

.v-img__gradient {
  background-repeat: no-repeat;
}

.v-responsive {
  flex: 1 0 auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.v-responsive--inline {
  flex: none;
  display: inline-flex;
}

.v-responsive__content {
  flex: 1 0 0;
  max-width: 100%;
}

.v-responsive__sizer ~ .v-responsive__content {
  margin-inline-start: -100%;
}

.v-responsive__sizer {
  pointer-events: none;
  flex: 1 0 0;
  transition: padding-bottom .2s cubic-bezier(.4, 0, .2, 1);
}

.v-label {
  color: inherit;
  letter-spacing: .009375em;
  opacity: var(--v-medium-emphasis-opacity);
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  min-width: 0;
  font-size: 1rem;
  display: inline-flex;
  overflow: hidden;
}

.v-label--clickable {
  cursor: pointer;
}

.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  z-index: 0;
  pointer-events: none;
  contain: strict;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-ripple__animation {
  color: inherit;
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-ripple__animation--enter {
  opacity: 0;
  transition: none;
}

.v-ripple__animation--in {
  opacity: calc(.25 * var(--v-theme-overlay-multiplier));
  transition: transform .25s cubic-bezier(0, 0, .2, 1), opacity .1s cubic-bezier(0, 0, .2, 1);
}

.v-ripple__animation--out {
  opacity: 0;
  transition: opacity .3s cubic-bezier(0, 0, .2, 1);
}

.v-chip {
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.v-chip .v-icon {
  --v-icon-size-multiplier: .857143;
}

.v-chip.v-chip--size-x-small {
  --v-chip-size: .625rem;
  --v-chip-height: 20px;
  padding: 0 8px;
  font-size: .625rem;
}

.v-chip.v-chip--size-x-small .v-avatar {
  --v-avatar-height: 14px;
}

.v-chip--pill.v-chip.v-chip--size-x-small .v-avatar {
  --v-avatar-height: 20px;
}

.v-chip.v-chip--size-x-small .v-avatar--start {
  margin-inline: -5.6px 4px;
}

.v-chip--pill.v-chip.v-chip--size-x-small .v-avatar--start {
  margin-inline-start: -8px;
}

.v-chip.v-chip--size-x-small .v-avatar--end {
  margin-inline: 4px -5.6px;
}

.v-chip--pill.v-chip.v-chip--size-x-small .v-avatar--end {
  margin-inline-end: -8px;
}

.v-chip--pill.v-chip.v-chip--size-x-small .v-avatar--end + .v-chip__close {
  margin-inline-start: 12px;
}

.v-chip.v-chip--size-x-small .v-icon--start, .v-chip.v-chip--size-x-small .v-chip__filter {
  margin-inline: -4px 4px;
}

.v-chip.v-chip--size-x-small .v-icon--end, .v-chip.v-chip--size-x-small .v-chip__close {
  margin-inline: 4px -4px;
}

.v-chip.v-chip--size-x-small .v-icon--end + .v-chip__close, .v-chip.v-chip--size-x-small .v-avatar--end + .v-chip__close, .v-chip.v-chip--size-x-small .v-chip__append + .v-chip__close {
  margin-inline-start: 8px;
}

.v-chip.v-chip--size-small {
  --v-chip-size: .75rem;
  --v-chip-height: 26px;
  padding: 0 10px;
  font-size: .75rem;
}

.v-chip.v-chip--size-small .v-avatar {
  --v-avatar-height: 20px;
}

.v-chip--pill.v-chip.v-chip--size-small .v-avatar {
  --v-avatar-height: 26px;
}

.v-chip.v-chip--size-small .v-avatar--start {
  margin-inline: -7px 5px;
}

.v-chip--pill.v-chip.v-chip--size-small .v-avatar--start {
  margin-inline-start: -10px;
}

.v-chip.v-chip--size-small .v-avatar--end {
  margin-inline: 5px -7px;
}

.v-chip--pill.v-chip.v-chip--size-small .v-avatar--end {
  margin-inline-end: -10px;
}

.v-chip--pill.v-chip.v-chip--size-small .v-avatar--end + .v-chip__close {
  margin-inline-start: 15px;
}

.v-chip.v-chip--size-small .v-icon--start, .v-chip.v-chip--size-small .v-chip__filter {
  margin-inline: -5px 5px;
}

.v-chip.v-chip--size-small .v-icon--end, .v-chip.v-chip--size-small .v-chip__close {
  margin-inline: 5px -5px;
}

.v-chip.v-chip--size-small .v-icon--end + .v-chip__close, .v-chip.v-chip--size-small .v-avatar--end + .v-chip__close, .v-chip.v-chip--size-small .v-chip__append + .v-chip__close {
  margin-inline-start: 10px;
}

.v-chip.v-chip--size-default {
  --v-chip-size: .875rem;
  --v-chip-height: 32px;
  padding: 0 12px;
  font-size: .875rem;
}

.v-chip.v-chip--size-default .v-avatar {
  --v-avatar-height: 26px;
}

.v-chip--pill.v-chip.v-chip--size-default .v-avatar {
  --v-avatar-height: 32px;
}

.v-chip.v-chip--size-default .v-avatar--start {
  margin-inline: -8.4px 6px;
}

.v-chip--pill.v-chip.v-chip--size-default .v-avatar--start {
  margin-inline-start: -12px;
}

.v-chip.v-chip--size-default .v-avatar--end {
  margin-inline: 6px -8.4px;
}

.v-chip--pill.v-chip.v-chip--size-default .v-avatar--end {
  margin-inline-end: -12px;
}

.v-chip--pill.v-chip.v-chip--size-default .v-avatar--end + .v-chip__close {
  margin-inline-start: 18px;
}

.v-chip.v-chip--size-default .v-icon--start, .v-chip.v-chip--size-default .v-chip__filter {
  margin-inline: -6px 6px;
}

.v-chip.v-chip--size-default .v-icon--end, .v-chip.v-chip--size-default .v-chip__close {
  margin-inline: 6px -6px;
}

.v-chip.v-chip--size-default .v-icon--end + .v-chip__close, .v-chip.v-chip--size-default .v-avatar--end + .v-chip__close, .v-chip.v-chip--size-default .v-chip__append + .v-chip__close {
  margin-inline-start: 12px;
}

.v-chip.v-chip--size-large {
  --v-chip-size: 1rem;
  --v-chip-height: 38px;
  padding: 0 14px;
  font-size: 1rem;
}

.v-chip.v-chip--size-large .v-avatar {
  --v-avatar-height: 32px;
}

.v-chip--pill.v-chip.v-chip--size-large .v-avatar {
  --v-avatar-height: 38px;
}

.v-chip.v-chip--size-large .v-avatar--start {
  margin-inline: -9.8px 7px;
}

.v-chip--pill.v-chip.v-chip--size-large .v-avatar--start {
  margin-inline-start: -14px;
}

.v-chip.v-chip--size-large .v-avatar--end {
  margin-inline: 7px -9.8px;
}

.v-chip--pill.v-chip.v-chip--size-large .v-avatar--end {
  margin-inline-end: -14px;
}

.v-chip--pill.v-chip.v-chip--size-large .v-avatar--end + .v-chip__close {
  margin-inline-start: 21px;
}

.v-chip.v-chip--size-large .v-icon--start, .v-chip.v-chip--size-large .v-chip__filter {
  margin-inline: -7px 7px;
}

.v-chip.v-chip--size-large .v-icon--end, .v-chip.v-chip--size-large .v-chip__close {
  margin-inline: 7px -7px;
}

.v-chip.v-chip--size-large .v-icon--end + .v-chip__close, .v-chip.v-chip--size-large .v-avatar--end + .v-chip__close, .v-chip.v-chip--size-large .v-chip__append + .v-chip__close {
  margin-inline-start: 14px;
}

.v-chip.v-chip--size-x-large {
  --v-chip-size: 1.125rem;
  --v-chip-height: 44px;
  padding: 0 17px;
  font-size: 1.125rem;
}

.v-chip.v-chip--size-x-large .v-avatar {
  --v-avatar-height: 38px;
}

.v-chip--pill.v-chip.v-chip--size-x-large .v-avatar {
  --v-avatar-height: 44px;
}

.v-chip.v-chip--size-x-large .v-avatar--start {
  margin-inline: -11.9px 8.5px;
}

.v-chip--pill.v-chip.v-chip--size-x-large .v-avatar--start {
  margin-inline-start: -17px;
}

.v-chip.v-chip--size-x-large .v-avatar--end {
  margin-inline: 8.5px -11.9px;
}

.v-chip--pill.v-chip.v-chip--size-x-large .v-avatar--end {
  margin-inline-end: -17px;
}

.v-chip--pill.v-chip.v-chip--size-x-large .v-avatar--end + .v-chip__close {
  margin-inline-start: 25.5px;
}

.v-chip.v-chip--size-x-large .v-icon--start, .v-chip.v-chip--size-x-large .v-chip__filter {
  margin-inline: -8.5px 8.5px;
}

.v-chip.v-chip--size-x-large .v-icon--end, .v-chip.v-chip--size-x-large .v-chip__close {
  margin-inline: 8.5px -8.5px;
}

.v-chip.v-chip--size-x-large .v-icon--end + .v-chip__close, .v-chip.v-chip--size-x-large .v-avatar--end + .v-chip__close, .v-chip.v-chip--size-x-large .v-chip__append + .v-chip__close {
  margin-inline-start: 17px;
}

.v-chip.v-chip--density-default {
  height: calc(var(--v-chip-height)  + 0px);
}

.v-chip.v-chip--density-comfortable {
  height: calc(var(--v-chip-height)  + -4px);
}

.v-chip.v-chip--density-compact {
  height: calc(var(--v-chip-height)  + -8px);
}

.v-chip {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
}

.v-chip:hover > .v-chip__overlay {
  opacity: calc(var(--v-hover-opacity) * var(--v-theme-overlay-multiplier));
}

.v-chip:focus-visible > .v-chip__overlay {
  opacity: calc(var(--v-focus-opacity) * var(--v-theme-overlay-multiplier));
}

@supports not selector(:focus-visible) {
  .v-chip:focus > .v-chip__overlay {
    opacity: calc(var(--v-focus-opacity) * var(--v-theme-overlay-multiplier));
  }
}

.v-chip--active > .v-chip__overlay, .v-chip[aria-haspopup="menu"][aria-expanded="true"] > .v-chip__overlay {
  opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier));
}

.v-chip--active:hover > .v-chip__overlay, .v-chip[aria-haspopup="menu"][aria-expanded="true"]:hover > .v-chip__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
}

.v-chip--active:focus-visible > .v-chip__overlay, .v-chip[aria-haspopup="menu"][aria-expanded="true"]:focus-visible > .v-chip__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
}

@supports not selector(:focus-visible) {
  .v-chip--active:focus > .v-chip__overlay, .v-chip[aria-haspopup="menu"][aria-expanded="true"]:focus > .v-chip__overlay {
    opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-chip {
  border-radius: 9999px;
}

.v-chip--variant-plain, .v-chip--variant-outlined, .v-chip--variant-text, .v-chip--variant-tonal {
  color: inherit;
  background: none;
}

.v-chip--variant-plain {
  opacity: .26;
}

.v-chip--variant-plain:focus, .v-chip--variant-plain:hover {
  opacity: 1;
}

.v-chip--variant-plain .v-chip__overlay {
  display: none;
}

.v-chip--variant-elevated, .v-chip--variant-flat {
  background: rgb(var(--v-theme-surface-variant));
  color: rgb(var(--v-theme-on-surface-variant));
}

.v-chip--variant-elevated {
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 3px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-chip--variant-flat {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, #0003), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-chip--variant-outlined {
  border: thin solid;
}

.v-chip--variant-text .v-chip__overlay {
  background: currentColor;
}

.v-chip--variant-tonal .v-chip__underlay {
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  pointer-events: none;
  background: currentColor;
  inset: 0;
}

.v-chip .v-chip__underlay {
  position: absolute;
}

.v-chip--border {
  border-width: thin;
}

.v-chip--link {
  cursor: pointer;
}

.v-chip--link, .v-chip--filter {
  -webkit-user-select: none;
  user-select: none;
}

.v-chip__content {
  align-items: center;
  display: inline-flex;
}

.v-autocomplete__selection .v-chip__content, .v-combobox__selection .v-chip__content, .v-select__selection .v-chip__content {
  overflow: hidden;
}

.v-chip__filter, .v-chip__prepend, .v-chip__append, .v-chip__close {
  align-items: center;
  display: inline-flex;
}

.v-chip__close {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: 0 auto;
  max-width: 18px;
  max-height: 18px;
  font-size: 18px;
}

.v-chip__close .v-icon {
  font-size: inherit;
}

.v-chip__filter {
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
}

.v-chip__overlay {
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  background-color: currentColor;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.v-chip--disabled {
  opacity: .3;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.v-chip--label {
  border-radius: 4px;
}

.v-chip-group {
  min-width: 0;
  max-width: 100%;
  padding: 4px 0;
  display: flex;
  overflow-x: auto;
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0;
}

.v-chip-group .v-chip.v-chip--selected:not(.v-chip--disabled) .v-chip__overlay {
  opacity: var(--v-activated-opacity);
}

.v-chip-group--column .v-slide-group__content {
  white-space: normal;
  flex-wrap: wrap;
  max-width: 100%;
}

.v-slide-group {
  display: flex;
  overflow: hidden;
}

.v-slide-group__next, .v-slide-group__prev {
  cursor: pointer;
  flex: 0 52px;
  justify-content: center;
  align-items: center;
  min-width: 52px;
  display: flex;
}

.v-slide-group__next--disabled, .v-slide-group__prev--disabled {
  pointer-events: none;
  opacity: var(--v-disabled-opacity);
}

.v-slide-group__content {
  white-space: nowrap;
  flex: 1 0 auto;
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.v-slide-group__content > * {
  white-space: initial;
}

.v-slide-group__container {
  contain: content;
  scrollbar-width: none;
  scrollbar-color: #0000;
  flex: auto;
  display: flex;
  overflow: auto hidden;
}

.v-slide-group__container::-webkit-scrollbar {
  display: none;
}

.v-slide-group--vertical {
  max-height: inherit;
}

.v-slide-group--vertical, .v-slide-group--vertical .v-slide-group__container, .v-slide-group--vertical .v-slide-group__content {
  flex-direction: column;
}

.v-slide-group--vertical .v-slide-group__container {
  overflow: hidden auto;
}

.v-text-field input {
  color: inherit;
  opacity: 0;
  flex: 1;
  min-width: 0;
  transition: opacity .15s cubic-bezier(.4, 0, .2, 1);
}

.v-text-field input:focus, .v-text-field input:active {
  outline: none;
}

.v-text-field input:invalid {
  box-shadow: none;
}

.v-text-field .v-field {
  cursor: text;
}

.v-text-field--prefixed.v-text-field .v-field__input {
  --v-field-padding-start: 6px;
}

.v-text-field--suffixed.v-text-field .v-field__input {
  --v-field-padding-end: 0;
}

.v-text-field .v-input__details {
  padding-inline: 16px;
}

.v-input--plain-underlined.v-text-field .v-input__details {
  padding-inline: 0;
}

.v-text-field .v-field--no-label input, .v-text-field .v-field--active input {
  opacity: 1;
}

.v-text-field .v-field--single-line input {
  transition: none;
}

.v-text-field__prefix, .v-text-field__suffix {
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  cursor: default;
  opacity: 0;
  white-space: nowrap;
  min-height: max(var(--v-input-control-height, 56px), 1.5rem + var(--v-field-input-padding-top)  + var(--v-field-input-padding-bottom));
  padding-top: calc(var(--v-field-padding-top, 4px)  + var(--v-input-padding-top, 0));
  padding-bottom: var(--v-field-padding-bottom, 6px);
  align-items: center;
  transition: inherit;
  display: flex;
}

.v-field--active .v-text-field__prefix, .v-field--active .v-text-field__suffix {
  opacity: 1;
}

.v-field--disabled .v-text-field__prefix, .v-field--disabled .v-text-field__suffix {
  color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity));
}

.v-text-field__prefix {
  padding-inline-start: var(--v-field-padding-start);
}

.v-text-field__suffix {
  padding-inline-end: var(--v-field-padding-end);
}

.v-counter {
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  flex: 0 auto;
  font-size: 12px;
  transition-duration: .15s;
}

.v-field {
  letter-spacing: .009375em;
  contain: layout;
  --v-theme-overlay-multiplier: 1;
  --v-field-padding-start: 16px;
  --v-field-padding-end: 16px;
  --v-field-padding-top: 8px;
  --v-field-padding-bottom: 4px;
  --v-field-input-padding-top: calc(var(--v-field-padding-top, 8px)  + var(--v-input-padding-top, 0));
  --v-field-input-padding-bottom: var(--v-field-padding-bottom, 4px);
  border-radius: 4px;
  flex: 1 0;
  grid-area: control;
  grid-template-columns: min-content minmax(0, 1fr) min-content min-content;
  grid-template-areas: "prepend-inner field clear append-inner";
  max-width: 100%;
  font-size: 16px;
  display: grid;
  position: relative;
}

.v-field--disabled {
  opacity: var(--v-disabled-opacity);
  pointer-events: none;
}

.v-field .v-chip {
  --v-chip-height: 24px;
}

.v-field--prepended {
  padding-inline-start: 12px;
}

.v-field--appended {
  padding-inline-end: 12px;
}

.v-field--variant-solo, .v-field--variant-solo-filled, .v-field--variant-solo-inverted {
  background: rgb(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, #0003), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
  border-color: #0000;
}

.v-field--variant-solo-inverted.v-field--focused {
  color: rgb(var(--v-theme-on-surface-variant));
}

.v-field--variant-filled {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.v-input--density-default .v-field--variant-solo, .v-input--density-default .v-field--variant-solo-inverted, .v-input--density-default .v-field--variant-solo-filled, .v-input--density-default .v-field--variant-filled {
  --v-input-control-height: 56px;
  --v-field-padding-bottom: 4px;
}

.v-input--density-comfortable .v-field--variant-solo, .v-input--density-comfortable .v-field--variant-solo-inverted, .v-input--density-comfortable .v-field--variant-solo-filled, .v-input--density-comfortable .v-field--variant-filled {
  --v-input-control-height: 48px;
  --v-field-padding-bottom: 0px;
}

.v-input--density-compact .v-field--variant-solo, .v-input--density-compact .v-field--variant-solo-inverted, .v-input--density-compact .v-field--variant-solo-filled, .v-input--density-compact .v-field--variant-filled {
  --v-input-control-height: 40px;
  --v-field-padding-bottom: 0px;
}

.v-field--variant-outlined, .v-field--single-line, .v-field--no-label {
  --v-field-padding-top: 0px;
}

.v-input--density-default .v-field--variant-outlined, .v-input--density-default .v-field--single-line, .v-input--density-default .v-field--no-label {
  --v-field-padding-bottom: 16px;
}

.v-input--density-comfortable .v-field--variant-outlined, .v-input--density-comfortable .v-field--single-line, .v-input--density-comfortable .v-field--no-label {
  --v-field-padding-bottom: 12px;
}

.v-input--density-compact .v-field--variant-outlined, .v-input--density-compact .v-field--single-line, .v-input--density-compact .v-field--no-label {
  --v-field-padding-bottom: 8px;
}

.v-field--variant-plain, .v-field--variant-underlined {
  border-radius: 0;
  padding: 0;
}

.v-field--variant-plain.v-field, .v-field--variant-underlined.v-field {
  --v-field-padding-start: 0px;
  --v-field-padding-end: 0px;
}

.v-input--density-default .v-field--variant-plain, .v-input--density-default .v-field--variant-underlined {
  --v-input-control-height: 48px;
  --v-field-padding-top: 4px;
  --v-field-padding-bottom: 4px;
}

.v-input--density-comfortable .v-field--variant-plain, .v-input--density-comfortable .v-field--variant-underlined {
  --v-input-control-height: 40px;
  --v-field-padding-top: 2px;
  --v-field-padding-bottom: 0px;
}

.v-input--density-compact .v-field--variant-plain, .v-input--density-compact .v-field--variant-underlined {
  --v-input-control-height: 32px;
  --v-field-padding-top: 0px;
  --v-field-padding-bottom: 0px;
}

.v-field--flat {
  box-shadow: none;
}

.v-field--rounded {
  border-radius: 24px;
}

.v-field.v-field--prepended {
  --v-field-padding-start: 6px;
}

.v-field.v-field--appended {
  --v-field-padding-end: 6px;
}

.v-field__input {
  color: inherit;
  letter-spacing: .009375em;
  opacity: var(--v-high-emphasis-opacity);
  min-height: max(var(--v-input-control-height, 56px), 1.5rem + var(--v-field-input-padding-top)  + var(--v-field-input-padding-bottom));
  padding-inline: var(--v-field-padding-start) var(--v-field-padding-end);
  padding-top: var(--v-field-input-padding-top);
  padding-bottom: var(--v-field-input-padding-bottom);
  flex-wrap: wrap;
  align-items: center;
  column-gap: 2px;
  width: 100%;
  min-width: 0;
  display: flex;
  position: relative;
}

.v-input--density-default .v-field__input {
  row-gap: 8px;
}

.v-input--density-comfortable .v-field__input {
  row-gap: 6px;
}

.v-input--density-compact .v-field__input {
  row-gap: 4px;
}

.v-field__input input {
  letter-spacing: inherit;
}

.v-field__input input::placeholder, input.v-field__input::placeholder, textarea.v-field__input::placeholder {
  color: currentColor;
  opacity: var(--v-disabled-opacity);
}

.v-field__input:focus, .v-field__input:active {
  outline: none;
}

.v-field__input:invalid {
  box-shadow: none;
}

.v-field__field {
  flex: 1 0;
  grid-area: field;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.v-field__prepend-inner {
  grid-area: prepend-inner;
  padding-inline-end: var(--v-field-padding-after);
}

.v-field__clearable {
  grid-area: clear;
}

.v-field__append-inner {
  grid-area: append-inner;
  padding-inline-start: var(--v-field-padding-after);
}

.v-field__append-inner, .v-field__clearable, .v-field__prepend-inner {
  padding-top: var(--v-input-padding-top, 8px);
  align-items: flex-start;
  display: flex;
}

.v-field--center-affix .v-field__append-inner, .v-field--center-affix .v-field__clearable, .v-field--center-affix .v-field__prepend-inner {
  align-items: center;
  padding-top: 0;
}

.v-field.v-field--variant-underlined .v-field__append-inner, .v-field.v-field--variant-underlined .v-field__clearable, .v-field.v-field--variant-underlined .v-field__prepend-inner, .v-field.v-field--variant-plain .v-field__append-inner, .v-field.v-field--variant-plain .v-field__clearable, .v-field.v-field--variant-plain .v-field__prepend-inner {
  padding-top: calc(var(--v-field-padding-top, 8px)  + var(--v-input-padding-top, 0));
  padding-bottom: var(--v-field-padding-bottom, 4px);
  align-items: flex-start;
}

.v-field--focused .v-field__prepend-inner, .v-field--focused .v-field__append-inner {
  opacity: 1;
}

.v-field__prepend-inner > .v-icon, .v-field__append-inner > .v-icon, .v-field__clearable > .v-icon {
  opacity: var(--v-medium-emphasis-opacity);
}

.v-field--disabled .v-field__prepend-inner > .v-icon, .v-field--error .v-field__prepend-inner > .v-icon, .v-field--disabled .v-field__append-inner > .v-icon, .v-field--error .v-field__append-inner > .v-icon, .v-field--disabled .v-field__clearable > .v-icon, .v-field--error .v-field__clearable > .v-icon {
  opacity: 1;
}

.v-field--error:not(.v-field--disabled) .v-field__prepend-inner > .v-icon, .v-field--error:not(.v-field--disabled) .v-field__append-inner > .v-icon, .v-field--error:not(.v-field--disabled) .v-field__clearable > .v-icon {
  color: rgb(var(--v-theme-error));
}

.v-field__clearable {
  cursor: pointer;
  opacity: 0;
  margin-inline: 4px;
  transition: opacity .15s cubic-bezier(.4, 0, .2, 1), transform .15s cubic-bezier(.4, 0, .2, 1), width .15s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.v-field--focused .v-field__clearable, .v-field--persistent-clear .v-field__clearable {
  opacity: 1;
}

@media (hover: hover) {
  .v-field:hover .v-field__clearable {
    opacity: 1;
  }
}

@media (hover: none) {
  .v-field__clearable {
    opacity: 1;
  }
}

.v-label.v-field-label {
  contain: layout paint;
  max-width: calc(100% - var(--v-field-padding-start)  - var(--v-field-padding-end));
  pointer-events: none;
  top: var(--v-input-padding-top);
  transform-origin: 0;
  z-index: 1;
  margin-inline-start: var(--v-field-padding-start);
  margin-inline-end: var(--v-field-padding-end);
  transition: opacity .15s cubic-bezier(.4, 0, .2, 1), transform .15s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
}

.v-field--variant-underlined .v-label.v-field-label, .v-field--variant-plain .v-label.v-field-label {
  top: calc(var(--v-input-padding-top)  + var(--v-field-padding-top));
}

.v-field--center-affix .v-label.v-field-label {
  top: 50%;
  transform: translateY(-50%);
}

.v-field--active .v-label.v-field-label {
  visibility: hidden;
}

.v-field--focused .v-label.v-field-label, .v-field--error .v-label.v-field-label {
  opacity: 1;
}

.v-field--error:not(.v-field--disabled) .v-label.v-field-label {
  color: rgb(var(--v-theme-error));
}

.v-label.v-field-label--floating {
  --v-field-label-scale: .75em;
  font-size: var(--v-field-label-scale);
  visibility: hidden;
  max-width: 100%;
}

.v-field--center-affix .v-label.v-field-label--floating {
  transform: none;
}

.v-field.v-field--active .v-label.v-field-label--floating {
  visibility: unset;
}

.v-input--density-default .v-field--variant-solo .v-label.v-field-label--floating, .v-input--density-default .v-field--variant-solo-inverted .v-label.v-field-label--floating, .v-input--density-default .v-field--variant-filled .v-label.v-field-label--floating, .v-input--density-default .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 7px;
}

.v-input--density-comfortable .v-field--variant-solo .v-label.v-field-label--floating, .v-input--density-comfortable .v-field--variant-solo-inverted .v-label.v-field-label--floating, .v-input--density-comfortable .v-field--variant-filled .v-label.v-field-label--floating, .v-input--density-comfortable .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 5px;
}

.v-input--density-compact .v-field--variant-solo .v-label.v-field-label--floating, .v-input--density-compact .v-field--variant-solo-inverted .v-label.v-field-label--floating, .v-input--density-compact .v-field--variant-filled .v-label.v-field-label--floating, .v-input--density-compact .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 3px;
}

.v-field--variant-plain .v-label.v-field-label--floating, .v-field--variant-underlined .v-label.v-field-label--floating {
  top: var(--v-input-padding-top);
  margin: 0;
  transform: translateY(-16px);
}

.v-field--variant-outlined .v-label.v-field-label--floating {
  transform-origin: center;
  margin: 0 4px;
  position: static;
  transform: translateY(-50%);
}

.v-field__outline {
  --v-field-border-width: 1px;
  --v-field-border-opacity: .38;
  contain: layout;
  pointer-events: none;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

@media (hover: hover) {
  .v-field:hover .v-field__outline {
    --v-field-border-opacity: var(--v-high-emphasis-opacity);
  }
}

.v-field--error:not(.v-field--disabled) .v-field__outline {
  color: rgb(var(--v-theme-error));
}

.v-field.v-field--focused .v-field__outline, .v-input.v-input--error .v-field__outline {
  --v-field-border-opacity: 1;
}

.v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 2px;
}

.v-field--variant-filled .v-field__outline:before, .v-field--variant-underlined .v-field__outline:before {
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0 var(--v-field-border-width);
  opacity: var(--v-field-border-opacity);
  content: "";
  width: 100%;
  height: 100%;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.v-field--variant-filled .v-field__outline:after, .v-field--variant-underlined .v-field__outline:after {
  content: "";
  border: 0 solid;
  border-bottom-width: 2px;
  width: 100%;
  height: 100%;
  transition: transform .15s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

.v-field--focused.v-field--variant-filled .v-field__outline:after, .v-field--focused.v-field--variant-underlined .v-field__outline:after {
  transform: scaleX(1);
}

.v-field--variant-outlined .v-field__outline {
  border-radius: inherit;
}

.v-field--variant-outlined .v-field__outline__start, .v-field--variant-outlined .v-field__outline__notch:before, .v-field--variant-outlined .v-field__outline__notch:after, .v-field--variant-outlined .v-field__outline__end {
  opacity: var(--v-field-border-opacity);
  border: 0 solid;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

.v-field--variant-outlined .v-field__outline__start {
  border-top-width: var(--v-field-border-width);
  border-bottom-width: var(--v-field-border-width);
  border-inline-start-width: var(--v-field-border-width);
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
  flex: 0 0 12px;
}

.v-field--rounded.v-field--variant-outlined .v-field__outline__start, [class^="rounded-"].v-field--variant-outlined .v-field__outline__start, [class*=" rounded-"].v-field--variant-outlined .v-field__outline__start {
  flex-basis: calc(var(--v-input-control-height) / 2 + 2px);
}

.v-field--reverse.v-field--variant-outlined .v-field__outline__start {
  border-inline-end-width: var(--v-field-border-width);
  border-inline-start-width: 0;
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
}

.v-field--variant-outlined .v-field__outline__notch {
  flex: none;
  max-width: calc(100% - 12px);
  position: relative;
}

.v-field--variant-outlined .v-field__outline__notch:before, .v-field--variant-outlined .v-field__outline__notch:after {
  opacity: var(--v-field-border-opacity);
  content: "";
  width: 100%;
  height: 100%;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.v-field--variant-outlined .v-field__outline__notch:before {
  border-width: var(--v-field-border-width) 0 0;
}

.v-field--variant-outlined .v-field__outline__notch:after {
  border-width: 0 0 var(--v-field-border-width);
  bottom: 0;
}

.v-field--active.v-field--variant-outlined .v-field__outline__notch:before {
  opacity: 0;
}

.v-field--variant-outlined .v-field__outline__end {
  border-top-width: var(--v-field-border-width);
  border-bottom-width: var(--v-field-border-width);
  border-inline-end-width: var(--v-field-border-width);
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
  flex: 1;
}

.v-field--reverse.v-field--variant-outlined .v-field__outline__end {
  border-inline-end-width: 0;
  border-inline-start-width: var(--v-field-border-width);
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
}

.v-field__loader {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  width: 100%;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  right: 0;
  overflow: hidden;
}

.v-field--variant-outlined .v-field__loader {
  width: calc(100% - 2px);
  top: calc(100% - 3px);
  left: 1px;
}

.v-field__overlay {
  border-radius: inherit;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.v-field--variant-filled .v-field__overlay {
  opacity: .04;
  background-color: currentColor;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

.v-field--variant-filled.v-field--has-background .v-field__overlay {
  opacity: 0;
}

@media (hover: hover) {
  .v-field--variant-filled:hover .v-field__overlay {
    opacity: calc((.04 + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-field--variant-filled.v-field--focused .v-field__overlay {
  opacity: calc((.04 + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
}

.v-field--variant-solo-filled .v-field__overlay {
  opacity: .04;
  background-color: currentColor;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

@media (hover: hover) {
  .v-field--variant-solo-filled:hover .v-field__overlay {
    opacity: calc((.04 + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-field--variant-solo-filled.v-field--focused .v-field__overlay {
  opacity: calc((.04 + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
}

.v-field--variant-solo-inverted .v-field__overlay {
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

.v-field--variant-solo-inverted.v-field--has-background .v-field__overlay {
  opacity: 0;
}

@media (hover: hover) {
  .v-field--variant-solo-inverted:hover .v-field__overlay {
    opacity: calc((.04 + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-field--variant-solo-inverted.v-field--focused .v-field__overlay {
  background-color: rgb(var(--v-theme-surface-variant));
  opacity: 1;
}

.v-field--reverse .v-field__field, .v-field--reverse .v-field__input, .v-field--reverse .v-field__outline {
  flex-direction: row-reverse;
}

.v-field--reverse .v-field__input, .v-field--reverse input {
  text-align: end;
}

.v-input--disabled .v-field--variant-filled .v-field__outline:before, .v-input--disabled .v-field--variant-underlined .v-field__outline:before {
  border-image: repeating-linear-gradient(to right, rgba(var(--v-theme-on-surface), var(--v-disabled-opacity)) 0px, rgba(var(--v-theme-on-surface), var(--v-disabled-opacity)) 2px, transparent 2px, transparent 4px) 1 repeat;
}

.v-field--loading .v-field__outline:after, .v-field--loading .v-field__outline:before {
  opacity: 0;
}

.v-progress-linear {
  background: none;
  width: 100%;
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
  position: relative;
  overflow: hidden;
}

@media (forced-colors: active) {
  .v-progress-linear {
    border: thin solid buttontext;
  }
}

.v-progress-linear__background, .v-progress-linear__buffer {
  opacity: var(--v-border-opacity);
  background: currentColor;
  width: 100%;
  transition: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (forced-colors: active) {
  .v-progress-linear__buffer {
    opacity: .3;
    background-color: highlight;
  }
}

.v-progress-linear__content {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-progress-linear__determinate, .v-progress-linear__indeterminate {
  background: currentColor;
}

@media (forced-colors: active) {
  .v-progress-linear__determinate, .v-progress-linear__indeterminate {
    background-color: highlight;
  }
}

.v-progress-linear__determinate {
  height: inherit;
  transition: width, left, right;
  position: absolute;
  left: 0;
}

.v-progress-linear__indeterminate .long, .v-progress-linear__indeterminate .short {
  height: inherit;
  width: auto;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  position: absolute;
  inset: 0 auto 0 0;
}

.v-progress-linear__indeterminate .long {
  animation-name: indeterminate-ltr;
}

.v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-ltr;
}

.v-progress-linear__stream {
  opacity: .3;
  pointer-events: none;
  transition: width, left, right;
  animation: .25s linear infinite paused stream;
  position: absolute;
  bottom: 0;
  left: auto;
}

.v-progress-linear--reverse .v-progress-linear__background, .v-progress-linear--reverse .v-progress-linear__determinate, .v-progress-linear--reverse .v-progress-linear__content, .v-progress-linear--reverse .v-progress-linear__indeterminate .long, .v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  left: auto;
  right: 0;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .long {
  animation-name: indeterminate-rtl;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-rtl;
}

.v-progress-linear--reverse .v-progress-linear__stream {
  right: auto;
}

.v-progress-linear--absolute, .v-progress-linear--fixed {
  z-index: 1;
  left: 0;
}

.v-progress-linear--absolute {
  position: absolute;
}

.v-progress-linear--fixed {
  position: fixed;
}

.v-progress-linear--rounded {
  border-radius: 9999px;
}

.v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__determinate, .v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__indeterminate {
  border-radius: inherit;
}

.v-progress-linear--striped .v-progress-linear__determinate {
  background-image: linear-gradient(135deg, #ffffff40 25%, #0000 0 50%, #ffffff40 0 75%, #0000 0, #0000);
  background-repeat: repeat;
  background-size: var(--v-progress-linear-height);
  animation: 1s linear infinite progress-linear-stripes;
}

.v-progress-linear--active .v-progress-linear__indeterminate .long, .v-progress-linear--active .v-progress-linear__indeterminate .short, .v-progress-linear--active .v-progress-linear__stream {
  animation-play-state: running;
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate, .v-progress-linear--rounded-bar .v-progress-linear__indeterminate, .v-progress-linear--rounded-bar .v-progress-linear__stream + .v-progress-linear__background {
  border-radius: 9999px;
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }

  60% {
    left: -90%;
    right: 100%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: -35%;
    right: 100%;
  }
}

@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%;
  }

  60% {
    left: -8%;
    right: 107%;
  }

  100% {
    left: -8%;
    right: 107%;
  }
}

@keyframes stream {
  to {
    transform: translateX(var(--v-progress-linear-stream-to));
  }
}

@keyframes progress-linear-stripes {
  0% {
    background-position-x: var(--v-progress-linear-height);
  }
}

.v-input {
  flex: auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: grid;
}

.v-input--disabled {
  pointer-events: none;
}

.v-input--density-default {
  --v-input-control-height: 56px;
  --v-input-padding-top: 16px;
}

.v-input--density-comfortable {
  --v-input-control-height: 48px;
  --v-input-padding-top: 12px;
}

.v-input--density-compact {
  --v-input-control-height: 40px;
  --v-input-padding-top: 8px;
}

.v-input--vertical {
  grid-template: "append" max-content
                 "control"
                 "prepend" max-content
                 / min-content;
}

.v-input--vertical .v-input__prepend {
  margin-block-start: 16px;
}

.v-input--vertical .v-input__append {
  margin-block-end: 16px;
}

.v-input--horizontal {
  grid-template: "prepend control append"
                 "a messages b"
                 / max-content minmax(0, 1fr) max-content;
}

.v-input--horizontal .v-input__prepend {
  margin-inline-end: 16px;
}

.v-input--horizontal .v-input__append {
  margin-inline-start: 16px;
}

.v-input__details {
  letter-spacing: .0333333em;
  grid-area: messages;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 22px;
  padding-top: 6px;
  font-size: .75rem;
  font-weight: 400;
  line-height: normal;
  display: flex;
  overflow: hidden;
}

.v-input__details > .v-icon, .v-input__prepend > .v-icon, .v-input__append > .v-icon {
  opacity: var(--v-medium-emphasis-opacity);
}

.v-input--disabled .v-input__details > .v-icon, .v-input--disabled .v-input__details .v-messages, .v-input--error .v-input__details > .v-icon, .v-input--error .v-input__details .v-messages, .v-input--disabled .v-input__prepend > .v-icon, .v-input--disabled .v-input__prepend .v-messages, .v-input--error .v-input__prepend > .v-icon, .v-input--error .v-input__prepend .v-messages, .v-input--disabled .v-input__append > .v-icon, .v-input--disabled .v-input__append .v-messages, .v-input--error .v-input__append > .v-icon, .v-input--error .v-input__append .v-messages {
  opacity: 1;
}

.v-input--disabled .v-input__details, .v-input--disabled .v-input__prepend, .v-input--disabled .v-input__append {
  opacity: var(--v-disabled-opacity);
}

.v-input--error:not(.v-input--disabled) .v-input__details > .v-icon, .v-input--error:not(.v-input--disabled) .v-input__details .v-messages, .v-input--error:not(.v-input--disabled) .v-input__prepend > .v-icon, .v-input--error:not(.v-input--disabled) .v-input__prepend .v-messages, .v-input--error:not(.v-input--disabled) .v-input__append > .v-icon, .v-input--error:not(.v-input--disabled) .v-input__append .v-messages {
  color: rgb(var(--v-theme-error));
}

.v-input__prepend, .v-input__append {
  padding-top: var(--v-input-padding-top);
  align-items: flex-start;
  display: flex;
}

.v-input--center-affix .v-input__prepend, .v-input--center-affix .v-input__append {
  align-items: center;
  padding-top: 0;
}

.v-input__prepend {
  grid-area: prepend;
}

.v-input__append {
  grid-area: append;
}

.v-input__control {
  grid-area: control;
  display: flex;
}

.v-input--hide-spin-buttons input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-input--hide-spin-buttons input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-input--hide-spin-buttons input[type="number"] {
  -moz-appearance: textfield;
}

.v-input--plain-underlined .v-input__prepend, .v-input--plain-underlined .v-input__append {
  align-items: flex-start;
}

.v-input--density-default.v-input--plain-underlined .v-input__prepend, .v-input--density-default.v-input--plain-underlined .v-input__append {
  padding-top: calc(var(--v-input-padding-top)  + 4px);
}

.v-input--density-comfortable.v-input--plain-underlined .v-input__prepend, .v-input--density-comfortable.v-input--plain-underlined .v-input__append {
  padding-top: calc(var(--v-input-padding-top)  + 2px);
}

.v-input--density-compact.v-input--plain-underlined .v-input__prepend, .v-input--density-compact.v-input--plain-underlined .v-input__append {
  padding-top: calc(var(--v-input-padding-top)  + 0px);
}

.v-messages {
  opacity: var(--v-medium-emphasis-opacity);
  flex: auto;
  min-width: 1px;
  min-height: 14px;
  font-size: 12px;
  position: relative;
}

.v-messages__message {
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 12px;
  transition-duration: .15s;
}

.v-btn {
  letter-spacing: .0892857em;
  text-indent: .0892857em;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  border-radius: 4px;
  outline: none;
  flex-shrink: 0;
  grid-template-columns: max-content auto max-content;
  grid-template-areas: "prepend content append";
  justify-content: center;
  align-items: center;
  max-width: 100%;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition-property: box-shadow, transform, opacity, background;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-grid;
  position: relative;
}

.v-btn--size-x-small {
  --v-btn-size: .625rem;
  --v-btn-height: 20px;
  font-size: var(--v-btn-size);
  min-width: 36px;
  padding: 0 8px;
}

.v-btn--size-small {
  --v-btn-size: .75rem;
  --v-btn-height: 28px;
  font-size: var(--v-btn-size);
  min-width: 50px;
  padding: 0 12px;
}

.v-btn--size-default {
  --v-btn-size: .875rem;
  --v-btn-height: 36px;
  font-size: var(--v-btn-size);
  min-width: 64px;
  padding: 0 16px;
}

.v-btn--size-large {
  --v-btn-size: 1rem;
  --v-btn-height: 44px;
  font-size: var(--v-btn-size);
  min-width: 78px;
  padding: 0 20px;
}

.v-btn--size-x-large {
  --v-btn-size: 1.125rem;
  --v-btn-height: 52px;
  font-size: var(--v-btn-size);
  min-width: 92px;
  padding: 0 24px;
}

.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height)  + 0px);
}

.v-btn.v-btn--density-comfortable {
  height: calc(var(--v-btn-height)  + -8px);
}

.v-btn.v-btn--density-compact {
  height: calc(var(--v-btn-height)  + -12px);
}

.v-btn {
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
}

.v-btn--border {
  box-shadow: none;
  border-width: thin;
}

.v-btn--absolute {
  position: absolute;
}

.v-btn--fixed {
  position: fixed;
}

.v-btn:hover > .v-btn__overlay {
  opacity: calc(var(--v-hover-opacity) * var(--v-theme-overlay-multiplier));
}

.v-btn:focus-visible > .v-btn__overlay {
  opacity: calc(var(--v-focus-opacity) * var(--v-theme-overlay-multiplier));
}

@supports not selector(:focus-visible) {
  .v-btn:focus > .v-btn__overlay {
    opacity: calc(var(--v-focus-opacity) * var(--v-theme-overlay-multiplier));
  }
}

.v-btn--active > .v-btn__overlay, .v-btn[aria-haspopup="menu"][aria-expanded="true"] > .v-btn__overlay {
  opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier));
}

.v-btn--active:hover > .v-btn__overlay, .v-btn[aria-haspopup="menu"][aria-expanded="true"]:hover > .v-btn__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
}

.v-btn--active:focus-visible > .v-btn__overlay, .v-btn[aria-haspopup="menu"][aria-expanded="true"]:focus-visible > .v-btn__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
}

@supports not selector(:focus-visible) {
  .v-btn--active:focus > .v-btn__overlay, .v-btn[aria-haspopup="menu"][aria-expanded="true"]:focus > .v-btn__overlay {
    opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-btn--variant-plain, .v-btn--variant-outlined, .v-btn--variant-text, .v-btn--variant-tonal {
  color: inherit;
  background: none;
}

.v-btn--variant-plain {
  opacity: .62;
}

.v-btn--variant-plain:focus, .v-btn--variant-plain:hover {
  opacity: 1;
}

.v-btn--variant-plain .v-btn__overlay {
  display: none;
}

.v-btn--variant-elevated, .v-btn--variant-flat {
  background: rgb(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}

.v-btn--variant-elevated {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, #0003), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-btn--variant-flat {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, #0003), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-btn--variant-outlined {
  border: thin solid;
}

.v-btn--variant-text .v-btn__overlay {
  background: currentColor;
}

.v-btn--variant-tonal .v-btn__underlay {
  opacity: var(--v-activated-opacity);
  border-radius: inherit;
  pointer-events: none;
  background: currentColor;
  inset: 0;
}

.v-btn .v-btn__underlay {
  position: absolute;
}

@supports selector(:focus-visible) {
  .v-btn:after {
    pointer-events: none;
    border-radius: inherit;
    opacity: 0;
    content: "";
    border: 2px solid;
    width: 100%;
    height: 100%;
    transition: opacity .2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-btn:focus-visible:after {
    opacity: calc(.25 * var(--v-theme-overlay-multiplier));
  }
}

.v-btn--icon {
  border-radius: 50%;
  min-width: 0;
  padding: 0;
}

.v-btn--icon.v-btn--size-default {
  --v-btn-size: 1rem;
}

.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height)  + 12px);
  height: calc(var(--v-btn-height)  + 12px);
}

.v-btn--icon.v-btn--density-comfortable {
  width: calc(var(--v-btn-height)  + 0px);
  height: calc(var(--v-btn-height)  + 0px);
}

.v-btn--icon.v-btn--density-compact {
  width: calc(var(--v-btn-height)  + -8px);
  height: calc(var(--v-btn-height)  + -8px);
}

.v-btn--elevated:hover, .v-btn--elevated:focus {
  box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, #0003), 0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 1px 10px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-btn--elevated:active {
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, #0003), 0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 3px 14px 2px var(--v-shadow-key-ambient-opacity, #0000001f);
}

.v-btn--flat {
  box-shadow: none;
}

.v-btn--block {
  flex: 1 0 auto;
  min-width: 100%;
  display: flex;
}

.v-btn--disabled {
  pointer-events: none;
  opacity: .26;
}

.v-btn--disabled:hover {
  opacity: .26;
}

.v-btn--disabled.v-btn--variant-elevated, .v-btn--disabled.v-btn--variant-flat {
  box-shadow: none;
  opacity: 1;
  color: rgba(var(--v-theme-on-surface), .26);
  background: rgb(var(--v-theme-surface));
}

.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay, .v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
  opacity: .461538;
}

.v-btn--loading {
  pointer-events: none;
}

.v-btn--loading .v-btn__content, .v-btn--loading .v-btn__prepend, .v-btn--loading .v-btn__append {
  opacity: 0;
}

.v-btn--stacked {
  grid-template: "prepend" max-content
                 "content" max-content
                 "append" max-content
                 / auto;
  align-content: center;
  justify-items: center;
}

.v-btn--stacked .v-btn__content {
  flex-direction: column;
  line-height: 1.25;
}

.v-btn--stacked .v-btn__prepend, .v-btn--stacked .v-btn__append, .v-btn--stacked .v-btn__content > .v-icon--start, .v-btn--stacked .v-btn__content > .v-icon--end {
  margin-inline: 0;
}

.v-btn--stacked .v-btn__prepend, .v-btn--stacked .v-btn__content > .v-icon--start {
  margin-bottom: 4px;
}

.v-btn--stacked .v-btn__append, .v-btn--stacked .v-btn__content > .v-icon--end {
  margin-top: 4px;
}

.v-btn--stacked.v-btn--size-x-small {
  --v-btn-size: .625rem;
  --v-btn-height: 56px;
  font-size: var(--v-btn-size);
  min-width: 56px;
  padding: 0 12px;
}

.v-btn--stacked.v-btn--size-small {
  --v-btn-size: .75rem;
  --v-btn-height: 64px;
  font-size: var(--v-btn-size);
  min-width: 64px;
  padding: 0 14px;
}

.v-btn--stacked.v-btn--size-default {
  --v-btn-size: .875rem;
  --v-btn-height: 72px;
  font-size: var(--v-btn-size);
  min-width: 72px;
  padding: 0 16px;
}

.v-btn--stacked.v-btn--size-large {
  --v-btn-size: 1rem;
  --v-btn-height: 80px;
  font-size: var(--v-btn-size);
  min-width: 80px;
  padding: 0 18px;
}

.v-btn--stacked.v-btn--size-x-large {
  --v-btn-size: 1.125rem;
  --v-btn-height: 88px;
  font-size: var(--v-btn-size);
  min-width: 88px;
  padding: 0 20px;
}

.v-btn--stacked.v-btn--density-default {
  height: calc(var(--v-btn-height)  + 0px);
}

.v-btn--stacked.v-btn--density-comfortable {
  height: calc(var(--v-btn-height)  + -16px);
}

.v-btn--stacked.v-btn--density-compact {
  height: calc(var(--v-btn-height)  + -24px);
}

.v-btn--slim {
  padding: 0 8px;
}

.v-btn--readonly {
  pointer-events: none;
}

.v-btn--rounded {
  border-radius: 24px;
}

.v-btn--rounded.v-btn--icon {
  border-radius: 4px;
}

.v-btn .v-icon {
  --v-icon-size-multiplier: .857143;
}

.v-btn--icon .v-icon {
  --v-icon-size-multiplier: 1;
}

.v-btn--stacked .v-icon {
  --v-icon-size-multiplier: 1.14286;
}

.v-btn--stacked.v-btn--block {
  min-width: 100%;
}

.v-btn__loader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-btn__loader > .v-progress-circular {
  width: 1.5em;
  height: 1.5em;
}

.v-btn__content, .v-btn__prepend, .v-btn__append {
  align-items: center;
  transition: transform, opacity .2s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.v-btn__prepend {
  margin-inline: calc(var(--v-btn-height) / -9) calc(var(--v-btn-height) / 4.5);
  grid-area: prepend;
}

.v-btn--slim .v-btn__prepend {
  margin-inline-start: 0;
}

.v-btn__append {
  margin-inline: calc(var(--v-btn-height) / 4.5) calc(var(--v-btn-height) / -9);
  grid-area: append;
}

.v-btn--slim .v-btn__append {
  margin-inline-end: 0;
}

.v-btn__content {
  white-space: nowrap;
  grid-area: content;
  justify-content: center;
}

.v-btn__content > .v-icon--start {
  margin-inline: calc(var(--v-btn-height) / -9) calc(var(--v-btn-height) / 4.5);
}

.v-btn__content > .v-icon--end {
  margin-inline: calc(var(--v-btn-height) / 4.5) calc(var(--v-btn-height) / -9);
}

.v-btn--stacked .v-btn__content {
  white-space: normal;
}

.v-btn__overlay {
  border-radius: inherit;
  opacity: 0;
  background-color: currentColor;
  transition: opacity .2s ease-in-out;
}

.v-btn__overlay, .v-btn__underlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.v-pagination .v-btn {
  border-radius: 4px;
}

.v-pagination .v-btn--rounded {
  border-radius: 50%;
}

.v-btn__overlay {
  transition: none;
}

.v-pagination__item--is-active .v-btn__overlay {
  opacity: var(--v-border-opacity);
}

.v-btn-toggle > .v-btn.v-btn--active:not(.v-btn--disabled) > .v-btn__overlay {
  opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier));
}

.v-btn-toggle > .v-btn.v-btn--active:not(.v-btn--disabled):hover > .v-btn__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-hover-opacity)) * var(--v-theme-overlay-multiplier));
}

.v-btn-toggle > .v-btn.v-btn--active:not(.v-btn--disabled):focus-visible > .v-btn__overlay {
  opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
}

@supports not selector(:focus-visible) {
  .v-btn-toggle > .v-btn.v-btn--active:not(.v-btn--disabled):focus > .v-btn__overlay {
    opacity: calc((var(--v-activated-opacity)  + var(--v-focus-opacity)) * var(--v-theme-overlay-multiplier));
  }
}

.v-btn-group {
  vertical-align: middle;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
  flex-wrap: nowrap;
  min-width: 0;
  max-width: 100%;
  display: inline-flex;
  overflow: hidden;
}

.v-btn-group--border {
  box-shadow: none;
  border-width: thin;
}

.v-btn-group {
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, #0003), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, #00000024), 0px 0px 0px 0px var(--v-shadow-key-ambient-opacity, #0000001f);
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  background: none;
  border-radius: 4px;
}

.v-btn-group--density-default.v-btn-group {
  height: 48px;
}

.v-btn-group--density-comfortable.v-btn-group {
  height: 40px;
}

.v-btn-group--density-compact.v-btn-group {
  height: 36px;
}

.v-btn-group .v-btn {
  border-color: inherit;
  border-radius: 0;
}

.v-btn-group .v-btn:not(:last-child) {
  border-inline-end: none;
}

.v-btn-group .v-btn:not(:first-child) {
  border-inline-start: none;
}

.v-btn-group .v-btn:first-child {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.v-btn-group .v-btn:last-child {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.v-btn-group--divided .v-btn:not(:last-child) {
  border-inline-end-style: solid;
  border-inline-end-width: thin;
  border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-btn-group--tile {
  border-radius: 0;
}

.v-progress-circular {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.v-progress-circular > svg {
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.v-progress-circular__content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-progress-circular__underlay {
  color: rgba(var(--v-border-color), var(--v-border-opacity));
  stroke: currentColor;
  z-index: 1;
}

.v-progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  transition: all .2s ease-in-out, stroke-width;
}

.v-progress-circular--size-x-small {
  width: 16px;
  height: 16px;
}

.v-progress-circular--size-small {
  width: 24px;
  height: 24px;
}

.v-progress-circular--size-default {
  width: 32px;
  height: 32px;
}

.v-progress-circular--size-large {
  width: 48px;
  height: 48px;
}

.v-progress-circular--size-x-large {
  width: 64px;
  height: 64px;
}

.v-progress-circular--indeterminate > svg {
  transform-origin: center;
  transition: all .2s ease-in-out;
  animation: 1.4s linear infinite progress-circular-rotate;
}

.v-progress-circular--indeterminate .v-progress-circular__overlay {
  stroke-dasharray: 25 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center;
  animation: 1.4s ease-in-out infinite progress-circular-dash, 1.4s linear infinite progress-circular-rotate;
  transform: rotate(-90deg);
}

.v-progress-circular--disable-shrink > svg {
  animation-duration: .7s;
}

.v-progress-circular--disable-shrink .v-progress-circular__overlay {
  animation: none;
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg, .v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay {
  animation-play-state: paused !important;
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(270deg);
  }
}

.v-file-input--hide.v-input .v-field, .v-file-input--hide.v-input .v-input__control, .v-file-input--hide.v-input .v-input__details {
  display: none;
}

.v-file-input--hide.v-input .v-input__prepend {
  grid-area: control;
  margin: 0 auto;
}

.v-file-input--chips.v-input--density-compact .v-field--variant-solo .v-label.v-field-label--floating, .v-file-input--chips.v-input--density-compact .v-field--variant-solo-inverted .v-label.v-field-label--floating, .v-file-input--chips.v-input--density-compact .v-field--variant-filled .v-label.v-field-label--floating, .v-file-input--chips.v-input--density-compact .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 0;
}

.v-file-input input[type="file"] {
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.v-file-input .v-input__details {
  padding-inline: 16px;
}

.v-input--plain-underlined.v-file-input .v-input__details {
  padding-inline: 0;
}

.v-main {
  padding-left: var(--v-layout-left);
  padding-right: var(--v-layout-right);
  padding-top: var(--v-layout-top);
  padding-bottom: var(--v-layout-bottom);
  flex: 1 0 auto;
  max-width: 100%;
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
}

.v-main__scroller {
  max-width: 100%;
  position: relative;
}

.v-main--scrollable {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-main--scrollable > .v-main__scroller {
  --v-layout-left: 0px;
  --v-layout-right: 0px;
  --v-layout-top: 0px;
  --v-layout-bottom: 0px;
  flex: auto;
  overflow-y: auto;
}

.v-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

@media (width >= 960px) {
  .v-container {
    max-width: 900px;
  }
}

@media (width >= 1280px) {
  .v-container {
    max-width: 1200px;
  }
}

@media (width >= 1920px) {
  .v-container {
    max-width: 1800px;
  }
}

@media (width >= 2560px) {
  .v-container {
    max-width: 2400px;
  }
}

.v-container--fluid {
  max-width: 100%;
}

.v-container.fill-height {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.v-row {
  flex-wrap: wrap;
  flex: auto;
  margin: -12px;
  display: flex;
}

.v-row + .v-row {
  margin-top: 12px;
}

.v-row + .v-row--dense {
  margin-top: 4px;
}

.v-row--dense {
  margin: -4px;
}

.v-row--dense > .v-col, .v-row--dense > [class*="v-col-"] {
  padding: 4px;
}

.v-row.v-row--no-gutters {
  margin: 0;
}

.v-row.v-row--no-gutters > .v-col, .v-row.v-row--no-gutters > [class*="v-col-"] {
  padding: 0;
}

.v-spacer {
  flex-grow: 1;
}

.v-col-xxl, .v-col-xxl-auto, .v-col-xxl-12, .v-col-xxl-11, .v-col-xxl-10, .v-col-xxl-9, .v-col-xxl-8, .v-col-xxl-7, .v-col-xxl-6, .v-col-xxl-5, .v-col-xxl-4, .v-col-xxl-3, .v-col-xxl-2, .v-col-xxl-1, .v-col-xl, .v-col-xl-auto, .v-col-xl-12, .v-col-xl-11, .v-col-xl-10, .v-col-xl-9, .v-col-xl-8, .v-col-xl-7, .v-col-xl-6, .v-col-xl-5, .v-col-xl-4, .v-col-xl-3, .v-col-xl-2, .v-col-xl-1, .v-col-lg, .v-col-lg-auto, .v-col-lg-12, .v-col-lg-11, .v-col-lg-10, .v-col-lg-9, .v-col-lg-8, .v-col-lg-7, .v-col-lg-6, .v-col-lg-5, .v-col-lg-4, .v-col-lg-3, .v-col-lg-2, .v-col-lg-1, .v-col-md, .v-col-md-auto, .v-col-md-12, .v-col-md-11, .v-col-md-10, .v-col-md-9, .v-col-md-8, .v-col-md-7, .v-col-md-6, .v-col-md-5, .v-col-md-4, .v-col-md-3, .v-col-md-2, .v-col-md-1, .v-col-sm, .v-col-sm-auto, .v-col-sm-12, .v-col-sm-11, .v-col-sm-10, .v-col-sm-9, .v-col-sm-8, .v-col-sm-7, .v-col-sm-6, .v-col-sm-5, .v-col-sm-4, .v-col-sm-3, .v-col-sm-2, .v-col-sm-1, .v-col, .v-col-auto, .v-col-12, .v-col-11, .v-col-10, .v-col-9, .v-col-8, .v-col-7, .v-col-6, .v-col-5, .v-col-4, .v-col-3, .v-col-2, .v-col-1 {
  width: 100%;
  padding: 12px;
}

.v-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.v-col-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

.v-col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.v-col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.v-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.v-col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.v-col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.v-col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.v-col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.v-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.v-col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.v-col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.v-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-inline-start: 8.33333%;
}

.offset-2 {
  margin-inline-start: 16.6667%;
}

.offset-3 {
  margin-inline-start: 25%;
}

.offset-4 {
  margin-inline-start: 33.3333%;
}

.offset-5 {
  margin-inline-start: 41.6667%;
}

.offset-6 {
  margin-inline-start: 50%;
}

.offset-7 {
  margin-inline-start: 58.3333%;
}

.offset-8 {
  margin-inline-start: 66.6667%;
}

.offset-9 {
  margin-inline-start: 75%;
}

.offset-10 {
  margin-inline-start: 83.3333%;
}

.offset-11 {
  margin-inline-start: 91.6667%;
}

@media (width >= 600px) {
  .v-col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .v-col-sm-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .v-col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .v-col-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .v-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .v-col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .v-col-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .v-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-col-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .v-col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .v-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .v-col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .v-col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .v-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-sm-0 {
    margin-inline-start: 0;
  }

  .offset-sm-1 {
    margin-inline-start: 8.33333%;
  }

  .offset-sm-2 {
    margin-inline-start: 16.6667%;
  }

  .offset-sm-3 {
    margin-inline-start: 25%;
  }

  .offset-sm-4 {
    margin-inline-start: 33.3333%;
  }

  .offset-sm-5 {
    margin-inline-start: 41.6667%;
  }

  .offset-sm-6 {
    margin-inline-start: 50%;
  }

  .offset-sm-7 {
    margin-inline-start: 58.3333%;
  }

  .offset-sm-8 {
    margin-inline-start: 66.6667%;
  }

  .offset-sm-9 {
    margin-inline-start: 75%;
  }

  .offset-sm-10 {
    margin-inline-start: 83.3333%;
  }

  .offset-sm-11 {
    margin-inline-start: 91.6667%;
  }
}

@media (width >= 960px) {
  .v-col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .v-col-md-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .v-col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .v-col-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .v-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .v-col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .v-col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .v-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .v-col-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .v-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .v-col-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .v-col-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .v-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-md-0 {
    margin-inline-start: 0;
  }

  .offset-md-1 {
    margin-inline-start: 8.33333%;
  }

  .offset-md-2 {
    margin-inline-start: 16.6667%;
  }

  .offset-md-3 {
    margin-inline-start: 25%;
  }

  .offset-md-4 {
    margin-inline-start: 33.3333%;
  }

  .offset-md-5 {
    margin-inline-start: 41.6667%;
  }

  .offset-md-6 {
    margin-inline-start: 50%;
  }

  .offset-md-7 {
    margin-inline-start: 58.3333%;
  }

  .offset-md-8 {
    margin-inline-start: 66.6667%;
  }

  .offset-md-9 {
    margin-inline-start: 75%;
  }

  .offset-md-10 {
    margin-inline-start: 83.3333%;
  }

  .offset-md-11 {
    margin-inline-start: 91.6667%;
  }
}

@media (width >= 1280px) {
  .v-col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .v-col-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .v-col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .v-col-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .v-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .v-col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .v-col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .v-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-col-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .v-col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .v-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .v-col-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .v-col-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .v-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-lg-0 {
    margin-inline-start: 0;
  }

  .offset-lg-1 {
    margin-inline-start: 8.33333%;
  }

  .offset-lg-2 {
    margin-inline-start: 16.6667%;
  }

  .offset-lg-3 {
    margin-inline-start: 25%;
  }

  .offset-lg-4 {
    margin-inline-start: 33.3333%;
  }

  .offset-lg-5 {
    margin-inline-start: 41.6667%;
  }

  .offset-lg-6 {
    margin-inline-start: 50%;
  }

  .offset-lg-7 {
    margin-inline-start: 58.3333%;
  }

  .offset-lg-8 {
    margin-inline-start: 66.6667%;
  }

  .offset-lg-9 {
    margin-inline-start: 75%;
  }

  .offset-lg-10 {
    margin-inline-start: 83.3333%;
  }

  .offset-lg-11 {
    margin-inline-start: 91.6667%;
  }
}

@media (width >= 1920px) {
  .v-col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .v-col-xl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .v-col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .v-col-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .v-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .v-col-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .v-col-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .v-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-col-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .v-col-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .v-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .v-col-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .v-col-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .v-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xl-0 {
    margin-inline-start: 0;
  }

  .offset-xl-1 {
    margin-inline-start: 8.33333%;
  }

  .offset-xl-2 {
    margin-inline-start: 16.6667%;
  }

  .offset-xl-3 {
    margin-inline-start: 25%;
  }

  .offset-xl-4 {
    margin-inline-start: 33.3333%;
  }

  .offset-xl-5 {
    margin-inline-start: 41.6667%;
  }

  .offset-xl-6 {
    margin-inline-start: 50%;
  }

  .offset-xl-7 {
    margin-inline-start: 58.3333%;
  }

  .offset-xl-8 {
    margin-inline-start: 66.6667%;
  }

  .offset-xl-9 {
    margin-inline-start: 75%;
  }

  .offset-xl-10 {
    margin-inline-start: 83.3333%;
  }

  .offset-xl-11 {
    margin-inline-start: 91.6667%;
  }
}

@media (width >= 2560px) {
  .v-col-xxl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .v-col-xxl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .v-col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .v-col-xxl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .v-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .v-col-xxl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .v-col-xxl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .v-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-col-xxl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .v-col-xxl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .v-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .v-col-xxl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .v-col-xxl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .v-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xxl-0 {
    margin-inline-start: 0;
  }

  .offset-xxl-1 {
    margin-inline-start: 8.33333%;
  }

  .offset-xxl-2 {
    margin-inline-start: 16.6667%;
  }

  .offset-xxl-3 {
    margin-inline-start: 25%;
  }

  .offset-xxl-4 {
    margin-inline-start: 33.3333%;
  }

  .offset-xxl-5 {
    margin-inline-start: 41.6667%;
  }

  .offset-xxl-6 {
    margin-inline-start: 50%;
  }

  .offset-xxl-7 {
    margin-inline-start: 58.3333%;
  }

  .offset-xxl-8 {
    margin-inline-start: 66.6667%;
  }

  .offset-xxl-9 {
    margin-inline-start: 75%;
  }

  .offset-xxl-10 {
    margin-inline-start: 83.3333%;
  }

  .offset-xxl-11 {
    margin-inline-start: 91.6667%;
  }
}
/*# sourceMappingURL=index.955d7d27.css.map */
