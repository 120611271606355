.v-progress-linear {
  background: transparent;
  overflow: hidden;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.v-progress-linear--rounded {
  border-radius: 9999px;
}
@media (forced-colors: active) {
  .v-progress-linear {
    border: thin solid buttontext;
  }
}

.v-progress-linear__background,
.v-progress-linear__buffer {
  background: currentColor;
  bottom: 0;
  left: 0;
  opacity: var(--v-border-opacity);
  position: absolute;
  top: 0;
  width: 100%;
  transition-property: width, left, right;
  transition: inherit;
}

@media (forced-colors: active) {
  .v-progress-linear__buffer {
    background-color: highlight;
    opacity: 0.3;
  }
}
.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.v-progress-linear__determinate,
.v-progress-linear__indeterminate {
  background: currentColor;
}
@media (forced-colors: active) {
  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background-color: highlight;
  }
}

.v-progress-linear__determinate {
  height: inherit;
  left: 0;
  position: absolute;
  transition: inherit;
  transition-property: width, left, right;
}

.v-progress-linear__indeterminate .long, .v-progress-linear__indeterminate .short {
  animation-play-state: paused;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  bottom: 0;
  height: inherit;
  left: 0;
  position: absolute;
  right: auto;
  top: 0;
  width: auto;
}
.v-progress-linear__indeterminate .long {
  animation-name: indeterminate-ltr;
}
.v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-ltr;
}

.v-progress-linear__stream {
  animation: stream 0.25s infinite linear;
  animation-play-state: paused;
  bottom: 0;
  left: auto;
  opacity: 0.3;
  pointer-events: none;
  position: absolute;
  transition: inherit;
  transition-property: width, left, right;
}

.v-progress-linear--reverse .v-progress-linear__background,
.v-progress-linear--reverse .v-progress-linear__determinate,
.v-progress-linear--reverse .v-progress-linear__content {
  left: auto;
  right: 0;
}
.v-progress-linear--reverse .v-progress-linear__indeterminate .long, .v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  left: auto;
  right: 0;
}
.v-progress-linear--reverse .v-progress-linear__indeterminate .long {
  animation-name: indeterminate-rtl;
}
.v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  animation-name: indeterminate-short-rtl;
}
.v-progress-linear--reverse .v-progress-linear__stream {
  right: auto;
}

.v-progress-linear--absolute,
.v-progress-linear--fixed {
  left: 0;
  z-index: 1;
}

.v-progress-linear--absolute {
  position: absolute;
}

.v-progress-linear--fixed {
  position: fixed;
}

.v-progress-linear--rounded {
  border-radius: 9999px;
}
.v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__determinate,
.v-progress-linear--rounded.v-progress-linear--rounded-bar .v-progress-linear__indeterminate {
  border-radius: inherit;
}

.v-progress-linear--striped .v-progress-linear__determinate {
  animation: progress-linear-stripes 1s infinite linear;
  background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.25) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, 0.25) 0, hsla(0, 0%, 100%, 0.25) 75%, transparent 0, transparent);
  background-repeat: repeat;
  background-size: var(--v-progress-linear-height);
}

.v-progress-linear--active .v-progress-linear__indeterminate .long, .v-progress-linear--active .v-progress-linear__indeterminate .short {
  animation-play-state: running;
}
.v-progress-linear--active .v-progress-linear__stream {
  animation-play-state: running;
}

.v-progress-linear--rounded-bar .v-progress-linear__determinate,
.v-progress-linear--rounded-bar .v-progress-linear__indeterminate,
.v-progress-linear--rounded-bar .v-progress-linear__stream + .v-progress-linear__background {
  border-radius: 9999px;
}
.v-progress-linear--rounded-bar .v-progress-linear__determinate {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: -35%;
    right: 100%;
  }
}
@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%;
  }
  60% {
    left: -8%;
    right: 107%;
  }
  100% {
    left: -8%;
    right: 107%;
  }
}
@keyframes stream {
  to {
    transform: translateX(var(--v-progress-linear-stream-to));
  }
}
@keyframes progress-linear-stripes {
  0% {
    background-position-x: var(--v-progress-linear-height);
  }
}