.v-file-input--hide.v-input .v-field,
.v-file-input--hide.v-input .v-input__control,
.v-file-input--hide.v-input .v-input__details {
  display: none;
}
.v-file-input--hide.v-input .v-input__prepend {
  grid-area: control;
  margin: 0 auto;
}
.v-file-input--chips.v-input--density-compact .v-field--variant-solo .v-label.v-field-label--floating,
.v-file-input--chips.v-input--density-compact .v-field--variant-solo-inverted .v-label.v-field-label--floating,
.v-file-input--chips.v-input--density-compact .v-field--variant-filled .v-label.v-field-label--floating,
.v-file-input--chips.v-input--density-compact .v-field--variant-solo-filled .v-label.v-field-label--floating {
  top: 0px;
}
.v-file-input input[type=file] {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.v-file-input .v-input__details {
  padding-inline: 16px;
}
.v-input--plain-underlined.v-file-input .v-input__details {
  padding-inline: 0;
}